import React, {useContext, useEffect, useState} from 'react';
import Screen from "../../Components/Screen";
import {useHistory} from "react-router-dom";
import {Button, Form, Tab, Table, Tabs} from "react-bootstrap";
import logo from "../../Images/logo-dark.png";
import {Typeahead} from "react-bootstrap-typeahead";
import firebase from "firebase";
import moment from "moment";
import {globalUser} from "../../App";
import {Supplier} from "../ControlPanel";


const EngineeringBOM = () => {
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | undefined>()
    const [previewMode, setPreviewMode] = useState<boolean>(false)
    const [customerOrderNumber, setCustomerOrderNumber] = useState<string>("")
    const [equipmentDescription, setEquipmentDescription] = useState<string>("")
    const [equipmentNumber, setEquipmentNumber] = useState<string>("")
    const [thercoSerckRef, setThercoSerckRef] = useState<string>("")

    const [quantity, setQuantity] = useState<number>(1)
    const [suppliers, setSuppliers] = useState<Supplier[]>([])
    const currentUser = useContext(globalUser)
    const history = useHistory();

    // @ts-ignore
    const bomRefState: string = history.location.state.bomRef;
    // @ts-ignore
    const bomState: { partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }[] = history.location.state.bomState;

    const getSuppliers = async () => {
        const db = firebase.firestore().collection("suppliers");
        let temp: Supplier[] = []
        await db.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                console.log("Request, Engineering BOM")
                // doc.data() is never undefined for query doc snapshots
                temp.push({
                    id: doc.id,
                    addressOne: doc.data().addressOne,
                    addressTwo: doc.data().addressTwo,
                    addressThree: doc.data().addressThree,
                    name: doc.data().name,
                    postCode: doc.data().postCode,
                    townCity: doc.data().townCity,
                    website: doc.data().website,
                    contacts: doc.data().contacts
                })
            });
        }).then(() => setSuppliers(temp))
    }

    useEffect(() => {
        getSuppliers()
    }, [])

    const dateRaised = moment().format("D/M/YYYY");

    const tableData = () => {
        let i = 0
        let subI = 0
        return (
            bomState?.map((item) => {
                if (item.level === 1) {
                    i++
                    subI = 0
                    return (
                        <tr>
                            <td style={{border: "0"}}>{i}{`.00`}</td>
                            <td style={{border: "0"}} className="font-weight-bold">{item.description}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{item.partNumber}</td>
                            <td>{item.partQuantity * quantity}</td>
                            <td></td>
                        </tr>
                    )
                } else {
                    subI++
                    return (
                        <tr>
                            <td>{i}.{(subI < 10 ? '0' : '') + subI}</td>
                            <td>{item.description}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{item.partNumber}</td>
                            <td>{item.partQuantity * quantity}</td>
                            <td></td>
                        </tr>
                    )
                }
            })
        )
    }

    return (
        <>
            {previewMode ?
                <div className="createBomDocument bg-white">
                    <div className="bg-white" style={{padding: "4rem 3rem", pageBreakAfter: "always"}}>
                        <img className="mb-5" src={logo}/>
                        <h2 className="purchaseOrdersCreate-template__green-text">BILL OF MATERIALS</h2>
                        <h2 className="mb-5">{bomRefState}</h2>
                        <div className="d-flex flex-row justify-content-between">
                            {selectedSupplier &&
                                <div className="d-flex flex-column">
                                    <p style={{fontSize: "18px"}}
                                       className="font-weight-bold">{selectedSupplier.name}</p>
                                    <p style={{fontSize: "18px"}}>{selectedSupplier.addressOne}</p>
                                    <p style={{fontSize: "18px"}}>{selectedSupplier.townCity}</p>
                                    <p style={{fontSize: "18px"}}>{selectedSupplier.postCode}</p>
                                    <a onClick={() => {
                                        setSelectedSupplier(undefined)
                                    }} href="#" className="mt-2">Edit supplier</a>
                                </div>
                            }
                            <div className="d-flex flex-column">
                                <span style={{fontSize: "18px", whiteSpace: "nowrap"}}
                                      className="mb-2 font-weight-bold">Date Raised:</span>
                                <p style={{fontSize: "18px", whiteSpace: "nowrap"}}>{dateRaised}</p>
                            </div>
                        </div>
                        <div className="d-flex flex-column mt-5">
                                <span style={{fontSize: "18px", whiteSpace: "nowrap"}}
                                      className="mb-2 font-weight-bold">Customer Order Number:<span className="font-weight-normal">{` ${customerOrderNumber}`}</span></span>
                        </div>

                        <div className="d-flex flex-column mt-5">
                                <span style={{fontSize: "18px", whiteSpace: "nowrap"}}
                                      className="mb-2 font-weight-bold">Equipment Description:<span className="font-weight-normal">{` ${equipmentDescription}`}</span></span>
                        </div>
                        <div className="d-flex flex-column mt-3">
                                <span style={{fontSize: "18px", whiteSpace: "nowrap"}}
                                      className="mb-2 font-weight-bold">Equipment Number:<span className="font-weight-normal">{` ${equipmentNumber}`}</span></span>
                        </div>

                        <div className="d-flex flex-column mt-5">
                                <span style={{fontSize: "18px", whiteSpace: "nowrap"}}
                                      className="mb-2 font-weight-bold">Therco-Serck Ref:<span className="font-weight-normal">{` ${thercoSerckRef}`}</span></span>
                        </div>
                    </div>
                    <div className="bg-white" style={{padding: "4rem 3rem 1rem 3rem"}}>
                        <p style={{fontSize: "18px"}} className="font-weight-bold">Material Quantities</p>
                        <p>The quantities shown in this BOM are for {` ${quantity} ${quantity > 1 ? "items" : "item"}`}</p>
                    </div>
                    <div className="bg-white" style={{padding: "4rem 3rem"}}>
                        <Table  bordered hover>
                            <thead>
                            <tr>
                                <th>Item</th>
                                <th>Description</th>
                                <th>Dimension</th>
                                <th>Material</th>
                                <th>Finish</th>
                                <th>Part Number</th>
                                <th>Quantity</th>
                                <th>QA Requirement</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData()}
                            </tbody>
                        </Table>
                    </div>
                    <div className="bg-white d-flex align-items-center justify-content-end" style={{padding: "4rem 3rem"}}>
                        <Button className="button-green w-auto" onClick={() => setPreviewMode(false)}>Close preview</Button>
                        <Button className="button-green w-auto ml-2 hide-on-print" onClick={() => window.print()}>Print PDF</Button>
                    </div>
                </div>
            :
                <Screen active="EngineeringBOM">
                    <Tabs defaultActiveKey="cover">
                        <Tab eventKey="cover" title="Cover sheet">
                            <div className="bg-white" style={{padding: "4rem 3rem", pageBreakAfter: "always"}}>
                                <img className="mb-5" src={logo}/>
                                <h2 className="purchaseOrdersCreate-template__green-text">BILL OF MATERIALS</h2>
                                <h2 className="mb-5">{bomRefState}</h2>
                                <div className="d-flex flex-row justify-content-between">
                                    {selectedSupplier ?
                                        <div className="d-flex flex-column">
                                            <p style={{fontSize: "18px"}}
                                               className="font-weight-bold">{selectedSupplier.name}</p>
                                            <p style={{fontSize: "18px"}}>{selectedSupplier.addressOne}</p>
                                            <p style={{fontSize: "18px"}}>{selectedSupplier.townCity}</p>
                                            <p style={{fontSize: "18px"}}>{selectedSupplier.postCode}</p>
                                            <a onClick={() => {
                                                setSelectedSupplier(undefined)
                                            }} href="#" className="mt-2">Edit supplier</a>
                                        </div>
                                        :
                                        <div className="purchaseOrdersCreate-template-supplier w-25">
                                            <Typeahead
                                                onChange={(selected) => {
                                                    setSelectedSupplier(selected[0])
                                                }}
                                                options={suppliers}
                                                labelKey="name"
                                                placeholder="Search for supplier..."
                                            />
                                        </div>
                                    }
                                    <div className="d-flex flex-column">
                                <span style={{fontSize: "18px", whiteSpace: "nowrap"}}
                                      className="mb-2 font-weight-bold">Date Raised:</span>
                                        <p style={{fontSize: "18px", whiteSpace: "nowrap"}}>{dateRaised}</p>
                                    </div>
                                </div>
                                <div className="d-flex flex-column mt-5">
                                <span style={{fontSize: "18px", whiteSpace: "nowrap"}}
                                      className="mb-2 font-weight-bold">Customer Order Number:</span>
                                    <Form.Control value={customerOrderNumber}
                                                  onChange={(event) => setCustomerOrderNumber(event.currentTarget.value)}
                                                  className="w-25" placeholder="Enter order number..."/>
                                </div>

                                <div className="d-flex flex-column mt-5">
                                <span style={{fontSize: "18px", whiteSpace: "nowrap"}}
                                      className="mb-2 font-weight-bold">Equipment Description:</span>
                                    <Form.Control value={equipmentDescription}
                                                  onChange={(event) => setEquipmentDescription(event.currentTarget.value)}
                                                  className="w-25" placeholder="Enter equipment description..."/>
                                </div>
                                <div className="d-flex flex-column mt-3">
                                <span style={{fontSize: "18px", whiteSpace: "nowrap"}}
                                      className="mb-2 font-weight-bold">Equipment Number:</span>
                                    <Form.Control value={equipmentNumber}
                                                  onChange={(event) => setEquipmentNumber(event.currentTarget.value)}
                                                  className="w-25" placeholder="Enter equipment number..."/>
                                </div>

                                <div className="d-flex flex-column mt-5">
                                <span style={{fontSize: "18px", whiteSpace: "nowrap"}}
                                      className="mb-2 font-weight-bold">Therco-Serck Ref:</span>
                                    <Form.Control value={thercoSerckRef}
                                                  onChange={(event) => setThercoSerckRef(event.currentTarget.value)}
                                                  className="w-25" placeholder="Enter Therco-Serck ref..."/>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="content" title="Bill of materials">
                            <div className="bg-white" style={{padding: "4rem 3rem"}}>
                                <div className="d-flex w-25 flex-column mb-5">
                                    <p style={{fontSize: "18px", whiteSpace: "nowrap"}} className="mb-0">Quantity:</p>
                                    <Form.Control value={quantity}
                                                  onChange={(event) => setQuantity(parseInt(event.currentTarget.value))}
                                                  type="Number" placeholder="Quantity..."/>
                                </div>
                                <Table  bordered hover>
                                    <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Description</th>
                                        <th>Dimension</th>
                                        <th>Material</th>
                                        <th>Finish</th>
                                        <th>Part Number</th>
                                        <th>Quantity</th>
                                        <th>QA Requirement</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tableData()}
                                    </tbody>
                                </Table>
                            </div>
                        </Tab>
                    </Tabs>
                    <div className="bg-white d-flex justify-content-end" style={{padding: "4rem 3rem"}}>
                        <Button className="button-green w-auto" onClick={() => setPreviewMode(true)}>View preview</Button>
                    </div>
                </Screen>
            }
        </>
    )
}

export default EngineeringBOM