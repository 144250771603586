import React from 'react';
import {Card} from 'react-bootstrap';

interface CardItemProps {
    width: string;
    title: any;
    body: any;
    height?: string;
    noPadding?: boolean;
}

const CardItem = ({width, height, title, body, noPadding}: CardItemProps) => {
    return (
        <Card
            bg={"light"}
            text={'dark'}
            style={{ width: width, height: height && height }}
            className={`cardItem`}
        >
            <Card.Header className="cardItem__title">{title}</Card.Header>
            <Card.Body className={noPadding ? 'cardItem--noPadding' : ""}>
                {body}
            </Card.Body>
        </Card>
    )
}

export default CardItem