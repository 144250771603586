import React, { useEffect, useState } from 'react';
import Screen from "../../../Components/Screen";
import firebase from "firebase";
import logo from "../../../Images/logo-dark.png";
import { Button, Card, Table } from "react-bootstrap";
import { Part } from '../../Parts';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const MaterialsNotReceived = () => {
    const location = useLocation();
    const [parts, setParts] = useState<{ part: Part, dueDate: string, quantity: number, price: number, po: string, supplier: string, so: string }[]>([]);

    const findGrn = async (po: string): Promise<string[]> => {
        const query = firebase.firestore().collection('GRN').where("poNumber", "==", po.toString());
        const docs = await query.get();
        const receivedParts: string[] = [];
        docs.forEach(doc => {
            const dateReceived = doc.data().dateReceived;
            if (Array.isArray(dateReceived)) {
                dateReceived.forEach((recData: { date: string, partId: string }) => {
                    receivedParts.push(recData.partId);
                });
            }
        });
        return receivedParts;
    };

    const getPOParts = async (month: string, year: string) => {
        const monthIndex = parseInt(month, 10) - 1;
        const purchaseOrdersRef = firebase.firestore().collection('purchaseOrders');
        const purchaseOrdersSnapshot = await purchaseOrdersRef.get();
        let partsDueInMonth: { part: Part, dueDate: string, quantity: number, price: number, po: string, supplier: string, so: string }[] = [];
        let promises: Promise<void>[] = [];

        purchaseOrdersSnapshot.forEach(doc => {
            const parts: { part: Part, dueDate: string, quantity: number, price: number }[] = doc.data().parts;
            const id: string = doc.data().id;
            const supplier: string = doc.data().supplier.name;
            const so: string = doc.data().soNumber || "";

            parts.forEach(part => {
                const dueDate = new Date(part.dueDate);
                if (dueDate.getFullYear() === parseInt(year, 10) && dueDate.getMonth() === monthIndex) {
                    promises.push(
                        findGrn(id).then((found) => {
                            if (!found.includes(part.part.id)) {
                                partsDueInMonth.push({ ...part, po: id, supplier: supplier, so: so });
                            }
                        })
                    );
                }
            });
        });

        await Promise.all(promises);

        // Sort the parts by due date closest to today
        const today = moment();
        const sortedParts = partsDueInMonth.slice().sort((a, b) => {
            const dueDateA = moment(a.dueDate);
            const dueDateB = moment(b.dueDate);
            return Math.abs(dueDateA.diff(today, 'days')) - Math.abs(dueDateB.diff(today, 'days'));
        });

        setParts(sortedParts);
    };

    useEffect(() => {
        //@ts-ignore
        getPOParts(location.state?.month, location.state?.year);
    }, [location.state]);

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const getMonthName = (monthIndex: number): string => {
        return monthNames[monthIndex];
    }

    //@ts-ignore
    const title = `Materials due in ${getMonthName(parseInt(location.state?.month, 10) - 1)} ${location.state?.year}, but not received`;

    return (
        <Screen active="Reports">
            <div className="purchaseOrdersView">
                <div className="purchaseOrdersCreate-template">
                    <img className="mb-5" src={logo} />
                    <h2 className="mb-5 purchaseOrdersCreate-template__green-text text-uppercase">{title}</h2>
                    <Card className="mb-4">
                        <Card.Header>
                            <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 1.0 – MATERIALS</h4>
                        </Card.Header>
                        <Card.Body>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>PO Number</th>
                                        <th>Supplier</th>
                                        <th>Part Number</th>
                                        <th>Due Date</th>
                                        <th>SO Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {parts.map((part, index) => (
                                        <tr key={index}>
                                            <td>{part.po}</td>
                                            <td>{part.supplier}</td>
                                            <td>{part.part.id}</td>
                                            <td>{moment(part.dueDate).format("DD/MM/YYYY")}</td>
                                            <td>{part.so}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <div className="d-flex flex-row justify-content-end w-100 mt-5">
                        <div className="d-flex flex-row align-items-center">
                            {/* <Button className="button-green ml-3">View preview & print</Button> */}
                        </div>
                    </div>
                </div>
            </div>
        </Screen>
    );
}

export default MaterialsNotReceived;
