import React, {useEffect, useState} from 'react';
import firebase from "firebase";

interface BackpageSectionsProps {
    sections: { section6: boolean, section7: boolean, section8: boolean, section9: boolean, section10: boolean }
    lastSectionNumber: number
}

const BackpageSections = ({sections, lastSectionNumber}: BackpageSectionsProps) => {

    const [sectionInfo, setSectionInfo] = useState({
        sectionTwo: [],
        sectionFive: [],
        sectionSix: [],
        sectionSeven: [],
        sectionEight: [],
        sectionNine: [],
        sectionTen: []
    })
    const [sectionsUsed, setSectionsUsed] = useState<{title: string, content: string[]}[]>([])

    const getSections = async () => {
        const db = firebase.firestore().collection("dropdowns").doc("sections");
        await db.get().then((snap) => {
            if (snap.exists) {
                // @ts-ignore
                setSectionInfo(snap.data().values)
            }
        })
    }

    const setTrueSections = () => {
        let trueSections: {title: string, content: string[]}[] = [];
        if (sections["section6"]) {
            trueSections.push({title: 'HSEQ, INSPECTION & TESTING', content: sectionInfo["sectionSix"]})
        }
        if (sections["section7"]) {
            trueSections.push({title: 'PREPARATION FOR SHIPMENT', content: sectionInfo["sectionSeven"]})
        }
        if (sections["section8"]) {
            trueSections.push({title: 'GUARANTEE', content: sectionInfo["sectionEight"]})
        }
        if (sections["section9"]) {
            trueSections.push({title: 'TERMS & CONDITIONS', content: sectionInfo["sectionNine"]})
        }
        if (sections["section10"]) {
            trueSections.push({title: 'EXCLUSIONS', content: sectionInfo["sectionTen"]})
        }
        setSectionsUsed(trueSections)
    }

    useEffect(() => {
        getSections()
    }, [])

    useEffect(() => {
        setTrueSections()
    }, [sectionInfo])

    return (
        <div>
            {sectionsUsed.map((value,index) => {
                const count = lastSectionNumber + (index + 1);
                return (
                    <>
                        <h5 className="green-section-header mt-3">SECTION {count}.0 -{value.title} </h5>
                        {value.content.map((text) => <p>{text}</p>)}
                    </>
                )
            })}
        </div>
    )
}

export default BackpageSections