export const assessment = [
    {id: 1, text: "Is the enquiry for spares or replacement Serck equipment?"},
    {id: 2, text: "Is T-S prequalified with the end user(or contractor ot project) for this product or work scope?"},
    {id: 3, text: "Does T-S employ or have the access to SQEP for this product or work scope?"},
    {id: 4, text: "Does T-S (or its partners) have the requisite infrastructure to comply with the enquiry?"},
    {id: 5, text: "Can T-S satisfy the pre and post tender thermal and or mechanical design requirements?"},
    {id: 6, text: "Does T-S have access to the current version of the design codes & client specific specifications?"},
    {id: 7, text: "Can T-S meet the bid due date?"},
    {id: 8, text: "Based on current and forecast shop loadings can T-S achieve the required on site date?"},
    {id: 9, text: "Is there an order to place?"},
    {id: 10, text: "Pending a review of the offered T's & C's is it likely that agreeable cash positive terms will be agreed?"},
]