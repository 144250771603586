import React, {useEffect, useState} from 'react';
import Screen from "../../../Components/Screen";
import firebase from "firebase";
import {GRN} from "../../Goods in/CreateGRN";
import {useLocation} from "react-router";
import logo from "../../../Images/logo-dark.png";
import {Button, Card, Table} from "react-bootstrap";
import {SalesOrder} from "../../Order processing/OrderProcessing";

const SoClosedInMonth = () => {
    const [grn, setGrn] = useState<SalesOrder[]>([])

    const getSos = async () => {
        const query = firebase.firestore().collection('salesOrders')
        const grnRequest = await query.get()
        const temp: SalesOrder[] = []
        await grnRequest.docs.map(async (doc) => {
            // @ts-ignore
            const grnData: SalesOrder = doc.data();
            temp.push(grnData)
        })
        setGrn(temp)
    }

    const sortDate = (date: string) => {
        const temp = date.split(" ")[1]
        let tempDate = "01"
        if(temp === "Jan") {
            tempDate = "01"
        }
        if(temp === "Feb") {
            tempDate = "02"
        }
        if(temp === "Mar") {
            tempDate = "03"
        }
        if(temp === "Apr") {
            tempDate = "04"
        }
        if(temp === "May") {
            tempDate = "05"
        }
        if(temp === "Jun") {
            tempDate = "06"
        }
        if(temp === "Jul") {
            tempDate = "07"
        }
        if(temp === "Aug") {
            tempDate = "08"
        }
        if(temp === "Sep") {
            tempDate = "09"
        }
        if(temp === "Oct") {
            tempDate = "10"
        }
        if(temp === "Nov") {
            tempDate = "11"
        }
        if(temp === "Dec") {
            tempDate = "12"
        }
        return tempDate
    }

    useEffect(() => {
        getSos()
    }, [])

    const location = useLocation()
    // @ts-ignore
    const title = `SO's closed in ${location.state?.month}/${location.state?.year}`

    return (
        <Screen active="Reports">
            <div className="purchaseOrdersView">
                <div className="purchaseOrdersCreate-template">
                    <img className="mb-5" src={logo}/>
                    <h2 className="mb-5 purchaseOrdersCreate-template__green-text text-uppercase">{title}</h2>
                    <Card className="mb-4">
                        <Card.Header>
                            <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 1.0 – CLOSED SO LIST</h4>
                        </Card.Header>
                        <Card.Body>
                            <Table  bordered>
                                <thead>
                                <tr>
                                    <th className="text-right">#</th>
                                    <th>SO Number</th>
                                    <th>Customer</th>
                                </tr>
                                </thead>
                                <tbody>
                                {grn.map((value, index, array) => {
                                    if (value.closedDate !== undefined) {
                                        // @ts-ignore
                                        console.log(new Date(value.closedDate).getMonth() + 1)
                                        let date = ""
                                        if((new Date(value.closedDate).getMonth() + 1).toString().length === 1) {
                                            date = `0${(new Date(value.closedDate).getMonth() + 1).toString()}`
                                        }else {
                                            date = (new Date(value.closedDate).getMonth() + 1).toString()
                                        }
                                        // @ts-ignore
                                        if (location.state?.month === date) {
                                            return (
                                                <tr>
                                                    <td className="text-right">1</td>
                                                    <td>SO{value.quote.enquiry.tnNumber}</td>
                                                    <td>{value.quote.enquiry.customer.name}</td>
                                                </tr>
                                            )
                                        }
                                    }
                                })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <div className="d-flex flex-row justify-content-end w-100 mt-5">
                        <div className="d-flex flex-row align-items-center">
                            <Button
                                className="button-green ml-3">
                                View preview & print
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Screen>
    )
}

export default SoClosedInMonth