import React, { useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import { Accordion, Button, Card, Col, Form, FormControl, Modal, Row, Table } from "react-bootstrap";
import { MdAdd, MdFlightLand, MdNoteAdd, MdRemove } from "react-icons/all";
import CreateGRN, { GRN } from "./CreateGRN";
import firebase from "firebase";
import searchIcon from "../../Images/icons8-search-96 (1).png";
import { PurchaseOrderPart } from "../Purchase Orders/PurchaseOrdersCreate";
import EditGRN from "./EditGRN";
import moment from "moment";

export interface DisplayGRN {
    id: number;
    dateCreated: string;
    poNumber: string;
    items: {
        part: PurchaseOrderPart,
        quantityReceived: number,
        passed: string,
        dateReceived: string,
        datePassed: string,
        mtcNumber: string,
    }[]
}

export interface DisplayGRNItem {
    part: PurchaseOrderPart,
    quantityReceived: number,
    passed: string,
    dateReceived: string,
    datePassed: string,
    mtcNumber: string,
}

const GoodsIn = () => {
    const [showCreate, setShowCreate] = useState<boolean>(false)
    const [showEdit, setShowEdit] = useState<boolean>(false)
    const [showQuarantine, setShowQuarantine] = useState<boolean>(false)
    const [showLocation, setShowLocation] = useState<boolean>(false)
    const [acitveKey, setActiveKey] = useState<number>()
    const [grn, setGrn] = useState<DisplayGRN[]>([])
    const [grnEdit, setGrnEdit] = useState<DisplayGRN>()
    const [data, setData] = useState<any>([])
    const [search, setSearch] = useState<string>("")
    const [quarantine, setQuarantine] = useState<string>("")
    const [stockLocation, setStockLocation] = useState<string>("")
    const [quarantineId, setQuarantineId] = useState<string>("")

    const getGrns = async () => {
        const query = firebase.firestore().collection('GRN')
        const grnRequest = await query.get()
        const tempArray: DisplayGRN[] = []
        grnRequest.docs.map(async (doc) => {
            // @ts-ignore
            const grnData: GRN = doc.data();
            const display: DisplayGRN = {
                id: grnData.id,
                dateCreated: grnData.dateCreated,
                poNumber: grnData.poNumber,
                items: []
            }
            grnData.selectedParts.map((value) => {
                let dp = ""
                grnData.datePassed.map((dpValue) => {
                    if (dpValue.partId === value.part.id) {
                        // @ts-ignore
                        dp = dpValue?.date
                    }
                })
                let dr = ""
                grnData.dateReceived.map((dpValue) => {
                    if (dpValue.partId === value.part.id) {
                        // @ts-ignore
                        dr = dpValue?.date
                    }
                })
                let mtc = ""
                grnData.mtcNumber.map((dpValue) => {
                    if (dpValue.partId === value.part.id) {
                        // @ts-ignore
                        mtc = dpValue?.mtcNumber
                    }
                })
                let passed = ""
                grnData.passed.map((dpValue) => {
                    if (dpValue.partId === value.part.id) {
                        // @ts-ignore
                        passed = dpValue?.passed
                    }
                })
                let qty = ""
                grnData.quantityReceived.map((dpValue) => {
                    if (dpValue.partId === value.part.id) {
                        // @ts-ignore
                        qty = dpValue?.quantity
                    }
                })
                const tempItem: DisplayGRNItem = {
                    part: value,
                    datePassed: dp,
                    dateReceived: dr,
                    mtcNumber: mtc,
                    passed: passed,
                    quantityReceived: parseInt(qty),
                }
                display.items.push(tempItem)
            })
            console.log(display)
            tempArray.push(display)
        })
        setGrn(tempArray)
    }

    const quarantineItem = async () => {
        const temp = { status: "Quarantined", reason: quarantine, location: "" }
        const db = firebase.firestore().collection("GRNExtra").doc(quarantineId);
        if (temp) {
            await db.set(temp).then(() => {
                setShowQuarantine(false)
                setData([])
            }).then(() => getGrns())
        }
    }

    const assignStockLocation = async () => {
        const temp = { status: "Cleared", reason: "", location: stockLocation }
        const db = firebase.firestore().collection("GRNExtra").doc(quarantineId);
        if (temp) {
            await db.set(temp).then(() => {
                setData([])
            }).then(() => getGrns())
        }
    }

    const filterGrns = async () => {
        const col = grn.filter((a) => a.poNumber.includes(search))
        setGrn(col)
    }

    useEffect(() => {
        getGrns()
    }, [])

    const mobileQuery = window.matchMedia('(max-width: 850px)')
    return (
        <Screen active="GoodsIn">
            <CreateGRN getGrns={() => getGrns()} show={showCreate} hide={() => setShowCreate(false)} />
            {grnEdit &&
                <EditGRN show={showEdit} hide={() => {
                    setShowEdit(false)
                    getGrns()
                }} displayGrn={grnEdit} />
            }
            <Card>
                <Card.Header
                    className="card-header--sticky purchaseOrders-header d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-row align-items-center">
                        <MdFlightLand style={{ marginRight: ".3rem" }} size={18} color="#131313" />
                        <p className="font-weight-bold">Goods in</p>
                    </div>
                    {!mobileQuery.matches &&
                        <div className="d-flex flex-row align-items-center">
                            <Form.Control style={{ width: "10rem", marginLeft: "-10.5rem" }} as="select">
                                <option>PO number</option>
                            </Form.Control>
                            <FormControl
                                value={search}
                                onChange={(e) => setSearch(e.currentTarget.value)}
                                onKeyDown={(e: any) => e.key === "Enter"}
                                style={{ marginLeft: ".3rem", marginRight: ".3rem" }}
                                className="datasheet-list-head__search"
                                placeholder={`Search GRN's...`}
                            />
                            <Button
                                onClick={() => {
                                    filterGrns()
                                }}
                                className="button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                <img src={searchIcon} />
                            </Button>
                        </div>
                    }
                    <div className="d-flex flex-row align-items-center purchaseOrders-header-controls">
                        <a onClick={() => setShowCreate(true)}>
                            <MdNoteAdd size={20} color="#131313" />
                            {` Create GRN`}
                        </a>
                    </div>
                </Card.Header>
                <Card.Body className="purchaseOrders-body">
                    <div className="d-flex flex-column p-3">
                        <Accordion>
                            {grn && grn.sort((a, b) => moment(new Date(b.dateCreated)).diff(moment(new Date(a.dateCreated)))).map((grn, index) => {
                                return (
                                    <Card>
                                        <Accordion.Toggle
                                            onClick={() => {
                                                if (acitveKey === index) {
                                                    setActiveKey(undefined)
                                                } else {
                                                    setActiveKey(index)
                                                }
                                            }}
                                            className="purchaseOrders-body-accordion-header"
                                            as={Card.Header} eventKey={index.toString()}>
                                            {`GRN${grn.id} - PO ${grn.poNumber}`}
                                            {acitveKey === index ? <MdRemove size={20} /> : <MdAdd size={20} />}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={index.toString()}>
                                            <Card.Body>
                                                <Row className="mb-2">
                                                    <Col>
                                                        <p className="form-label">Purchase order:</p>
                                                        <p>PO{grn.poNumber}</p>
                                                        <p className="form-label mt-2">Date created:</p>
                                                        <p>{moment(new Date(grn.dateCreated)).format("DD/MM/YYYY")}</p>
                                                    </Col>
                                                </Row>
                                                <p className="form-label mb-1">Parts received:</p>
                                                <Table className="mb-3" bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Part ID</th>
                                                            <th className="w-75">Description, Dimension, Material</th>
                                                            <th>QTY</th>
                                                            <th>Date received</th>
                                                            <th>Passed</th>
                                                            <th>Date passed</th>
                                                            <th>MTC</th>
                                                            <th>Stock location</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {grn.items.map((value, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{value.part.part.id}</td>
                                                                    <td>{value.part.part.description}{value.part.part.dimensions && `, ${value.part.part.dimensions}`}{value.part.part.material && `, ${value.part.part.material}`}</td>
                                                                    <td>
                                                                        {value.quantityReceived}
                                                                    </td>
                                                                    <td>
                                                                        {moment(new Date(value.dateReceived)).format("DD/MM/YYYY")}
                                                                    </td>
                                                                    <td>
                                                                        {value.passed === "yes" ? "Passed" : "Failed"}
                                                                    </td>
                                                                    <td>
                                                                        {moment(new Date(value.datePassed)).format("DD/MM/YYYY")}
                                                                    </td>
                                                                    <td>
                                                                        {value.mtcNumber}
                                                                    </td>
                                                                    <td>

                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                                <div className="d-flex flex-row align-items-center justify-content-end">
                                                    <Button
                                                        onClick={() => {
                                                            setShowEdit(true)
                                                            setGrnEdit(grn)
                                                        }}
                                                        className="button-green w-auto ml-2">
                                                        Edit GRN
                                                    </Button>
                                                    {/*<Button*/}
                                                    {/*    className="button-green w-auto ml-2">*/}
                                                    {/*    Print labels*/}
                                                    {/*</Button>*/}
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })}
                        </Accordion>
                    </div>
                </Card.Body>
            </Card>
            <Modal show={showQuarantine} onHide={() => setShowQuarantine(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{`Quarantine goods`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">
                        <p className="form-label">Reason:</p>
                        <Form.Control value={quarantine} onChange={(event) => setQuarantine(event.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => quarantineItem()} className="button-green w-auto">
                        Quarantine
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showLocation} onHide={() => setShowLocation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{`Stock location`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">
                        <p className="form-label">Stock location:</p>
                        <Form.Control value={stockLocation} onChange={(event) => setStockLocation(event.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => assignStockLocation()} className="button-green w-auto">
                        Assign location
                    </Button>
                </Modal.Footer>
            </Modal>
        </Screen>
    )
}

export default GoodsIn