import React, {ReactNode, useContext, useEffect} from 'react';
import firebase from "firebase";
import {globalNotifications, globalUser} from "../App";
import {toast} from "react-hot-toast";
import {MdNotificationsActive} from 'react-icons/md';
import {Link} from "react-router-dom";

interface LiveUpdatesProps {
    children: ReactNode
}
const LiveUpdates = ({children}:LiveUpdatesProps) => {
    const currentUser = useContext(globalUser)
    const allNotifications = useContext(globalNotifications)

    useEffect(() => {
        if (currentUser.currentUser.uid !== "") {
            firebase.firestore().collection("notifications").doc(currentUser.currentUser.uid)
                .onSnapshot((doc) => {
                    if (doc.exists) {
                        console.log("Current data: ", doc.data());
                        // @ts-ignore
                        doc.data().notifications.map((value) => {
                            if (value.viewed === false) {
                                toast(<Link to={value.link} className="font-weight-bold plain-link plain-link--bold">{value.text}</Link>, {
                                    icon: <MdNotificationsActive color="#51ad32" size={22}/>
                                })
                            }
                        })
                        // @ts-ignore
                        allNotifications.setAllNotifications(doc.data().notifications)
                    }
                });
        }
    }, [currentUser.currentUser.uid])
    return (<>{children}</>)
}

export default LiveUpdates