import React, { useEffect, useState } from 'react';
import firebase from "firebase";
import { useLocation } from 'react-router-dom';

import Screen from "../../../Components/Screen";
import logo from "../../../Images/logo-dark.png";
import { Button, Card, Table } from "react-bootstrap";
import { Part } from '../../Parts';

const SalesOrderIntake = () => {
    const [totalCost, setTotalCost] = useState<number>(0);
    const [orderCount, setOrderCount] = useState<number>(0);
    const [yearToDateCost, setYearToDateCost] = useState<number>(0);
    const location = useLocation();

    const calculateTotalCost = async (startDate: Date, endDate: Date): Promise<{ totalCost: number, count: number }> => {
        console.log(startDate, endDate)
        const salesOrdersRef = firebase.firestore().collection('salesOrders');
        const salesOrdersSnapshot = await salesOrdersRef.get();
        let totalCost = 0;
        let count = 0;

        salesOrdersSnapshot.forEach(doc => {
            const poReceivedDate = new Date(doc.data().poRecievedDate); // corrected to 'poRecievedDate'
            console.log(poReceivedDate)
            if (poReceivedDate >= startDate && poReceivedDate <= endDate) {
                count += 1; // Increment the count for each matching sales order
                const parts = doc.data().parts;
                const itemPrices = doc.data().quote.itemPrices;
                console.log(parts, itemPrices);
                if (Array.isArray(parts) && Array.isArray(itemPrices)) {
                    parts.forEach((part: { quantity: number }, index: number) => {
                        if (itemPrices[index] && itemPrices[index].price) {
                            totalCost += part.quantity * itemPrices[index].price;
                        }
                    });
                }
            }
        });

        return { totalCost, count };
    };

    const calculateTotalCostFromPreviousNovember = async (): Promise<number> => {
        const monthIndex = parseInt(month, 10) - 1;
        const yearNumber = parseInt(year, 10);
        const endDate = new Date(yearNumber, monthIndex + 1, 0)
        const currentYear = new Date().getFullYear();
        const previousNovember = new Date(currentYear - 1, 10, 1); // November is month 10 (zero-indexed)

        const { totalCost } = await calculateTotalCost(previousNovember, endDate);
        return totalCost;
    };

    useEffect(() => {
        //@ts-ignore
        const month = location.state?.month;
        //@ts-ignore
        const year = location.state?.year;

        if (month && year) {
            const monthIndex = parseInt(month, 10) - 1;
            const yearNumber = parseInt(year, 10);
            const startDate = new Date(yearNumber, monthIndex, 1);
            const endDate = new Date(yearNumber, monthIndex + 1, 0); // Last day of the month

            calculateTotalCost(startDate, endDate).then(({ totalCost, count }) => {
                setTotalCost(totalCost);
                setOrderCount(count);
            });

            calculateTotalCostFromPreviousNovember().then(total => {
                setYearToDateCost(total);
            });
        }
    }, [location.state]);

    //@ts-ignore
    const month = location.state?.month; // June
    //@ts-ignore
    const year = location.state?.year;
    const title = "Total cost of sales orders in " + `${month}/${year}:`;

    return (
        <Screen active="Reports">
            <div className="purchaseOrdersView">
                <div className="purchaseOrdersCreate-template">
                    <img className="mb-5" src={logo} />
                    <h2 className="mb-5 purchaseOrdersCreate-template__green-text text-uppercase">{title}</h2>
                    <Card className="mb-4">
                        <Card.Header>
                        </Card.Header>
                        <Card.Body>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Order Count</th>
                                        <th>Value</th>
                                        <th>Year to Date (From 1st Nov)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{orderCount}</td>
                                        <td className='text-right'>£{totalCost.toFixed(2)}</td>
                                        <td className='text-right'>£{yearToDateCost.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <div className="d-flex flex-row justify-content-end w-100 mt-5">
                        {/* <div className="d-flex flex-row align-items-center">
                            <Button className="button-green ml-3">
                                View preview & print
                            </Button>
                        </div> */}
                    </div>
                </div>
            </div>
        </Screen>
    );
};

export default SalesOrderIntake;
