import React, { useContext, useEffect, useState } from 'react';
import Screen from "../Components/Screen";
import { useHistory } from "react-router-dom";
import { MdInsertDriveFile, MdPieChart, MdStore } from "react-icons/md";
import CardItem from "../Components/CardItem";
import { Part } from "./Parts";
import firebase from "firebase";
import { globalNotifications, globalUser } from "../App";
import { PurchaseOrder } from "./Purchase Orders/PurchaseOrdersCreate";
import LineChart from "../Components/Charts/LineChart";

const Dashboard = () => {
    const history = useHistory();
    const allNotifications = useContext(globalNotifications)
    const currentUser = useContext(globalUser)
    const [stockParts, setStockParts] = useState<Part[]>([])
    const [stockValue, setStockValue] = useState<string>("0")
    const [userPendings, setUserPendings] = useState<PurchaseOrder[]>([])

    const getTotalStock = async () => {
        let temp: { partNumber: string, description: string, price: number, stock: number }[] = []
        const query = firebase.firestore().collection('parts')
            .where("stock", ">", 0)
        let value = 0
        const stockList = await query.get()
        stockList.docs.map((doc) => {
            if (typeof doc.data().price === "number") {
                // @ts-ignore
                value = value + (doc.data().price * doc.data().stock)
            }
        })
        // ~6000 requests
        setStockValue((Math.round(value * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    }

    const getUserPendings = async () => {
        console.log("out")
        let temp: PurchaseOrder[] = []
        const query = firebase.firestore().collection('purchaseOrders')
            .where("approverId", "==", currentUser.currentUser.uid)
            .where("approved", "==", false)
            .orderBy("id", "desc")
            .limit(4)

        await query.get().then((snapshot) => {
            console.log(snapshot)
            snapshot.forEach((doc) => {
                // @ts-ignore
                temp.push(doc.data())
                console.log("hit")
            })
        }).then(() => setUserPendings(temp))
    }

    useEffect(() => {
        getUserPendings()
    }, [])

    return (
        <Screen active="Dashboard">
            <div className="dashboard">
                <div className="dashboard-space dashboard-space--one">
                    <CardItem noPadding width="100%"
                        title={<p><MdInsertDriveFile size={20} color="#131313" /> Order intake</p>} body={<LineChart
                            id="Intake"
                            markers={[
                                {
                                    axis: 'y',
                                    value: 300000,
                                    legend: '',
                                    lineStyle: {
                                        stroke: 'black',
                                    },
                                    textStyle: {
                                        fill: 'red',
                                    },
                                },
                            ]}
                            lineData={[
                                {
                                    "x": "Nov",
                                    "y": 400123
                                },
                                {
                                    "x": "Dec",
                                    "y": 220030
                                },
                                {
                                    "x": "Jan",
                                    "y": 367322
                                },
                                {
                                    "x": "Feb",
                                    "y": 212865
                                },
                                {
                                    "x": "Mar",
                                    "y": 216887
                                },
                                {
                                    "x": "Apr",
                                    "y": 359965
                                },
                                {
                                    "x": "May",
                                    "y": 269980
                                },
                                {
                                    "x": "Jun",
                                    "y": 280121
                                },
                                {
                                    "x": "Jul",
                                    "y": 319234
                                },
                                {
                                    "x": "Aug",
                                    "y": 388122
                                },
                                {
                                    "x": "Sep",
                                    "y": 248944
                                },
                                {
                                    "x": "Oct",
                                    "y": 363221
                                }
                            ]} />} />
                </div>
                <div className="dashboard-space dashboard-space--two">
                    <CardItem noPadding width="100%"
                        title={<p><MdInsertDriveFile size={20} color="#131313" /> Sales output
                        </p>} body={<LineChart lineData={[{
                            "x": "Nov",
                            "y": 16123
                        },
                        {
                            "x": "Dec",
                            "y": 11030
                        },
                        {
                            "x": "Jan",
                            "y": 14322
                        },
                        {
                            "x": "Feb",
                            "y": 21865
                        },
                        {
                            "x": "Mar",
                            "y": 19887
                        },
                        {
                            "x": "Apr",
                            "y": 15965
                        },
                        {
                            "x": "May",
                            "y": 16980
                        },
                        {
                            "x": "Jun",
                            "y": 15121
                        },
                        {
                            "x": "Jul",
                            "y": 13234
                        },
                        {
                            "x": "Aug",
                            "y": 6122
                        },]} id={"Output"} />} />
                </div>
                <div className="dashboard-space dashboard-space--three">
                    <CardItem width="100%" title={<p><MdPieChart size={20} color="#131313" /> Supplier delivery performance</p>}
                        body={<LineChart lineData={[
                            {
                                "x": "Nov",
                                "y": 45
                            },
                            {
                                "x": "Dec",
                                "y": 98
                            },
                            {
                                "x": "Jan",
                                "y": 23
                            },
                            {
                                "x": "Feb",
                                "y": 34
                            },
                            {
                                "x": "Mar",
                                "y": 92
                            },
                            {
                                "x": "Apr",
                                "y": 89
                            },
                            {
                                "x": "May",
                                "y": 129
                            },
                            {
                                "x": "Jun",
                                "y": 110
                            },
                            {
                                "x": "Jul",
                                "y": 94
                            },
                            {
                                "x": "Aug",
                                "y": 118
                            },
                            {
                                "x": "Sep",
                                "y": 56
                            },
                            {
                                "x": "Oct",
                                "y": 76
                            }
                        ]} />} />
                </div>

                <div className="dashboard-space dashboard-space--four">
                    <CardItem noPadding width="100%"
                        title={<p><MdStore size={20} color="#131313" /> Therco-Serck delivery performance</p>} body={<LineChart lineData={[
                            {
                                "x": "November",
                                "y": 77
                            },
                            {
                                "x": "December",
                                "y": 118
                            },
                            {
                                "x": "January",
                                "y": 157
                            },
                            {
                                "x": "February",
                                "y": 34
                            },
                            {
                                "x": "March",
                                "y": 116
                            },
                            {
                                "x": "April",
                                "y": 159
                            },
                            {
                                "x": "May",
                                "y": 269
                            },
                            {
                                "x": "June",
                                "y": 180
                            },
                            {
                                "x": "July",
                                "y": 219
                            },
                            {
                                "x": "August",
                                "y": 188
                            },
                            {
                                "x": "September",
                                "y": 48
                            },
                            {
                                "x": "October",
                                "y": 26
                            }
                        ]} />} />
                </div>
                <div className="dashboard-space dashboard-space--five">
                    <CardItem width="100%" title={<p><MdPieChart size={20} color="#131313" /> Total stock value</p>}
                        body={<div className="d-flex justify-content-center align-items-center h-100 w-100"><p className="dashboard-space__single-value">£99,258.28</p></div>} />
                </div>
                <div className="dashboard-space dashboard-space--six">
                    <CardItem width="100%" title={<p><MdPieChart size={20} color="#131313" /> Order book value</p>}
                        body={<div className="d-flex justify-content-center align-items-center h-100 w-100"><p className="dashboard-space__single-value">£2</p></div>} />
                </div>
            </div>
        </Screen>
    )
}

export default Dashboard