import React, {useContext, useEffect, useState} from 'react';
import logo from '../Images/logo.png'
import {Button, Form} from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import firebase from 'firebase';
import {globalUser} from "../App";

const Login = () => {
    const history = useHistory();
    const {setCurrentUser} = useContext(globalUser)
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [remember, setRemember] = useState<boolean>(false)

    useEffect(() => {
        if (localStorage.getItem("remember") !== null && localStorage.getItem("remember") === "true") {
            // @ts-ignore
            setEmail(localStorage.getItem("email"))
            // @ts-ignore
            setPassword(localStorage.getItem("password"))
            // @ts-ignore
            setRemember(localStorage.getItem("remember"))
        }
    }, [])

    const signInWithEmailPassword = async () => {
        if (remember) {
            localStorage.setItem("email", email);
            localStorage.setItem("password", password);
            localStorage.setItem("remember", "true");
        }else {
            localStorage.setItem("email", "");
            localStorage.setItem("password", "");
            localStorage.setItem("remember", "false");
        }
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(async () => {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.
                await firebase.auth().signInWithEmailAndPassword(email, password)
                    .then((userCredential) => {
                        // Signed in
                        var foundUser = userCredential.user;
                        const user = firebase.firestore().collection('users').doc(firebase.auth().currentUser?.uid);
                        user.get().then((doc) => {
                            if (doc.exists) {
                                setCurrentUser(
                                    {
                                        // @ts-ignore
                                        uid: foundUser?.uid,
                                        // @ts-ignore
                                        name: doc.data().name,
                                        // @ts-ignore
                                        email: doc.data().email,
                                        // @ts-ignore
                                        permission: doc.data().permission,
                                        // @ts-ignore
                                        role: doc.data().role
                                    }
                                )
                            }
                        })
                        history.push("/dashboard")
                        // ...
                    })
                    .catch((error) => {
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        history.push("/")

                    });
            })
            .catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
            });
    }

    return (
        <div className="login">
            <div className="login-form">
                <img src={logo} className="login-form__logo"/>
                <Form>
                    <Form.Control
                        className="login-form__input"
                        placeholder="Email..."
                        value={email}
                        onChange={(event) => setEmail(event.currentTarget.value)}
                    />
                    <Form.Control
                        className="login-form__input"
                        type="password"
                        value={password}
                        placeholder="Password..."
                        onChange={(event) => setPassword(event.currentTarget.value)}
                    />
                    <Form.Check className="login-form-check" type="checkbox">
                        <Form.Check.Input className="login-form-check__box" onChange={() => setRemember(!remember)} checked={remember} type="checkbox"/>
                        <Form.Check.Label className="login-form-check__label">Remember me</Form.Check.Label>
                    </Form.Check>
                    <Button onClick={() => signInWithEmailPassword()} className="button-green">Sign in</Button>
                </Form>
            </div>
        </div>
    )
}

export default Login