import React, {useState} from 'react';
import Screen from "../../Components/Screen";
import {Link, useHistory} from "react-router-dom";
import {Form} from "react-bootstrap";


const BomTemplateSelection = () => {
    const [template, setTemplate] = useState<string>("indented-parts-list")
    const history = useHistory();

    // @ts-ignore
    const bomRefState: string = history.location.state.bomRef;
    // @ts-ignore
    const bomState: { partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }[] = history.location.state.bomState;

    return (
        <Screen active="BomTemplateSelection">
            <div className="d-flex justify-content-center align-items-center h-100 w-100 flex-column">
                <div style={{borderRadius: "15px"}} className="p-5 bg-white d-flex justify-content-center align-items-center h-auto w-auto flex-column">
                    <h3>Select a BOM template</h3>
                    <Form.Control className="w-auto mb-2 mt-1" onChange={(event) => setTemplate(event.currentTarget.value)} as="select">
                        <option value="indented-parts-list">Indented parts list</option>
                        <option value="engineering-bom">Engineering BOM</option>
                    </Form.Control>
                    <Link to={{
                        pathname: `/create-bill-of-materials/${template}`,
                        state: { bomState: bomState, bomRef: bomRefState}
                    }} className="button-green w-auto ml-2">Create report</Link>
                </div>
            </div>
        </Screen>
    )
}

export default BomTemplateSelection