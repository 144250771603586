import React, {useEffect, useState} from 'react';
import Screen from "../../../Components/Screen";
import firebase from "firebase";
import logo from "../../../Images/logo-dark.png";
import {Button, Card, Table} from "react-bootstrap";
import { Part } from '../../Parts';

const TotalStockList = () => {
    const [list, setList] = useState<Part[]>([])
    const [printing, setPrinting] = useState(false);

    const handlePrint = () => {
        setPrinting(true)
    }

    const getTotalStock = async () => {
        let temp: Part[] = []
        const query = firebase.firestore().collection('parts')
            .where("stock", ">", 0)

        const stockList = await query.get()
        stockList.docs.map((doc) => {
            console.log(doc.data())
            // @ts-ignore
            temp.push({id: doc.id,...doc.data()})
        })
        setList(temp.sort((a, b) => b.stock - a.stock))
    }

    useEffect(() => {
        getTotalStock()
    },[])


    if (printing) {
        <div className="purchaseOrdersView">
                <div className="purchaseOrdersView">
                    <div className="purchaseOrdersCreate-template">
                        <img className="mb-5" src={logo}/>
                        <h2 className="mb-5 purchaseOrdersCreate-template__green-text">{`PARTS LIST WITH POSITIVE STOCK`}</h2>
                        <Card className="mb-4">
                            <Card.Header>
                                <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 1.0 – STOCK LIST</h4>
                            </Card.Header>
                            <Card.Body>
                                <Table  bordered>
                                    <thead>
                                    <tr>
                                        <th className="text-right">#</th>
                                        <th>Part ID</th>
                                        <th>Description</th>
                                        <th className="text-right">Price</th>
                                        <th className="text-right">Stock</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list.map((partItem, index) => {
                                        return (
                                            <tr>
                                                <td className="text-right">{index + 1}</td>
                                                <td>{partItem.id}</td>
                                                <td>{partItem.description}, {partItem.dimensions}, {partItem.material}</td>
                                                <td className="text-right">£{partItem.price}.00</td>
                                                <td className="text-right">{partItem.stock}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                        <div className="d-flex flex-row justify-content-end w-100 mt-5">
                            <div className="d-flex flex-row align-items-center">
                                <Button
                                    onClick={() => setPrinting(false)}
                                    className="button-green ml-3">
                                    Cancel preview
                                </Button>
                                <Button
                                    onClick={() => window.print()}
                                    className="button-green ml-3">
                                    Download PDF
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    }

    return (
        <Screen active="Reports">
            <div className="purchaseOrdersView">
                <div className="purchaseOrdersCreate-template">
                    <img className="mb-5" src={logo}/>
                    <h2 className="mb-5 purchaseOrdersCreate-template__green-text">{`PARTS LIST WITH POSITIVE STOCK`}</h2>
                    <Card className="mb-4">
                        <Card.Header>
                            <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 1.0 – STOCK LIST</h4>
                        </Card.Header>
                        <Card.Body>
                            <Table  bordered>
                                <thead>
                                <tr>
                                    <th>Part ID</th>
                                    <th>Description</th>
                                    <th className="text-right">Stock</th>
                                    <th>SO Number</th>
                                    <th className="text-right">Price</th>
                                    <th className="text-right">Total</th>
                                    <th>Supplier</th>
                                    <th>PO Number</th>
                                    <th>Recieved Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                {list.map((partItem, index) => {
                                    return (
                                        <tr>
                                            <td>{partItem.id}</td>
                                            <td>{partItem.description}, {partItem.dimensions}, {partItem.material}</td>
                                            <td className="text-right">{partItem.stock}</td>
                                            <td></td>
                                            <td className="text-right">£{partItem.price}.00</td>
                                            <td className="text-right">£{parseInt(partItem.price) * partItem.stock}.00</td>
                                            <td>{partItem.supplierName}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <div className="d-flex flex-row justify-content-end w-100 mt-5">
                        <div className="d-flex flex-row align-items-center">
                            {/* <Button
                                onClick={() => handlePrint()}
                                className="button-green ml-3">
                                View preview & print
                            </Button> */}
                        </div>
                    </div>
                </div>
            </div>
        </Screen>
    )
}

export default TotalStockList