import React, { useContext, useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import logo from "../../Images/logo-dark.png"
import { useHistory, useLocation } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from "moment";
import { Button, Card, Form, FormControl, Modal, Table } from 'react-bootstrap';
import { Part } from "../Parts";
import firebase from "firebase";
import { globalNotifications, globalUser, Notification, User } from "../../App";
import DatePicker from "react-datepicker";
import { Supplier, SupplierContact } from "../ControlPanel";
import { MdAdd, MdEdit, MdFormatListBulleted } from "react-icons/all";
import { toast } from "react-hot-toast";

export interface PurchaseOrder {
    id: number;
    supplier?: Supplier;
    supplierContact?: SupplierContact;
    ref: string;
    dateRaised?: string;
    parts: { part: Part, dueDate: string, quantity: number, price: number }[];
    scope: string[];
    terms: string;
    raisedById: string;
    approverId: string;
    approved: boolean;
    soNumber?: string;
    extraNew?: {
        delAddress: {
            lineOne: string,
            lineTwo: string,
            town: string,
            postcode: string,
            country: string,
        },
        manualLines: { qty: number, desc: string, price: number, partNumber?: string, dueDate?: string }[],
    }
}

export interface PurchaseOrderPart {
    part: Part;
    dueDate: string;
    quantity: number;
}

const PurchaseOrdersCreate = () => {
    const history = useHistory();
    const [suppliers, setSuppliers] = useState<Supplier[]>([])
    const [supplierContacts, setSuppliersContacts] = useState<SupplierContact[]>([])
    const [extraInfoText, setExtraInfoText] = useState<string>("")
    const [approvers, setApprovers] = useState<User[]>([]);
    const currentUser = useContext(globalUser)
    const allNotifications = useContext(globalNotifications)
    const [poNumber, setPoNumber] = useState<number>(0)
    const [partList, setPartList] = useState<{ partNumber: string, description?: string, quantity?: string }[]>([])
    const [prices, setPrices] = useState<{ partNumber: string, price: number }[]>([])

    const [dueDates, setDueDates] = useState<{ partId: string, dueDate: Date }[]>([])
    const [selectedParts, setSelectedParts] = useState<Part[]>([])
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | undefined>()
    const [partSearchText, setPartSearchText] = useState<string>("")
    const [selectedSupplierContact, setSelectedSupplierContact] = useState<SupplierContact | undefined>()
    const [extraInfo, setExtraInfo] = useState<string[]>([])
    const [terms, setTerms] = useState<string>("Provision of parts described above where the raw materials originate from a supplier which operates at ISO9001 approved QMS which is certified by a European accredited body.")
    const [refNum, setRefNum] = useState<string>("")
    const [selectedApprover, setSelectedApprover] = useState<string>("")
    const dateRaised = moment().format("D/M/YYYY");
    const [show, setShow] = useState(false);


    //New
    const [delOne, setDelOne] = useState<string>("")
    const [delTwo, setDelTwo] = useState<string>("")
    const [delCity, setDelCity] = useState<string>("")
    const [delPost, setDelPost] = useState<string>("")
    const [delCountry, setDelCountry] = useState<string>("")
    const [manualLines, setManualLines] = useState<{ qty: number, desc: string, price: number, partNumber?: string, dueDate?: string }[]>([])
    const [manQty, setManQty] = useState<string>("")
    const [manDesc, setManDesc] = useState<string>("")
    const [manPrice, setManPrice] = useState<string>("")
    const [manPartNumber, setManPartNumber] = useState<string>("")
    const [manDate, setManDate] = useState<Date>()
    const [section2, setSection2] = useState<string[]>([])
    const [section2Value, setSection2Value] = useState<string>("")
    const [switchTwo, setSwitchTwo] = useState<boolean>(false)
    const [switchTwoVal, setSwitchTwoVal] = useState<string>("")


    const [dropdowns, setDropdowns] = useState({
        sectionTwo: [],
        sectionFive: [],
        sectionSix: [],
        sectionSeven: [],
        sectionEight: [],
        sectionNine: [],
        sectionTen: []
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPart, setShowPart] = useState(false);

    const handlePartClose = () => setShowPart(false);
    const handlePartShow = () => setShowPart(true);
    // @ts-ignore
    const partsState = history.location.state;
    console.log(partsState)

    const handlePartChange = (partNumber: string, objectKey: string, input: string) => {
        const temp = partList.map((value) => {
            if (value.partNumber === partNumber) {
                // @ts-ignore
                value[objectKey] = input
            }
            return value
        })
        setPartList(temp)
    }

    const getSelectedParts = async () => {
        const db = firebase.firestore().collection("parts");
        const temp: Part[] = []
        await partList.forEach((value, index, array) => {
            db.doc(value.partNumber).get()
                .then((docRef) => {
                    if (docRef.exists) {
                        // @ts-ignore
                        temp.push({
                            id: docRef.id,
                            // @ts-ignore
                            description: `${docRef.data().description}, ${docRef.data().dimensions}, ${docRef.data().material}, ${docRef.data().finish}`,
                            // @ts-ignore
                            material: docRef.data().material,
                            // @ts-ignore
                            price: `${docRef.data().price}`,
                            // @ts-ignore
                            productType: docRef.data().productType,
                            // @ts-ignore
                            stock: docRef.data().stock,
                            // @ts-ignore
                            supplierName: docRef.data().supplierName,
                        })

                    } else {
                        // @ts-ignore
                        temp.push({
                            id: value.partNumber,
                            // @ts-ignore
                            description: value.description,
                            // @ts-ignore
                            material: "",
                            // @ts-ignore
                            price: "",
                            // @ts-ignore
                            productType: "",
                            // @ts-ignore
                            stock: 0,
                            // @ts-ignore
                            supplierName: "",
                        })
                    }
                }).then(() => setSelectedParts([...selectedParts, ...temp]))
                .catch((error) => {
                })
        })
    }

    const getSuppliers = async () => {
        const db = firebase.firestore().collection("suppliers");
        let temp: Supplier[] = []
        await db.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                temp.push({
                    id: doc.id,
                    addressOne: doc.data().addressOne,
                    addressTwo: doc.data().addressTwo,
                    addressThree: doc.data().addressThree,
                    name: doc.data().name,
                    postCode: doc.data().postCode,
                    townCity: doc.data().townCity,
                    website: doc.data().website
                })
            });
        }).then(() => setSuppliers(temp))
    }

    const getDropdowns = async () => {
        const db = firebase.firestore().collection("dropdowns").doc("sections");
        await db.get().then((snap) => {
            if (snap.exists) {
                // @ts-ignore
                setDropdowns(snap.data().values)
            }
        })
    }

    const getSupplierContacts = async () => {
        const db = firebase.firestore().collection("suppliers");
        let temp: SupplierContact[] = []
        if (selectedSupplier) {
            db.doc(selectedSupplier?.id).get()
                .then((doc) => {
                    console.log(doc.data())
                    //@ts-ignore
                    if (doc.data().contacts !== undefined) {
                        //@ts-ignore
                        doc.data().contacts.map((item) => {
                            temp.push({
                                id: item.id,
                                // @ts-ignore
                                name: item.name,
                                // @ts-ignore
                                email: item.email,
                                // @ts-ignore
                                position: item.position,
                                // @ts-ignore
                                directPhone: item.directPhone,
                                // @ts-ignore
                                mobilePhone: item.mobilePhone,
                            })
                        })
                    }
                }).then(() => setSuppliersContacts(temp))
        }
    }

    const getTotalPrice = () => {
        let priceArray: number[] = [];
        selectedParts.map((part) => {
            // @ts-ignore
            const quantity = parseInt(partList.filter((value) => value.partNumber === part.id.toString())[0].quantity);
            let priceValue = "0"
            if (prices.filter(value => value.partNumber === part.id).length > 0) {
                // @ts-ignore
                priceValue = prices.filter(value => value.partNumber === part.id)[0].price
            }
            priceArray.push((quantity * parseInt(priceValue)))
        })
        manualLines.map((man) => {
            priceArray.push(man.price * man.qty)
        })
        return priceArray.reduce((a, b) => a + b, 0)
    }

    const getGross = () => {
        return getTotalPrice() + ((getTotalPrice() / 100) * 20)
    }

    const getApprovers = async () => {
        const db = firebase.firestore().collection("users");
        let temp: User[] = []
        await db.where("permission", "==", "Level 1").get().then((snapshot) => {
            snapshot.forEach((doc) => {
                if (doc.exists)
                    temp.push({
                        role: doc.data().role,
                        permission: doc.data().permission,
                        uid: doc.id,
                        email: doc.data().email,
                        name: doc.data().name
                    })
            })
        }).then(() => setApprovers(temp)).then(() => setSelectedApprover(temp[0].uid))
    }

    const getPoNumber = async () => {
        const db = firebase.firestore().collection("purchaseOrders");
        const query = db.orderBy("id", "desc").limit(1).get()
        await query.then((querySnapshot) => {
            if (querySnapshot.size === 0) {
                setPoNumber(5000000)
            } else {
                setPoNumber(querySnapshot.docs[0].data().id + 1)
            }
        })
    }

    const searchForPart = async () => {
        const db = firebase.firestore().collection("parts").doc(partSearchText);
        await db.get().then((doc) => {
            let temp: { partNumber?: string, description?: string, quantity?: number } = {}
            if (doc.exists) {
                temp.partNumber = partSearchText
                // @ts-ignore
                temp.description = `${doc.data().description}, ${doc.data().dimensions}, ${doc.data().material}, ${doc.data().finish}`
            } else {
                temp.partNumber = partSearchText
            }
            // @ts-ignore
            setPartList([...partList, temp])
        })
    }

    const handlePriceChange = (price: string, partNumber: string) => {
        const itemPrice = price
        const arr = prices.map((value) => {
            if (value.partNumber === partNumber) {
                value.price = parseInt(itemPrice)
            }
            return value
        })
        setPrices(arr)
    }

    const addUnapprovedPo = async () => {
        const db = firebase.firestore().collection("purchaseOrders");
        let tempParts: { part: Part, dueDate: string, quantity: number, price: number }[] = []
        console.log(selectedParts)
        selectedParts.map((part) => {
            const dueDate = dueDates.find((value) => part.id === value.partId)
            let tempPrice = 0
            // @ts-ignore
            tempPrice = prices.find((value) => part.id === value.partNumber)?.price
            // @ts-ignore
            const quantity = parseInt(partList.filter((value) => value.partNumber === part.id.toString())[0].quantity);
            if (dueDate && quantity)
                tempParts.push({ part: part, dueDate: dueDate.dueDate.toDateString(), quantity: quantity, price: tempPrice })
        })
        let purchaseOrder: PurchaseOrder = {
            id: poNumber,
            approved: false,
            approverId: selectedApprover,
            dateRaised: dateRaised,
            raisedById: currentUser.currentUser.uid,
            ref: refNum,
            scope: extraInfo,
            supplier: selectedSupplier,
            supplierContact: selectedSupplierContact,
            terms: terms,
            parts: tempParts,
            // @ts-ignore
            soNumber: partsState === undefined ? "" : partsState.soNumber,
            extraNew: {
                delAddress: {
                    country: delCountry,
                    lineOne: delOne,
                    lineTwo: delTwo,
                    postcode: delPost,
                    town: delCity
                },
                manualLines: manualLines
            }
        }
        console.log(purchaseOrder)
        await db.doc(purchaseOrder.id.toString()).set(purchaseOrder).then(async () => {
            const newNotification: Notification = {
                type: "PO Approval",
                text: "You've been assigned to approve a purchase order",
                link: `/purchase-orders/view/${poNumber}`,
                dateTime: new Date().toDateString(),
                viewed: false,
            }
            const notifyQuery = firebase.firestore().collection("notifications").doc(selectedApprover)
            await notifyQuery.get().then((doc) => {
                // @ts-ignore
                firebase.firestore().collection("notifications").doc(selectedApprover).set({ notifications: [newNotification, ...doc.data().notifications] })
            })
        }).then(() => {
            toast.success("Purchase order created!")
            history.push(`/purchase-orders/view/${purchaseOrder.id.toString()}`)
        })
    }

    const cleanManual = () => {
        const temp = manualLines
        return temp.map((a) => {
            if(a.dueDate === undefined) {
                a.dueDate = ""
            }
            return a
        })
    }

    const addApprovedPo = async () => {
        const db = firebase.firestore().collection("purchaseOrders");
        let tempParts: { part: Part, dueDate: string, quantity: number, price: number }[] = []
        console.log(selectedParts)
        selectedParts.map((part) => {
            const dueDate = dueDates.find((value) => part.id === value.partId)
            let tempPrice = 0
            // @ts-ignore
            tempPrice = prices.find((value) => part.id === value.partNumber)?.price
            // @ts-ignore
            const quantity = parseInt(partList.filter((value) => value.partNumber === part.id.toString())[0].quantity);
            if (dueDate && quantity)
                tempParts.push({ part: part, dueDate: dueDate.dueDate.toDateString(), quantity: quantity, price: tempPrice })
        })
        console.log(manualLines)
        console.log(history.location.state)
        let purchaseOrder: PurchaseOrder = {
            id: poNumber,
            approved: true,
            approverId: "fGQovdrEFsfx7SIRsHHAtzcmLtW2",
            dateRaised: dateRaised,
            raisedById: currentUser.currentUser.uid,
            ref: refNum,
            scope: extraInfo,
            supplier: selectedSupplier,
            supplierContact: selectedSupplierContact,
            terms: terms,
            parts: tempParts,
            // @ts-ignore
            soNumber: partsState === undefined ? "" : partsState.soNumber,
            extraNew: {
                delAddress: {
                    country: delCountry,
                    lineOne: delOne,
                    lineTwo: delTwo,
                    postcode: delPost,
                    town: delCity
                },
                manualLines: cleanManual()
            }
        }
        console.log(purchaseOrder)
        await db.doc(purchaseOrder.id.toString()).set(purchaseOrder).then(() => {
            toast.success("Purchase order created!")
            history.push(`/purchase-orders/view/${purchaseOrder.id.toString()}`)
        })
    }

    const location = useLocation()
    // @ts-ignore
    let fromOrderProcessing = []
    // @ts-ignore
    if (location.state?.parts) {
        // @ts-ignore
        fromOrderProcessing = location.state?.parts

    }

    useEffect(() => {
        if (fromOrderProcessing.length > 0 && selectedParts.length < 1) {
            const temp: Part[] = []
            const tempPartList: { partNumber: string, description?: string, quantity?: string }[] = []
            // @ts-ignore
            fromOrderProcessing.map((val) => {
                console.log(val)
                temp.push({
                    material: "",
                    dimensions: "",
                    finish: "",
                    id: val.partNumber,
                    description: val.description,
                    materialGroup: "",
                    nominalCodePurchasing: "",
                    nominalCodeSales: "",
                    notes: [],
                    price: "0",
                    stock: 0,
                    supplierName: "",
                    qaRequirement: [],
                    productType: ""
                })
                tempPartList.push({ quantity: val.partQuantity, description: val.description, partNumber: val.partNumber })
            })
            setSelectedParts(temp)
            setPartList(tempPartList)
        }
    }, [fromOrderProcessing])

    useEffect(() => {
        // getSelectedParts()
        getSuppliers()
        getPoNumber()
        getDropdowns()
    }, [])


    useEffect(() => {
        selectedParts.map((value) => {
            const tempt = prices
            tempt.push({ partNumber: value.id, price: 0 })
            setPrices(prices.concat(tempt))
        })
    }, [selectedParts])

    useEffect(() => {
        getSupplierContacts()
    }, [selectedSupplier])

    const addManLine = () => {
        const temp = manualLines
        temp.push({ desc: manDesc, price: parseInt(manPrice), qty: parseInt(manQty), dueDate: manDate?.toString(), partNumber: manPartNumber })
        setManualLines(temp)
        setManDesc("")
        setManPrice("")
        setManQty("")
        setManDate(undefined)
        setManPartNumber("")
    }
    const addSectionTwo = (value: string) => {
        setSection2([...section2, value])
    }

    return (
        <Screen active="Purchase orders create">
            <div className="purchaseOrdersCreate">
                <div className="purchaseOrdersCreate-template">
                    <img className="mb-5" src={logo} />
                    <div className='d-flex flex-row justify-content-between align-items-end'>
                        <h1>Purchase Order</h1>
                        <p className="mb-3">{moment().format('DD/MM/YYYY')}</p>
                    </div>
                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Section 1.0 - Reference Numbers & Address</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Our Purchase Order Number</th>
                                        <th>Supplier</th>
                                        <th>Delivery Address</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{poNumber}</td>
                                        <td className='noBottomBorder'>
                                            {selectedSupplier ?
                                                <div className="d-flex flex-column">
                                                    <p>{selectedSupplier.name}</p>
                                                </div>
                                                :
                                                <div className="">
                                                    <Typeahead
                                                        onChange={(selected) => {
                                                            setSelectedSupplier(selected[0])
                                                        }}
                                                        options={suppliers}
                                                        labelKey="name"
                                                        placeholder="Search for supplier..."
                                                    />
                                                </div>
                                            }
                                        </td>
                                        <td className='noBottomBorder'>
                                            <Form.Control value={delOne} onChange={(event) => setDelOne(event.currentTarget.value)}
                                                className="" type="text"
                                                placeholder="Delivery Address One..." />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='font-weight-bold  mid-cell'>Your Reference</td>
                                        <td className='noBottomBorder'><p>{selectedSupplier?.addressOne}</p></td>
                                        <td className='noBottomBorder'>
                                            <Form.Control value={delTwo} onChange={(event) => setDelTwo(event.currentTarget.value)}
                                                className="" type="text"
                                                placeholder="Delivery Address Two..." />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Form.Control value={refNum} onChange={(event) => setRefNum(event.currentTarget.value)}
                                                className="purchaseOrdersCreate-template__textbox" type="text"
                                                placeholder="Reference number..." />
                                        </td>
                                        <td className='noBottomBorder'><p>{selectedSupplier?.townCity}</p></td>
                                        <td className='noBottomBorder'>
                                            <Form.Control value={delCity} onChange={(event) => setDelCity(event.currentTarget.value)}
                                                className="" type="text"
                                                placeholder="Town/City..." />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='font-weight-bold'>Contact</td>
                                        <td className='noBottomBorder'><p>{selectedSupplier?.postCode}</p></td>
                                        <td className='noBottomBorder'>
                                            <Form.Control value={delPost} onChange={(event) => setDelPost(event.currentTarget.value)}
                                                className="" type="text"
                                                placeholder="Postcode..." />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {selectedSupplierContact ?
                                                <p>{`${selectedSupplierContact.name}`}</p>
                                                :
                                                <div className="purchaseOrdersCreate-template__textbox">
                                                    {selectedSupplier ?
                                                        <Typeahead
                                                            onChange={(selected) => {
                                                                setSelectedSupplierContact(selected[0])
                                                            }}
                                                            options={supplierContacts}
                                                            labelKey={(option) => `${option.name}`}
                                                        />
                                                        :
                                                        <p>Please select a supplier</p>
                                                    }
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            {selectedSupplier &&
                                                <a onClick={() => {
                                                    setSelectedSupplier(undefined)
                                                    setSelectedSupplierContact(undefined)
                                                }} href="#" className="mt-2">Edit supplier</a>
                                            }
                                        </td>
                                        <td>
                                            <Form.Control value={delCountry} onChange={(event) => setDelCountry(event.currentTarget.value)}
                                                className="" type="text"
                                                placeholder="Country..." />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Section 2.0 - Quantity, Description & Price</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th className='text-center'>Line</th>
                                        <th className='text-right'>Qty</th>
                                        <th>Description</th>
                                        <th className='text-center'>Part Number</th>
                                        <th className='text-center'>SO Number</th>
                                        <th>Due Date</th>
                                        <th className='text-right'>Unit Price</th>
                                        <th className='text-right'>Line Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedParts.map((part, index) => {
                                        // @ts-ignore
                                        const quantity = parseInt(partList.filter((value) => value.partNumber === part.id.toString())[0].quantity);
                                        const date = dueDates.find((value) => value.partId === part.id)
                                        let priceValue = 0
                                        if (prices.filter(value => value.partNumber === part.id).length > 0) {
                                            // @ts-ignore
                                            priceValue = prices.filter(value => value.partNumber === part.id)[0].price
                                        }
                                        console.log(priceValue)
                                        return (
                                            <tr>
                                                <td className='text-center noBottomBorder'>2.{index + 1}</td>
                                                <td className='noBottomBorder'>
                                                    <FormControl
                                                        type="Number"
                                                        placeholder="Quantity..."
                                                        value={quantity}
                                                    />
                                                </td>
                                                <td className='noBottomBorder'>{part.description}, {part.dimensions}, {part.material}, {part.finish}</td>
                                                <td className='text-center noBottomBorder'>
                                                    {part.id}
                                                </td>
                                                <td className='text-center noBottomBorder'>SO{
                                                    //@ts-ignore
                                                    partsState.soNumber
                                                }</td>
                                                <td className='noBottomBorder' style={{ maxWidth: "8rem" }}>
                                                    <DatePicker
                                                        dateFormat={"dd/MM/yyyy"}
                                                        placeholderText="Enter due date..." className="form-control"
                                                        wrapperClassName="purchaseOrdersCreate-template__date"
                                                        value={date && date.dueDate.toDateString()}
                                                        onChange={(date) => {
                                                            if (date instanceof Date) {
                                                                const temp = dueDates.filter((value) => value.partId !== part.id)
                                                                temp.push({ partId: part.id, dueDate: date })
                                                                setDueDates(temp)
                                                            }
                                                        }} />
                                                </td>
                                                <td className="noBottomBorder" style={{ maxWidth: "8rem" }}>
                                                    <Form.Control
                                                        className="w-100"
                                                        type="number"
                                                        value={priceValue}
                                                        onChange={(event) => handlePriceChange(event.currentTarget.value, part.id)}
                                                    />
                                                </td>
                                                <td className='text-right noBottomBorder'>£{(priceValue * quantity).toFixed(2)}</td>
                                            </tr>
                                        )
                                    })}
                                    {selectedParts.length > 0 &&
                                        <tr>
                                            <td className='text-center noBottomBorder'>2.{selectedParts && selectedParts.length + 1}</td>
                                            <td className='noBottomBorder'></td>
                                            <td className='noBottomBorder'></td>
                                            <td className='noBottomBorder'></td>
                                            <td className='noBottomBorder'></td>
                                            <td className='noBottomBorder'></td>
                                            <td className='noBottomBorder'></td>
                                            <td className='noBottomBorder'></td>
                                        </tr>
                                    }
                                    {manualLines.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className='text-center noBottomBorder'>2.{selectedParts && (selectedParts.length + 1 + index + (selectedParts.length > 0 ? 1 : 0))}</td>
                                                <td className='text-right noBottomBorder'>{item.qty}</td>
                                                <td className='noBottomBorder'>{item.desc}</td>
                                                <td className='noBottomBorder'>{item.partNumber}</td>
                                                <td className='noBottomBorder'></td>
                                                <td className='noBottomBorder'>{moment(item.dueDate).format("DD/MM/YYYY")}</td>
                                                <td className='text-right noBottomBorder'>£{item.price.toFixed(2)}</td>
                                                <td className='text-right noBottomBorder'>£{(item.price * item.qty).toFixed(2)}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td>

                                        </td>
                                        <td className='small-qty'>
                                            <Form.Control
                                                onChange={(e) => setManQty(e.currentTarget.value)}
                                                placeholder='QTY...'
                                                value={manQty}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                onChange={(e) => setManDesc(e.currentTarget.value)}
                                                placeholder='Description...'
                                                value={manDesc}
                                            />
                                        </td>
                                        <td>
                                            {selectedParts.length === 0 &&
                                                <Form.Control
                                                    onChange={(e) => setManPartNumber(e.currentTarget.value)}
                                                    placeholder='Part number...'
                                                    value={manPartNumber}
                                                />
                                            }
                                        </td>
                                        <td></td>
                                        <td>
                                            {selectedParts.length === 0 &&
                                                <DatePicker
                                                    dateFormat={"dd/MM/yyyy"}
                                                    placeholderText="Enter due date..." className="form-control"
                                                    wrapperClassName="purchaseOrdersCreate-template__date"
                                                    value={manDate && manDate.toDateString()}
                                                    onChange={(date) => {
                                                        if (date instanceof Date) {
                                                            setManDate(date)
                                                        }
                                                    }} />
                                            }
                                        </td>
                                        <td>
                                            <Form.Control
                                                onChange={(e) => setManPrice(e.currentTarget.value)}
                                                placeholder='Unit price...'
                                                value={manPrice}
                                            />
                                        </td>
                                        <td className='d-flex justify-content-end'>
                                            <Button
                                                onClick={() => addManLine()}
                                                className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                                <MdAdd size={24} color="white" />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>2.{selectedParts && (selectedParts.length + manualLines.length + (selectedParts.length > 0 ? 2 : 1))}</td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td className='no-border'></td>
                                        <td className='no-border'></td>
                                        <td className='no-border'></td>
                                        <td className='no-border text-right'>
                                            Sub Total, Nett
                                        </td>
                                        <td className='text-right'>
                                            £{getTotalPrice().toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>2.{selectedParts && (selectedParts.length + manualLines.length + (selectedParts.length > 0 ? 3 : 2))}</td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td className='no-border'></td>
                                        <td className='no-border'></td>
                                        <td className='no-border'></td>
                                        <td className='no-border text-right'>
                                            VAT
                                        </td>
                                        <td className='text-right'>
                                            £{((getTotalPrice() / 100) * 20).toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>2.{selectedParts && (selectedParts.length + manualLines.length + (selectedParts.length > 0 ? 4 : 3))}</td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td className='no-border'></td>
                                        <td className='no-border'></td>
                                        <td className='no-border'></td>
                                        <td className='no-border text-right'>
                                            Total, Gross
                                        </td>
                                        <td className='text-right'>
                                            £{getGross().toFixed(2)}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Section 3.0 - Scope of Supply</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Line</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='noBottomBorder'>3.1</td>
                                        <td className='noBottomBorder'>
                                            <Form.Control
                                                onChange={(e) => setTerms(e.currentTarget.value)}
                                                placeholder='Enter term...'
                                                value={terms}
                                            />
                                        </td>
                                    </tr>
                                    {extraInfo.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className='noBottomBorder'>3.{index + 2}</td>
                                                <td className='noBottomBorder'>{item}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td>

                                        </td>
                                        <td>
                                            <div className="w-100 d-flex flex-row align-items-center">
                                                {switchTwo ?
                                                    <Form.Control
                                                        onChange={(e) => setSwitchTwoVal(e.currentTarget.value)}
                                                        placeholder='Enter scope of supply information...'
                                                        value={switchTwoVal}
                                                    />
                                                    :
                                                    <Form.Control onChange={(event) => {
                                                        setSection2Value(event.currentTarget.value)
                                                    }} as="select">
                                                        {dropdowns && dropdowns["sectionTwo"].map((val: string, index) =>
                                                            <option value={val}>{val}</option>
                                                        )}
                                                    </Form.Control>
                                                }
                                                <Button
                                                    onClick={() => setSwitchTwo(!switchTwo)}
                                                    className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                                    {switchTwo ?
                                                        <MdFormatListBulleted size={24} color="white" />
                                                        :
                                                        <MdEdit size={24} color="white" />
                                                    }
                                                </Button>
                                                <Button
                                                    onClick={() => setExtraInfo([...extraInfo, switchTwo ? switchTwoVal : section2Value])}
                                                    className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                                    <MdAdd size={24} color="white" />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className='doc-section'>
                        <div className='doc-section-content'>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td className='font-weight-bold w-15'>Purchase Order Created By</td>
                                        <td className='w-35'>
                                            <p>{currentUser.currentUser.name}</p>
                                            <p>{currentUser.currentUser.role}</p>
                                        </td>
                                        <td className='font-weight-bold w-15'>Purchase Order Approved by</td>
                                        <td className='w-35'>
                                            <p>Not approved</p>
                                            <p>This purchase order requires approval</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>



                    <div className="purchaseOrdersCreate-template-signatures">
                        <div className="d-flex flex-column">

                        </div>
                        <div className="d-flex flex-column">

                            {getTotalPrice() < 15000 ?
                                <Button
                                    onClick={() => {
                                        addApprovedPo()
                                    }}
                                    className="button-green mt-5">
                                    Save purchase order
                                </Button>
                                :
                                <Button
                                    onClick={() => {
                                        getApprovers()
                                        handleShow()
                                    }}
                                    className="button-green mt-5">
                                    Send for approval
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select an approver</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control onChange={(event) => setSelectedApprover(event.currentTarget.value)} as="select">
                        {approvers.map((value, index) => <option value={value.uid} key={index}>{value.name}</option>)}
                    </Form.Control>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        onClick={() => addUnapprovedPo()}
                        className="button-green w-auto">
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size={"lg"} show={showPart} onHide={handlePartClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add part to purchase order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="form-label">Enter part number:</p>
                    <div className="d-flex flex-row align-items-center mb-3">
                        <Form.Control value={partSearchText}
                            className="w-50"
                            onChange={(event) => setPartSearchText(event.currentTarget.value)}
                            placeholder="Enter part number..." />
                        <Button
                            onClick={() => searchForPart()}
                            className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                            <MdAdd size={24} color="white" />
                        </Button>
                    </div>
                    {partList.length > 0 &&
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Quantity</th>
                                    <th>Part Number</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {partList.map((value, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                                <FormControl
                                                    onChange={(event) =>
                                                        handlePartChange(value.partNumber, "quantity", event.currentTarget.value)
                                                    }
                                                    type="Number"
                                                    placeholder="Quantity..."
                                                />
                                            </td>
                                            <td>{value.partNumber}</td>
                                            <td>
                                                {value.description ?
                                                    value.description
                                                    :
                                                    <FormControl
                                                        onChange={(event) =>
                                                            handlePartChange(value.partNumber, "description", event.currentTarget.value)
                                                        }
                                                        aria-details={value.partNumber}
                                                        placeholder="Description..."
                                                        as="textarea"
                                                        rows={1}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlePartClose}>
                        Close
                    </Button>
                    <Button
                        onClick={() => {
                            getSelectedParts()
                            handlePartClose()
                        }}
                        className="button-green w-auto">
                        Add part
                    </Button>
                </Modal.Footer>
            </Modal>
        </Screen>
    )
}

export default PurchaseOrdersCreate