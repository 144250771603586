import React, { useEffect, useState } from 'react';
import logo from "../../Images/logo-dark.png";
import Screen from "../../Components/Screen";
import { useHistory } from "react-router";
import { Table, Form, Button, FormControl } from "react-bootstrap";
import { SalesOrder } from '../Order processing/OrderProcessing';
import { Quote } from '../Enquiry processing/QuotationView';
import { Typeahead } from "react-bootstrap-typeahead";
import moment from "moment";
import DatePicker from "react-datepicker";
import firebase from "firebase";
import { User } from '../../App';
import { toast } from "react-hot-toast";
import { MdAdd } from 'react-icons/md';
import Footer from '../../Components/Footer';

export interface DeliveryNote {
    contact: string;
    orderNumber: string;
    commodity: string;
    invoiceNumber: string;
    dateShipped: string;
    dimensions: string;
    weight: string;
    notes: string
    newExtra?: {
        partInfo: {
            partNumber: string,
            commodity: string,
            packDim: string,
            weight: string
        }[],
        notes: string[]
    }
}

const DeliveryNote = () => {
    const [users, setUsers] = useState<User[]>([])
    const [contact, setContact] = useState<string>("")
    const [orderNumber, setOrderNumber] = useState<string>("")
    const [commodity, setCommodity] = useState<string>("")
    const [invoiceNumber, setInvoiceNumber] = useState<string>("")
    const [dateShipped, setDateShipped] = useState<string>(moment().format("DD/MM/YYYY"))
    const [dimensions, setDimensions] = useState<string>("")
    const [weight, setWeight] = useState<string>("")
    const [notes, setNotes] = useState<string>("")

    const history = useHistory();
    // @ts-ignore
    const passedState: { state: { so: SalesOrder, quote: Quote } } = history.location.state;
    console.log(passedState.state)

    const saveDeliveryNote = async () => {
        // @ts-ignore
        const temp: DeliveryNote = { newExtra: {notes: newNotes, partInfo: formValues}, contact, orderNumber, commodity, invoiceNumber, dateShipped, dimensions, weight, notes };
        console.log(temp)
        const db = firebase.firestore().collection("salesOrders").doc(`SO${passedState.state.quote.enquiry.tnNumber}`);
        if (temp) {
            await db.update({ DN: temp }).then(() => {
                toast.success(`Delivery note saved successfully`)
            })
        }
        let state = passedState
        passedState.state.so.DN = temp
        history.push({
            pathname: `/order-processing/delivery-note/view/${passedState.state.quote.enquiry.tnNumber}`,
            state: { ...state }
        });
    }

    const getAllUsers = async () => {
        const db = firebase.firestore().collection("users");
        let temp: User[] = []
        await db.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                temp.push({
                    name: doc.data().name,
                    uid: doc.id,
                    role: doc.data().role,
                    email: doc.data().email,
                    permission: doc.data().permission,
                })
            });
        }).then(() => setUsers(temp))
    }
    useEffect(() => {
        getAllUsers()
    }, [])

    //New
    const [formValues, setFormValues] = useState(
        passedState.state.so.parts.map(part => ({
            partNumber: part.partNumber || '',
            commodity: '',
            packDim: '',
            weight: ''
        }))
    );
    const [newNotes, setNewNotes] = useState<string[]>([])
    const [noteVal, setNoteVal] = useState<string>("")
    // Update function for form controls
    const handleInputChange = (index: number, field: any, value: any) => {
        setFormValues(prevValues =>
            prevValues.map((item, i) =>
                i === index ? { ...item, [field]: value } : item
            )
        );
    };

    return (
        <Screen active="Delivery note">
            <div className="purchaseOrdersCreate">
                <div className="purchaseOrdersCreate-template">

                    <img className="mb-5" src={logo} />

                    <div className='d-flex flex-row justify-content-between align-items-end'>
                        <h1>Advice Note</h1>
                        <p className="mb-3">{moment().format('DD/MM/YYYY')}</p>
                    </div>
                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Section 1.0 - Reference Numbers & Address</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Your Purchase Order Number</th>
                                        <th>Consignor</th>
                                        <th>Consignee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{passedState.state.so.customerPo}</td>
                                        <td className='noBottomBorder'>
                                            Therco Serck Ltd
                                        </td>
                                        <td className='noBottomBorder'>
                                            {passedState.state.quote.enquiry.customer.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='font-weight-bold  mid-cell'>Our Advice Note Number</td>
                                        <td className='noBottomBorder'>
                                            Rother Valley Way
                                        </td>
                                        <td className='noBottomBorder'>
                                            {passedState.state.quote.enquiry.customer.deliveryAddressOne}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            TN{passedState.state.quote.enquiry.tnNumber}{`/SO${passedState.state.so.quote.enquiry.tnNumber}`}
                                        </td>
                                        <td className='noBottomBorder'>
                                            Sheffield
                                        </td>
                                        <td className='noBottomBorder'>
                                            {passedState.state.quote.enquiry.customer.deliveryAddressTwo}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='font-weight-bold'>Our Commercial Invoice Number</td>
                                        <td className='noBottomBorder'>
                                            S20 3RW
                                        </td>
                                        <td className='noBottomBorder'>
                                            {passedState.state.quote.enquiry.customer.deliveryTownCity}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            TN{passedState.state.quote.enquiry.tnNumber}{`/SO${passedState.state.so.quote.enquiry.tnNumber}`}
                                        </td>
                                        <td>
                                            United Kingdom
                                        </td>
                                        <td>
                                            {passedState.state.quote.enquiry.customer.deliveryPostCode}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Section 2.0 - Quantity, Description & Price</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th className='text-center'>Line</th>
                                        <th className='text-right'>Qty</th>
                                        <th>Description</th>
                                        <th className='text-center'>Commodity Code</th>
                                        <th className='text-right'>Packed Dimensions [Each]</th>
                                        <th className='text-right'>Gross Weight [Each]</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {passedState.state.so.parts.map((part, index) => (
                                        <tr key={index}>
                                            <td  className='text-center noBottomBorder'>2.{index + 1}</td>
                                            <td className='noBottomBorder'>{part.quantity}</td>
                                            <td className='noBottomBorder'>{part.description}</td>
                                            <td className='noBottomBorder'>
                                                <FormControl
                                                    placeholder='Enter commodity code...'
                                                    value={formValues[index]?.commodity}
                                                    onChange={(e) => handleInputChange(index, 'commodity', e.target.value)}
                                                />
                                            </td>
                                            <td className='noBottomBorder'>
                                                <FormControl
                                                    placeholder='Enter dimensions...'
                                                    value={formValues[index]?.packDim}
                                                    onChange={(e) => handleInputChange(index, 'packDim', e.target.value)}
                                                />
                                            </td>
                                            <td className='noBottomBorder'>
                                                <FormControl
                                                    placeholder='Enter weight...'
                                                    value={formValues[index]?.weight}
                                                    onChange={(e) => handleInputChange(index, 'weight', e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Section 3.0 - Notes & Confirmation of Receipt</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th className='text-center'>Line</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newNotes.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className='small-qty text-center noBottomBorder'>3.{index + 1}</td>
                                                <td className='noBottomBorder'>{item}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td>

                                        </td>
                                        <td>
                                            <div className="w-100 d-flex flex-row align-items-center">

                                                    <Form.Control
                                                        onChange={(e) => setNoteVal(e.currentTarget.value)}
                                                        placeholder='Enter note...'
                                                        value={noteVal}
                                                    />
                                                <Button
                                                    onClick={() => {
                                                        const temp = newNotes
                                                        temp.push(noteVal)
                                                        setNoteVal("")
                                                    }}
                                                    className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                                    <MdAdd size={24} color="white" />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                
                    <div className="d-flex justify-content-end w-100">
                        <Button
                            onClick={() => saveDeliveryNote()}
                            className="button-green mt-5 w-auto">
                            Create delivery note
                        </Button>
                    </div>
                </div>
            </div>
        </Screen>
    )
}

export default DeliveryNote