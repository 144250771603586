import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Nav, Row, Tab, Table } from "react-bootstrap";
import searchIcon from "../../Images/icons8-search-96 (1).png";
import firebase from "firebase";
import { Quote } from "../Enquiry processing/QuotationView";
import DatePicker from "react-datepicker";
import { toast } from "react-hot-toast";
import moment from "moment";
import { globalUser } from "../../App";
import { SalesOrder } from './OrderProcessing';

interface SalesOrderCreateProps {
    show: boolean
    hide: () => void
    submit: () => void
}

const SalesOrderCreate = ({ show = false, hide, submit }: SalesOrderCreateProps) => {
    const [quoteTnNumber, setQuoteTnNumber] = useState<number>()
    const [nominalCodes, setNominalCodes] = useState([])
    const [selectedParts, setSelectedParts] = useState<{ quantity: number, partNumber: string, description: string, tagNumber: string, date?: string, nominalCode?: string }[]>()
    const [customParts, setCustomParts] = useState<{ quantity: number, partNumber: string, description: string, tagNumber: string, date?: string, nominalCode?: string }[]>([])
    const [customerPo, setCustomerPo] = useState<string>("")
    const [poRecievedDate, setPoRecievedDate] = useState<any>()
    const [quote, setQuote] = useState<Quote>()
    const currentUser = useContext(globalUser)

    const [customQuantity, setCustomQuantity] = useState<string>("")
    const [customPartNumber, setCustomPartNumber] = useState<string>("")
    const [customDescription, setCustomDescription] = useState<string>("")
    const [customTagNumber, setCustomTagNumber] = useState<string>("")
    const [customDate, setCustomDate] = useState<string>("")
    const [customNominalCode, setCustomNominalCode] = useState<string>("")
    const [noteText, setNoteText] = useState<string>("")
    const [noteTextLd, setNoteTextLd] = useState<string>("")
    const [notesLd, setNotesLd] = useState<{ text: string, username: string, date: string }[]>([])
    const [notes, setNotes] = useState<{ text: string, username: string, date: string }[]>([])

    const [tempDate, setTempDate] = useState<{ partId: string, date: string }[]>([])

    const addCustomPart = () => {
        setCustomParts([...customParts, { date: customDate, quantity: parseInt(customQuantity), tagNumber: customTagNumber, partNumber: customPartNumber, description: customDescription, nominalCode: customNominalCode }])
    }

    const getMostRecentQuote = (quotations: Quote[]): Quote | null => {
        if (quotations.length === 0) {
            return null; // Return null if no quotations exist
        }

        // Sort the quotations by `quoteDate` in descending order (most recent first)
        const sortedQuotations = quotations.sort((a, b) => {
            const dateA = new Date(a.quoteDate).getTime();
            const dateB = new Date(b.quoteDate).getTime();
            return dateB - dateA; // Sort by descending date
        });

        // Return the most recent quote (first in the sorted array)
        return sortedQuotations[0];
    };

    const quoteSearch = async () => {
        // Query the 'quotations' collection where 'tn' equals the tnNumber from the enquiry
        const quotationsQuery = firebase.firestore().collection("quotations")
            .where("tn", "==", quoteTnNumber);

        const quotationsSnapshot = await quotationsQuery.get();

        // Check if any quotation documents were found
        const quoteExists = !quotationsSnapshot.empty;

        const quotations: Quote[] = []
        if (quoteExists) {
            quotationsSnapshot.forEach(quotationDoc => {
                //@ts-ignore
                quotations.push(quotationDoc.data());
            });
        }

        const recent = getMostRecentQuote(quotations)
        if(recent) {
            setQuote(recent)
        }
    }

    const getNominalCodes = async () => {
        const db = firebase.firestore().collection("dropdowns").doc("nominalCodes");
        await db.get().then((doc) => {
            if (doc.exists) {
                // @ts-ignore
                setNominalCodes(doc.data().values)
            } else {
                setNominalCodes([])
            }
        })
    }

    const handlePartSelect = (value: { quantity: number, partNumber: string, description: string, tagNumber: string }) => {
        if (selectedParts) {
            if (!selectedParts.includes(value)) {
                setSelectedParts([...selectedParts, value])
            }
        } else {
            const temp = [];
            temp.push(value)
            setSelectedParts(temp)
        }
    }

    const saveSalesOrder = async () => {
        if (quote && selectedParts) {
            console.log(quote)
            const temp: SalesOrder = { parts: selectedParts, quote: quote, customerPo, poRecievedDate, closed: false, notes, notesLd, deliveryDates: tempDate };
            const db = firebase.firestore().collection("salesOrders").doc(`SO${quote.enquiry.tnNumber}`);
            if (temp) {
                await db.set(temp).then(() => {
                    toast.success(`SO${quote.enquiry.tnNumber} saved successfully`)
                    hide()
                })
            }
        }
    }

    useEffect(() => {
        setQuote(undefined)
    }, [quoteTnNumber])

    useEffect(() => {
        getNominalCodes()
    }, [])

    const mobileQuery = window.matchMedia('(max-width: 850px)')
    return (
        <Modal dialogClassName="large-dialog" show={show} onHide={() => hide()}>
            <Modal.Header closeButton>
                <Modal.Title>{`Create sales order`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tab.Container id="left-tabs-example" defaultActiveKey="selectQuote">
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="selectQuote">Select a quote</Nav.Link>
                                </Nav.Item>
                                {quote &&
                                    <Nav.Item>
                                        <Nav.Link eventKey="sales">Sales order information</Nav.Link>
                                    </Nav.Item>
                                }
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="selectQuote">
                                    <p className="form-label">Enter quote TN number:</p>
                                    <div className="d-flex flex-row align-items-center mb-3">
                                        <Form.Control value={quoteTnNumber}
                                            type="number"
                                            onChange={(event) => setQuoteTnNumber(parseInt(event.currentTarget.value))}
                                            className={`w-25 ${mobileQuery.matches && 'w-100'}`} placeholder="Enter TN number..." />
                                        <Button
                                            onClick={() => quoteSearch()}
                                            className="button-green button-green--search d-flex align-items-center justify-content-center w-auto ml-2">
                                            <img src={searchIcon} />
                                        </Button>
                                    </div>
                                    {quote &&
                                        <div>
                                            <div className="d-flex flex-row mb-4">
                                                <div className="d-flex flex-column">
                                                    <p className="font-weight-bold mb-1">Customer details:</p>
                                                    <p>{quote.enquiry.customer.name}</p>
                                                    <p>{quote.enquiry.customer.addressOne}</p>
                                                    <p>{quote.enquiry.customer.addressTwo}</p>
                                                    <p>{quote.enquiry.customer.addressThree}</p>
                                                    <p>{quote.enquiry.customer.townCity}</p>
                                                    <p>{quote.enquiry.customer.postCode}</p>
                                                </div>
                                            </div>
                                            <Table bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Quantity</th>
                                                        <th>Part Number</th>
                                                        <th>Description</th>
                                                        <th>Tag Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {quote.enquiry.parts.map((value: any, index: any, array: any) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{value.quantity}</td>
                                                                <td>{value.partNumber}</td>
                                                                <td>{value.description}</td>
                                                                <td>{value.tagNumber}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                </Tab.Pane>
                                {quote &&
                                    <Tab.Pane eventKey="sales">
                                        <div className="d-flex flex-row align-items-center justify-content-between mb-4">
                                            <div>
                                                <p className="form-label">Customer PO number:</p>
                                                <Form.Control value={customerPo}
                                                    onChange={(event) => setCustomerPo(event.currentTarget.value)}
                                                    placeholder="Enter PO number..." />
                                            </div>
                                            <div>
                                                <p className="form-label">PO received date:</p>
                                                <DatePicker
                                                    dateFormat={"dd/MM/yyyy"}
                                                    placeholderText="Enter date..."
                                                    className="form-control"
                                                    value={poRecievedDate}
                                                    onChange={(date) => {
                                                        if (date instanceof Date) {
                                                            setPoRecievedDate(date.toDateString())
                                                        }
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column mb-2">
                                            <p className="form-label">Stage payment profile:</p>
                                            {notes.map((value) => {
                                                return (
                                                    <div className="mb-3">
                                                        <p>{value.text}</p>
                                                        <div className="d-flex flex-row align-items-center">
                                                            <p className="font-weight-bold">{`- ${value.username} ${value.date}`}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <Form.Control
                                                onChange={(event) => setNoteText(event.currentTarget.value)}
                                                as="textarea"
                                                rows={3}
                                                value={noteText}
                                                placeholder="Enter a note..." />
                                            <div className="w-100 mt-2 d-flex justify-content-end">
                                                <Button className="button-green w-auto" onClick={() => {
                                                    let temp: { text: string, username: string, date: string }[] = []
                                                    if (notes !== undefined) {
                                                        temp = notes
                                                    }
                                                    temp.push({
                                                        text: noteText,
                                                        username: currentUser.currentUser.name,
                                                        date: moment().format("D/M/YYYY")
                                                    })
                                                    // @ts-ignore
                                                    setNotes(temp)
                                                    setNoteText("")
                                                }}>Add note</Button>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column mb-4">
                                            <p className="form-label">L.D.’s, bonds and guarantees:</p>
                                            {notesLd.map((value) => {
                                                return (
                                                    <div className="mb-3">
                                                        <p>{value.text}</p>
                                                        <div className="d-flex flex-row align-items-center">
                                                            <p className="font-weight-bold">{`- ${value.username} ${value.date}`}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <Form.Control
                                                onChange={(event) => setNoteTextLd(event.currentTarget.value)}
                                                as="textarea"
                                                rows={3}
                                                value={noteTextLd}
                                                placeholder="Enter a note..." />
                                            <div className="w-100 mt-2 d-flex justify-content-end">
                                                <Button className="button-green w-auto" onClick={() => {
                                                    let temp: { text: string, username: string, date: string }[] = []
                                                    if (notesLd !== undefined) {
                                                        temp = notesLd
                                                    }
                                                    temp.push({
                                                        text: noteTextLd,
                                                        username: currentUser.currentUser.name,
                                                        date: moment().format("D/M/YYYY")
                                                    })
                                                    // @ts-ignore
                                                    setNotesLd(temp)
                                                    setNoteTextLd("")
                                                }}>Add note</Button>
                                            </div>
                                        </div>
                                        <p className="form-label">Select line items for sales order:</p>
                                        <Table className="mb-4" bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Quantity</th>
                                                    <th>Part Number</th>
                                                    <th>Description</th>
                                                    <th>Tag Number</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {quote.enquiry.parts.map((value: any, index: any, array: any) => {
                                                    return (
                                                        <tr onClick={() => handlePartSelect(value)}>
                                                            <td>{index + 1}</td>
                                                            <td>{value.quantity}</td>
                                                            <td>{value.partNumber}</td>
                                                            <td>{value.description}</td>
                                                            <td>{value.tagNumber}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                        {selectedParts &&
                                            <>
                                                <p className="form-label">Items selected for sales order:</p>
                                                <Table className="mb-4" bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Quantity</th>
                                                            <th>Part Number</th>
                                                            <th>Description</th>
                                                            <th>Tag Number</th>
                                                            <th>Delivery date</th>
                                                            <th>Nominal codes</th>
                                                            <th>Notes</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {selectedParts.map((value: any, index: any, array: any) => {
                                                            const dateVal = tempDate.filter((partDate) => partDate.partId === value.partNumber)
                                                            return (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{value.quantity}</td>
                                                                    <td>{value.partNumber}</td>
                                                                    <td>{value.description}</td>
                                                                    <td>{value.tagNumber}</td>
                                                                    <td>
                                                                        <DatePicker
                                                                            dateFormat={"dd/MM/yyyy"}
                                                                            placeholderText="Enter date..."
                                                                            className="form-control"
                                                                            value={dateVal.length > 0 ? dateVal[0].date : ""}
                                                                            onChange={(date) => {
                                                                                if (date instanceof Date) {
                                                                                    const tempArr = tempDate.filter((partDate) => partDate.partId === value.partNumber)
                                                                                    tempArr.push({ date: date.toDateString(), partId: value.partNumber })
                                                                                    setTempDate(tempArr)
                                                                                    // @ts-ignore
                                                                                    // temp.find(v => v.partNumber === value.partNumber).date = date.toDateString()
                                                                                    // setSelectedParts(temp)
                                                                                }
                                                                            }} />
                                                                    </td>
                                                                    <td>
                                                                        <Form.Control as="select">
                                                                            <option value={""}>Select an option...</option>
                                                                            {nominalCodes.map((value) => <option value={value}>{value}</option>)}
                                                                        </Form.Control>
                                                                    </td>
                                                                    <td>
                                                                        <Form.Control as="textarea" rows={3} placeholder="Additional notes..." />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        {customParts.map((value: any, index: any, array: any) => {
                                                            return (
                                                                <tr>
                                                                    <td>{(selectedParts.length + 1)}</td>
                                                                    <td>
                                                                        <Form.Control value={customQuantity}
                                                                            onChange={(event) => setCustomQuantity(event.currentTarget.value)}
                                                                            placeholder="Quantity..." />
                                                                    </td>
                                                                    <td>
                                                                        <Form.Control value={customPartNumber}
                                                                            onChange={(event) => setCustomPartNumber(event.currentTarget.value)}
                                                                            placeholder="Part Number..." />
                                                                    </td>
                                                                    <td>
                                                                        <Form.Control value={customDescription}
                                                                            onChange={(event) => setCustomDescription(event.currentTarget.value)}
                                                                            placeholder="Description..." />
                                                                    </td>
                                                                    <td>
                                                                        <Form.Control value={customTagNumber}
                                                                            onChange={(event) => setCustomTagNumber(event.currentTarget.value)}
                                                                            placeholder="Tag Number..." />
                                                                    </td>
                                                                    <td>
                                                                        <DatePicker
                                                                            dateFormat={"dd/MM/yyyy"}
                                                                            placeholderText="Enter date..."
                                                                            className="form-control"
                                                                            value={customDate || ""}
                                                                            onChange={(date) => {
                                                                                if (date instanceof Date) {
                                                                                    // @ts-ignore
                                                                                    setCustomDate(date.toDateString())
                                                                                }
                                                                            }} />
                                                                    </td>
                                                                    <td>
                                                                        <Form.Control as="select">
                                                                            <option value={""}>Select an option...</option>
                                                                            {nominalCodes.map((value) => <option value={value}>{value}</option>)}
                                                                        </Form.Control>
                                                                    </td>
                                                                    <td>
                                                                        <Form.Control as="textarea" rows={3} placeholder="Additional notes..." />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                                {/* <div className="d-flex justify-content-end">
                                                    <Button className="button-green w-auto mt-2" onClick={() => addCustomPart()}>Add part</Button>
                                                </div> */}
                                            </>
                                        }
                                    </Tab.Pane>
                                }
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Modal.Body>
            <Modal.Footer>
                {selectedParts &&
                    <Button onClick={() => {
                        saveSalesOrder().then(() => submit())
                    }} className="button-green w-auto">
                        Save sales order
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default SalesOrderCreate