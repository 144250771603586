import React, { useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import { Link, useHistory } from "react-router-dom";
import { Button, Form, Table } from "react-bootstrap";
import logo from "../../Images/logo-dark.png";
import firebase from "firebase";
import moment from "moment";

const CreateBomDocument = () => {
    const history = useHistory();
    const [bom, setBom] = useState<{ partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }[]>([])
    const [bomRef, setBomRef] = useState<string>("")
    const [quantity, setQuantity] = useState<number>(1)
    const [previewMode, setPreviewMode] = useState<boolean>(false)
    const [bomDescription, setBomDescription] = useState<string>("")

    const getBomRefDescription = async () => {
        // @ts-ignore
        const bomRefState: string = history.location.state.bomRef;
        const query = firebase.firestore().collection("parts").doc(bomRefState)
        await query.get().then((doc) => {
            console.log("Request, Indented")
            // @ts-ignore
            setBomDescription(doc.data().description)
        })
    }

    const dateRaised = moment().format("D/M/YYYY");

    useEffect(() => {
        // @ts-ignore
        const bomState: { partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }[] = history.location.state.bomState;
        // @ts-ignore
        const bomRefState: string = history.location.state.bomRef;
        setBom(bomState)
        setBomRef(bomRefState)
        getBomRefDescription().then(() => {
            // @ts-ignore
            if (history.location.state.print === "true") {
                setPreviewMode(true)
                window.print()
            }
        })
    }, [])

    // @ts-ignore
    const bomState: { partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }[] = history.location.state.bomState;
    // @ts-ignore
    const bomRefState: string = history.location.state.bomRef;

    return (
        <>
            {previewMode ?
                <div className="createBomDocument bg-white">
                    <img className="mb-5" src={logo} />
                    <h5 className="mb-0">INDENTED PARTS LIST</h5>
                    <h2 className="mb-5 purchaseOrdersCreate-template__green-text">{`BOM REFERENCE NO. ${bomRef}`}</h2>
                    <div className="d-flex flex-row justify-content-between mt-4 mb-5">
                        <div className="d-flex align-items-center flex-row">
                            <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">DESCRIPTION:</h5>
                            <p style={{ fontSize: "18px" }}>{bomDescription}</p>
                        </div>
                        <div className="d-flex align-items-center flex-row">
                            <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">QUANTITY:</h5>
                            <p style={{ fontSize: "18px" }}>{quantity}</p>
                        </div>
                        <div className="d-flex align-items-center flex-row">
                            <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">DATE:</h5>
                            <p style={{ fontSize: "18px" }}>{dateRaised}</p>
                        </div>
                    </div>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Level</th>
                                <th>Seq.</th>
                                <th>Part number</th>
                                <th>Description</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bom?.map((item) => {
                                return (
                                    <tr>
                                        <td>{item.level}</td>
                                        <td>{item.sequence}</td>
                                        <td style={{ paddingLeft: `${item.level - 0.25}rem` }}>{item.partNumber}</td>
                                        <td>{item.description}</td>
                                        <td>{item.partQuantity * quantity}</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </Table>
                    <div className="d-flex justify-content-end align-items-center pt-5">
                        <Button className="button-green w-auto hide-on-print" onClick={() => setPreviewMode(false)}>Close preview</Button>
                        <Button className="button-green w-auto ml-2 hide-on-print" onClick={() => window.print()}>Print PDF</Button>
                    </div>
                </div>
                :
                <Screen active="createBomDocument">
                    <div className="createBomDocument bg-white">
                        <img className="mb-5" src={logo} />
                        <h5 className="mb-0">INDENTED PARTS LIST</h5>
                        <h2 className="mb-5 purchaseOrdersCreate-template__green-text">{`BOM REFERENCE NO. ${bomRef}`}</h2>
                        <div className="d-flex flex-row justify-content-between mt-4 mb-5">
                            <div className="d-flex align-items-center flex-row">
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">DESCRIPTION:</h5>
                                <p style={{ fontSize: "18px" }}>{bomDescription}</p>
                            </div>
                            <div className="d-flex align-items-center flex-row">
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">QUANTITY:</h5>
                                <Form.Control value={quantity}
                                    onChange={(event) => setQuantity(parseInt(event.currentTarget.value))}
                                    type="Number" placeholder="Quantity..." />
                            </div>
                            <div className="d-flex align-items-center flex-row">
                                <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">DATE:</h5>
                                <p style={{ fontSize: "18px" }}>{dateRaised}</p>
                            </div>
                        </div>
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>Level</th>
                                    <th>Seq.</th>
                                    <th>Part number</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bom?.map((item) => {
                                    if (item.level === 2) {
                                        return (
                                            <tr>
                                                <td>{item.level}</td>
                                                <td>{item.subSequence + 1}.00</td>
                                                <td style={{ paddingLeft: `${item.level - 0.25}rem` }}>{item.partNumber}</td>
                                                <td>{item.description || ""}</td>
                                                <td>{item.partQuantity}</td>
                                            </tr>
                                        )
                                    }
                                    return (
                                        <tr>
                                            <td>{item.level}</td>
                                            <td>{item.sequence + 1}.00</td>
                                            <td style={{ paddingLeft: `${item.level - 0.25}rem` }}>{item.partNumber}</td>
                                            <td>{item.description}</td>
                                            <td>{item.partQuantity * quantity}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end align-items-center pt-5">
                            <Button className="button-green w-auto" onClick={() => setPreviewMode(true)}>View preview</Button>
                        </div>
                    </div>
                </Screen>
            }
        </>
    )
}

export default CreateBomDocument