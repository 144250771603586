import React, { useContext, useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import { Button, Card, Modal, Table } from "react-bootstrap";
import firebase from "firebase";
import logo from "../../Images/logo-dark.png"
import { useParams } from 'react-router-dom';
import { PurchaseOrder } from "./PurchaseOrdersCreate";
import moment from "moment";
import { globalUser, Notification, User } from "../../App";
import { toast } from "react-hot-toast";
import Footer from '../../Components/Footer';

const PurchaseOrdersView = () => {
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>()
    const [approver, setApprover] = useState<User>()
    const [raisedBy, setRaisedBy] = useState<User>()
    const [printing, setPrinting] = useState(false);

    const currentUser = useContext(globalUser)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePrint = () => {
        setPrinting(true)
    }

    // @ts-ignore
    let { id } = useParams();

    const getTotalPrice = () => {
        let priceArray: number[] = [];
        purchaseOrder?.parts.map((partItem) => {
            if (partItem) {
                priceArray.push(partItem.quantity * partItem.price)
            }
        })
        purchaseOrder?.extraNew?.manualLines.map((man) => {
            priceArray.push(man.price * man.qty)
        })
        return priceArray.reduce((a, b) => a + b, 0)
    }

    const getGross = () => {
        return getTotalPrice() + ((getTotalPrice() / 100) * 20)
    }

    const getPurchaseOrder = async () => {
        const query = firebase.firestore().collection('purchaseOrders').doc(id);
        await query.get().then((doc) => {
            // @ts-ignore
            setPurchaseOrder(doc.data())
        })
    }

    const getApprover = async (id: string) => {
        const query = firebase.firestore().collection('users').doc(id);
        await query.get().then((doc) => {
            // @ts-ignore
            setApprover({
                ...doc.data(),
                uid: doc.id
            })
        })
    }

    const getRaisedBy = async (id: string) => {
        const query = firebase.firestore().collection('users').doc(id);
        await query.get().then((doc) => {
            // @ts-ignore
            setRaisedBy({
                ...doc.data(),
                uid: doc.id
            })
        })
    }

    const approvePo = async () => {
        if (purchaseOrder) {
            let newPurchaseOrder: PurchaseOrder = {
                ...purchaseOrder,
                approved: true
            }
            const db = firebase.firestore().collection("purchaseOrders");
            await db.doc(purchaseOrder.id.toString()).set(newPurchaseOrder).then(() => {
                getPurchaseOrder()
                handleClose()
                toast.success(`Purchase order approved!`)
            }).then(async () => {
                const newNotification: Notification = {
                    type: "PO Approval",
                    text: "Your purchase order has been approved",
                    link: `/purchase-orders/view/${purchaseOrder.id}`,
                    dateTime: new Date().toDateString(),
                    viewed: false,
                }
                const notifyQuery = firebase.firestore().collection("notifications").doc(purchaseOrder.raisedById)
                await notifyQuery.get().then((doc) => {
                    if (doc.exists) {
                        // @ts-ignore
                        firebase.firestore().collection("notifications").doc(purchaseOrder.raisedById).set({ notifications: [newNotification, ...doc.data().notifications] })
                    } else {
                        // @ts-ignore
                        firebase.firestore().collection("notifications").doc(purchaseOrder.raisedById).set({ notifications: [newNotification] })
                    }
                })
            })
        }
    }


    useEffect(() => {
        getPurchaseOrder()
    }, [])

    useEffect(() => {
        if (purchaseOrder) {
            getApprover(purchaseOrder.approverId)
            getRaisedBy(purchaseOrder.raisedById)
        }
    }, [purchaseOrder])


    if ((printing && purchaseOrder && approver && raisedBy)) {
        return (
            <div className="purchaseOrdersView">
                <div className="purchaseOrdersCreate-template p-1">
                    <img className="mb-3" src={logo} />
                    <div className='d-flex flex-row justify-content-between align-items-end'>
                        <h1>Purchase Order</h1>
                        <p className="mb-3">{moment().format('DD/MM/YYYY')}</p>
                    </div>
                    <div className='doc-section'>
                            <div className='doc-section-title'>
                                <h4>Section 1.0 - Reference Numbers & Address</h4>
                            </div>
                            <div className='doc-section-content'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Our Purchase Order Number</th>
                                            <th>Supplier</th>
                                            <th>Delivery Address</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{purchaseOrder.id}</td>
                                            <td className='noBottomBorder'>
                                                {purchaseOrder.supplier?.name}
                                            </td>
                                            <td className='noBottomBorder'>
                                                {purchaseOrder.extraNew?.delAddress.lineOne}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='font-weight-bold  mid-cell'>Your Reference</td>
                                            <td className='noBottomBorder'><p>{purchaseOrder.supplier?.addressOne}</p></td>
                                            <td className='noBottomBorder'>
                                                {purchaseOrder.extraNew?.delAddress.lineTwo}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {purchaseOrder.ref}
                                            </td>
                                            <td className='noBottomBorder'><p>{purchaseOrder.supplier?.townCity}</p></td>
                                            <td className='noBottomBorder'>
                                                {purchaseOrder.extraNew?.delAddress.town}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='font-weight-bold'>Contact</td>
                                            <td className='noBottomBorder'><p>{purchaseOrder.supplier?.postCode}</p></td>
                                            <td className='noBottomBorder'>
                                                {purchaseOrder.extraNew?.delAddress.postcode}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {purchaseOrder.supplierContact?.name}
                                            </td>
                                            <td>

                                            </td>
                                            <td>
                                                {purchaseOrder.extraNew?.delAddress.country}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className='doc-section'>
                            <div className='doc-section-title'>
                                <h4>Section 2.0 - Quantity, Description & Price</h4>
                            </div>
                            <div className='doc-section-content'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>Line</th>
                                            <th className='text-right'>Qty</th>
                                            <th>Description</th>
                                            <th className='text-center'>Part Number</th>
                                            <th className='text-center'>SO Number</th>
                                            <th>Due Date</th>
                                            <th className='text-right'>Unit Price</th>
                                            <th className='text-right'>Line Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {purchaseOrder.parts.map((part, index) => {
                                            // @ts-ignore

                                            return (
                                                <tr>
                                                    <td className='text-center noBottomBorder'>2.{index + 1}</td>
                                                    <td className='text-right noBottomBorder'>
                                                        {part.quantity}
                                                    </td>
                                                    <td className='noBottomBorder'>{part.part.description}, {part.part.dimensions}, {part.part.material}, {part.part.finish}</td>
                                                    <td className='text-center noBottomBorder'>
                                                        {part.part.id}
                                                    </td>
                                                    <td className='text-center noBottomBorder'>SO{
                                                        //@ts-ignore
                                                        purchaseOrder.soNumber || ""
                                                    }</td>
                                                    <td className='noBottomBorder' style={{ maxWidth: "8rem" }}>
                                                        {moment(part.dueDate).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td className='text-right noBottomBorder' style={{ maxWidth: "8rem" }}>
                                                        £{part.price.toFixed(2)}
                                                    </td>
                                                    <td className='text-right noBottomBorder'>£{(part.price * part.quantity).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                        {purchaseOrder.parts.length > 0 &&
                                            <tr>
                                                <td className='text-center noBottomBorder'>2.{purchaseOrder.parts && purchaseOrder.parts.length + 1}</td>
                                                <td className='noBottomBorder'></td>
                                                <td className='noBottomBorder'></td>
                                                <td className='noBottomBorder'></td>
                                                <td className='noBottomBorder'></td>
                                                <td className='noBottomBorder'></td>
                                                <td className='noBottomBorder'></td>
                                                <td className='noBottomBorder'></td>
                                            </tr>
                                        }
                                        {purchaseOrder.extraNew?.manualLines.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className='text-center noBottomBorder'>2.{purchaseOrder.parts && (purchaseOrder.parts.length + 1 + index + (purchaseOrder.parts.length > 0 ? 1 : 0))}</td>
                                                    <td className='text-right noBottomBorder'>{item.qty}</td>
                                                    <td className='noBottomBorder'>{item.desc}</td>
                                                    <td className='noBottomBorder'>{item.partNumber}</td>
                                                    <td className='noBottomBorder'></td>
                                                    <td className='noBottomBorder'>{moment(item.dueDate).isValid() ? moment(item.dueDate).format("DD/MM/YYYY") : ""}</td>
                                                    <td className='text-right noBottomBorder'>£{item.price.toFixed(2)}</td>
                                                    <td className='text-right noBottomBorder'>£{(item.price * item.qty).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td className='text-center borderTop'>2.{purchaseOrder.parts && purchaseOrder.extraNew?.manualLines && (purchaseOrder.parts.length + purchaseOrder.extraNew?.manualLines.length + (purchaseOrder.parts.length > 0 ? 2 : 1))}</td>
                                            <td className='borderTop'>

                                            </td>
                                            <td className='borderTop'>

                                            </td>
                                            <td className='no-border borderTop'></td>
                                            <td className='no-border borderTop'></td>
                                            <td className='no-border borderTop'></td>
                                            <td className='no-border text-right borderTop'>
                                                Sub Total, Nett
                                            </td>
                                            <td className='text-right borderTop'>
                                                £{getTotalPrice().toFixed(2)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-center'>2.{purchaseOrder.parts && purchaseOrder.extraNew?.manualLines && (purchaseOrder.parts.length + purchaseOrder.extraNew?.manualLines.length + (purchaseOrder.parts.length > 0 ? 3 : 2))}</td>
                                            <td>

                                            </td>
                                            <td>

                                            </td>
                                            <td className='no-border'></td>
                                            <td className='no-border'></td>
                                            <td className='no-border'></td>
                                            <td className='no-border text-right'>
                                                VAT
                                            </td>
                                            <td className='text-right'>
                                                £{((getTotalPrice() / 100) * 20).toFixed(2)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-center'>2.{purchaseOrder.parts && purchaseOrder.extraNew?.manualLines && (purchaseOrder.parts.length + purchaseOrder.extraNew?.manualLines.length + (purchaseOrder.parts.length > 0 ? 4 : 3))}</td>
                                            <td>

                                            </td>
                                            <td>

                                            </td>
                                            <td className='no-border'></td>
                                            <td className='no-border'></td>
                                            <td className='no-border'></td>
                                            <td className='no-border text-right'>
                                                Total, Gross
                                            </td>
                                            <td className='text-right'>
                                                £{getGross().toFixed(2)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className='doc-section'>
                            <div className='doc-section-title'>
                                <h4>Section 3.0 - Scope of Supply</h4>
                            </div>
                            <div className='doc-section-content'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Line</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='noBottomBorder'>3.1</td>
                                            <td className='noBottomBorder'>
                                                {purchaseOrder.terms}
                                            </td>
                                        </tr>
                                        {purchaseOrder.scope.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className='noBottomBorder'>3.{index + 2}</td>
                                                    <td className='noBottomBorder'>{item}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                    <div className='doc-section'>
                        <div className='doc-section-content'>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td className='font-weight-bold w-15'>Purchase Order Created By</td>
                                        <td className='w-35'>
                                            <p>{raisedBy.name}</p>
                                            <p>{raisedBy.role}</p>
                                        </td>
                                        <td className='font-weight-bold w-15'>Purchase Order Approved by</td>
                                        <td className='w-35'>
                                            <p>{approver.name}</p>
                                            <p>{approver.role}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <Footer />
                    {purchaseOrder.approved &&
                        <div className="d-flex flex-row justify-content-end w-100 mt-5 PO-printing">
                            <div className="d-flex flex-row align-items-center">
                                <Button
                                    onClick={() => setPrinting(false)}
                                    className="button-green ml-3">
                                    Cancel preview
                                </Button>
                                <Button
                                    onClick={() => window.print()}
                                    className="button-green ml-3">
                                    Download PDF
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            </div>)
    }

    return (
        <Screen active="Purchase orders view">
            {(purchaseOrder && approver && raisedBy) &&
                <div className="purchaseOrdersView">
                    <div className="purchaseOrdersCreate-template">
                        <img className="mb-5" src={logo} />
                        <div className='d-flex flex-row justify-content-between align-items-end'>
                            <h1>Purchase Order</h1>
                            <p className="mb-3">{moment().format('DD/MM/YYYY')}</p>
                        </div>
                        <div className='doc-section'>
                            <div className='doc-section-title'>
                                <h4>Section 1.0 - Reference Numbers & Address</h4>
                            </div>
                            <div className='doc-section-content'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Our Purchase Order Number</th>
                                            <th>Supplier</th>
                                            <th>Delivery Address</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{purchaseOrder.id}</td>
                                            <td className='noBottomBorder'>
                                                {purchaseOrder.supplier?.name}
                                            </td>
                                            <td className='noBottomBorder'>
                                                {purchaseOrder.extraNew?.delAddress.lineOne}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='font-weight-bold  mid-cell'>Your Reference</td>
                                            <td className='noBottomBorder'><p>{purchaseOrder.supplier?.addressOne}</p></td>
                                            <td className='noBottomBorder'>
                                                {purchaseOrder.extraNew?.delAddress.lineTwo}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {purchaseOrder.ref}
                                            </td>
                                            <td className='noBottomBorder'><p>{purchaseOrder.supplier?.townCity}</p></td>
                                            <td className='noBottomBorder'>
                                                {purchaseOrder.extraNew?.delAddress.town}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='font-weight-bold'>Contact</td>
                                            <td className='noBottomBorder'><p>{purchaseOrder.supplier?.postCode}</p></td>
                                            <td className='noBottomBorder'>
                                                {purchaseOrder.extraNew?.delAddress.postcode}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {purchaseOrder.supplierContact?.name}
                                            </td>
                                            <td>

                                            </td>
                                            <td>
                                                {purchaseOrder.extraNew?.delAddress.country}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className='doc-section'>
                            <div className='doc-section-title'>
                                <h4>Section 2.0 - Quantity, Description & Price</h4>
                            </div>
                            <div className='doc-section-content'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>Line</th>
                                            <th className='text-right'>Qty</th>
                                            <th>Description</th>
                                            <th className='text-center'>Part Number</th>
                                            <th className='text-center'>SO Number</th>
                                            <th>Due Date</th>
                                            <th className='text-right'>Unit Price</th>
                                            <th className='text-right'>Line Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {purchaseOrder.parts.map((part, index) => {
                                            // @ts-ignore

                                            return (
                                                <tr>
                                                    <td className='text-center noBottomBorder'>2.{index + 1}</td>
                                                    <td className='text-right noBottomBorder'>
                                                        {part.quantity}
                                                    </td>
                                                    <td className='noBottomBorder'>{part.part.description}, {part.part.dimensions}, {part.part.material}, {part.part.finish}</td>
                                                    <td className='text-center noBottomBorder'>
                                                        {part.part.id}
                                                    </td>
                                                    <td className='text-center noBottomBorder'>SO{
                                                        //@ts-ignore
                                                        purchaseOrder.soNumber || ""
                                                    }</td>
                                                    <td className='noBottomBorder' style={{ maxWidth: "8rem" }}>
                                                        {moment(part.dueDate).format("DD/MM/YYYY")}
                                                    </td>
                                                    <td className='text-right noBottomBorder' style={{ maxWidth: "8rem" }}>
                                                        £{part.price.toFixed(2)}
                                                    </td>
                                                    <td className='text-right noBottomBorder'>£{(part.price * part.quantity).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                        {purchaseOrder.parts.length > 0 &&
                                            <tr>
                                                <td className='text-center noBottomBorder'>2.{purchaseOrder.parts && purchaseOrder.parts.length + 1}</td>
                                                <td className='noBottomBorder'></td>
                                                <td className='noBottomBorder'></td>
                                                <td className='noBottomBorder'></td>
                                                <td className='noBottomBorder'></td>
                                                <td className='noBottomBorder'></td>
                                                <td className='noBottomBorder'></td>
                                                <td className='noBottomBorder'></td>
                                            </tr>
                                        }
                                        {purchaseOrder.extraNew?.manualLines.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className='text-center noBottomBorder'>2.{purchaseOrder.parts && (purchaseOrder.parts.length + 1 + index + (purchaseOrder.parts.length > 0 ? 1 : 0))}</td>
                                                    <td className='text-right noBottomBorder'>{item.qty}</td>
                                                    <td className='noBottomBorder'>{item.desc}</td>
                                                    <td className='noBottomBorder'>{item.partNumber}</td>
                                                    <td className='noBottomBorder'></td>
                                                    <td className='noBottomBorder'>{moment(item.dueDate).isValid() ? moment(item.dueDate).format("DD/MM/YYYY") : ""}</td>
                                                    <td className='text-right noBottomBorder'>£{item.price.toFixed(2)}</td>
                                                    <td className='text-right noBottomBorder'>£{(item.price * item.qty).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td className='text-center borderTop'>2.{purchaseOrder.parts && purchaseOrder.extraNew?.manualLines && (purchaseOrder.parts.length + purchaseOrder.extraNew?.manualLines.length + (purchaseOrder.parts.length > 0 ? 2 : 1))}</td>
                                            <td className='borderTop'>

                                            </td>
                                            <td className='borderTop'>

                                            </td>
                                            <td className='no-border borderTop'></td>
                                            <td className='no-border borderTop'></td>
                                            <td className='no-border borderTop'></td>
                                            <td className='no-border text-right borderTop'>
                                                Sub Total, Nett
                                            </td>
                                            <td className='text-right borderTop'>
                                                £{getTotalPrice().toFixed(2)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-center'>2.{purchaseOrder.parts && purchaseOrder.extraNew?.manualLines && (purchaseOrder.parts.length + purchaseOrder.extraNew?.manualLines.length + (purchaseOrder.parts.length > 0 ? 3 : 2))}</td>
                                            <td>

                                            </td>
                                            <td>

                                            </td>
                                            <td className='no-border'></td>
                                            <td className='no-border'></td>
                                            <td className='no-border'></td>
                                            <td className='no-border text-right'>
                                                VAT
                                            </td>
                                            <td className='text-right'>
                                                £{((getTotalPrice() / 100) * 20).toFixed(2)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-center'>2.{purchaseOrder.parts && purchaseOrder.extraNew?.manualLines && (purchaseOrder.parts.length + purchaseOrder.extraNew?.manualLines.length + (purchaseOrder.parts.length > 0 ? 4 : 3))}</td>
                                            <td>

                                            </td>
                                            <td>

                                            </td>
                                            <td className='no-border'></td>
                                            <td className='no-border'></td>
                                            <td className='no-border'></td>
                                            <td className='no-border text-right'>
                                                Total, Gross
                                            </td>
                                            <td className='text-right'>
                                                £{getGross().toFixed(2)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className='doc-section'>
                            <div className='doc-section-title'>
                                <h4>Section 3.0 - Scope of Supply</h4>
                            </div>
                            <div className='doc-section-content'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Line</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='noBottomBorder'>3.1</td>
                                            <td className='noBottomBorder'>
                                                {purchaseOrder.terms}
                                            </td>
                                        </tr>
                                        {purchaseOrder.scope.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className='noBottomBorder'>3.{index + 2}</td>
                                                    <td className='noBottomBorder'>{item}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className='doc-section'>
                            <div className='doc-section-content'>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td className='font-weight-bold w-15'>Purchase Order Created By</td>
                                            <td className='w-35'>
                                                <p>{raisedBy.name}</p>
                                                <p>{raisedBy.role}</p>
                                            </td>
                                            <td className='font-weight-bold w-15'>Purchase Order Approved by</td>
                                            <td className='w-35'>
                                                <p>{approver.name}</p>
                                                <p>{approver.role}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        {!purchaseOrder.approved &&
                            <div className="d-flex flex-row justify-content-end w-100 mt-5">
                                <div className="d-flex flex-row align-items-center">

                                    {currentUser.currentUser.uid === approver.uid &&
                                        <Button
                                            onClick={() => handleShow()}
                                            className="button-green ml-3">
                                            Approve this PO
                                        </Button>
                                    }
                                </div>
                            </div>
                        }
                        {purchaseOrder.approved &&
                            <div className="d-flex flex-row justify-content-end w-100 mt-5">
                                <div className="d-flex flex-row align-items-center">
                                    <Button
                                        onClick={() => handlePrint()}
                                        className="button-green ml-3">
                                        View preview & print
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Please confirm approval</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        onClick={() => {
                            approvePo()
                        }}
                        className="button-green w-auto">
                        Approve
                    </Button>
                </Modal.Footer>
            </Modal>
        </Screen>
    )
}

export default PurchaseOrdersView