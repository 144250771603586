import React, { useEffect, useState } from 'react';
import Screen from "../../../Components/Screen";
import firebase from "firebase";
import logo from "../../../Images/logo-dark.png";
import { Button, Card, Table } from "react-bootstrap";
import { useLocation } from 'react-router-dom';

const JobsClosedInMonth = () => {
    const [totalClosed, setTotalClosed] = useState<number>(0);
    const [percentageOnTime, setPercentageOnTime] = useState<number>(0);
    const location = useLocation();

    const getMonthName = (monthIndex: number): string => {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return monthNames[monthIndex];
    };

    const calculateJobsClosedInMonth = async (month: string, year: string) => {
        const monthIndex = parseInt(month, 10) - 1;
        const yearNumber = parseInt(year, 10);

        const salesOrdersRef = firebase.firestore().collection('salesOrders');
        const salesOrdersSnapshot = await salesOrdersRef.get();

        let closedJobs = 0;
        let onTimeJobs = 0;

        salesOrdersSnapshot.forEach(doc => {
            if(doc.data().closed === true) {
                const closedDate = new Date(doc.data().closedDate);
                const deliveryDate = new Date(doc.data().OA.deliveryDate);
    
                if (closedDate.getFullYear() === yearNumber && closedDate.getMonth() === monthIndex) {
                    closedJobs += 1;
                    if (deliveryDate <= closedDate) {
                        onTimeJobs += 1;
                    }
                }
            }
        });

        setTotalClosed(closedJobs);
        setPercentageOnTime(closedJobs === 0 ? 0 : (onTimeJobs / closedJobs) * 100);
    };

    useEffect(() => {
        //@ts-ignore
        const month = location.state?.month;
        //@ts-ignore
        const year = location.state?.year;

        if (month && year) {
            calculateJobsClosedInMonth(month, year);
        }
    }, [location.state]);

    //@ts-ignore
    const title = `Delivery performance in ${getMonthName(parseInt(location.state?.month, 10) - 1)} ${location.state?.year}`;

    return (
        <Screen active="Reports">
            <div className="purchaseOrdersView">
                <div className="purchaseOrdersCreate-template">
                    <img className="mb-5" src={logo} />
                    <h2 className="mb-5 purchaseOrdersCreate-template__green-text text-uppercase">{title}</h2>
                    <Card className="mb-4">
                        <Card.Header>
                            <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 1.0 – JOB STATISTICS</h4>
                        </Card.Header>
                        <Card.Body>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Total Closed</th>
                                        <th>Percentage on Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{totalClosed}</td>
                                        <td>{percentageOnTime.toFixed(2)}%</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <div className="d-flex flex-row justify-content-end w-100 mt-5">
                        <div className="d-flex flex-row align-items-center">
                            {/* <Button className="button-green ml-3">
                                View preview & print
                            </Button> */}
                        </div>
                    </div>
                </div>
            </div>
        </Screen>
    );
}

export default JobsClosedInMonth;
