import React, { useContext, useEffect, useState } from 'react';
import firebase from "firebase";
import { Button, Col, Form, FormControl, Modal, Nav, Row, Tab, Table } from "react-bootstrap";
import { Enquiry } from "./Enquiries";
import { Typeahead } from "react-bootstrap-typeahead";
import { Customer } from "../ControlPanel";
import { globalUser, User } from "../../App";
import DatePicker from "react-datepicker";
import moment from "moment";
import { assessment } from "../../Static Data/enquiryAssessment";
import { MdAdd } from "react-icons/all";
import TrafficLights from "../../Components/TrafficLights";
import { toast } from "react-hot-toast";

interface EnquiriesCreateProps {
    show: boolean
    hide: () => void
    submit: () => void
}

const EnquiriesCreate = ({ show = false, hide, submit }: EnquiriesCreateProps) => {
    const [tnNumber, setTnNumber] = useState<number>(0)
    const [enquiryForm, setEnquiryForm] = useState<Enquiry>()
    const [key, setKey] = useState<string | null>("enquiryInformation")

    const [customers, setCustomers] = useState<Customer[]>([])
    const [thermalScope, setThermalScope] = useState<string[]>([])
    const [mechanicalScope, setMechanicalScope] = useState<string[]>([])
    const [draughting, setDraughting] = useState<string[]>([])
    const [enquiryOwners, setEnquiryOwners] = useState<User[]>([])
    const [selectedCustomer, setSelectedCustomer] = useState<Customer>()
    const [noteText, setNoteText] = useState<string>("")
    const [partSearchText, setPartSearchText] = useState<string>("")
    const [partList, setPartList] = useState<{ partNumber: string, description?: string, quantity?: string, tagNumber?: string, price?: number }[]>([])

    const currentUser = useContext(globalUser)

    const removePart = (partNumber: string) => {
        const temp = partList.filter((v) => v.partNumber !== partNumber);
        setPartList(temp)
    }

    const getTnNumber = async () => {
        const db = firebase.firestore().collection("enquiries");
        const query = db.orderBy("tnNumber", "desc").limit(1).get()
        await query.then((querySnapshot) => {
            if (querySnapshot.size === 0) {
                setTnNumber(1)
            } else {
                setTnNumber(querySnapshot.docs[0].data().tnNumber + 1)
            }
        })
    }

    const getAllUsers = async () => {
        const db = firebase.firestore().collection("users");
        let temp: User[] = []
        await db.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                temp.push({
                    name: doc.data().name,
                    uid: doc.id,
                    role: doc.data().role,
                    email: doc.data().email,
                    permission: doc.data().permission,
                })
            });
        }).then(() => setEnquiryOwners(temp))
    }

    const getCustomers = async () => {
        const db = firebase.firestore().collection("customers");
        let temp: Customer[] = []
        await db.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                temp.push({
                    id: doc.id,
                    addressOne: doc.data().addressOne,
                    addressTwo: doc.data().addressTwo,
                    addressThree: doc.data().addressThree,
                    name: doc.data().name,
                    postCode: doc.data().postCode,
                    townCity: doc.data().townCity,
                    contacts: doc.data().contacts,
                    deliveryAddressOne: doc.data().deliveryAddressOne,
                    deliveryAddressTwo: doc.data().deliveryAddressTwo,
                    deliveryAddressThree: doc.data().deliveryAddressThree,
                    deliveryPostCode: doc.data().deliveryPostCode,
                    deliveryTownCity: doc.data().deliveryTownCity,
                    invoiceAddressOne: doc.data().invoiceAddressOne,
                    invoiceAddressTwo: doc.data().invoiceAddressTwo,
                    invoiceAddressThree: doc.data().invoiceAddressThree,
                    invoicePostCode: doc.data().invoicePostCode,
                    invoiceTownCity: doc.data().invoiceTownCity,
                    switchboard: doc.data().switchboard
                })
            });
        }).then(() => setCustomers(temp))
    }

    const getThermalScope = async () => {
        const db = firebase.firestore().collection("dropdowns").doc("thermalDesign");
        await db.get().then((doc) => {
            if (doc.exists) {
                // @ts-ignore
                setThermalScope(doc.data().values)
            } else {
                setThermalScope([])
            }
        })
    }

    const getMechanicalScope = async () => {
        const db = firebase.firestore().collection("dropdowns").doc("mechanicalDesign");
        await db.get().then((doc) => {
            if (doc.exists) {
                // @ts-ignore
                setMechanicalScope(doc.data().values)
            } else {
                setMechanicalScope([])
            }
        })
    }

    const getDraughting = async () => {
        const db = firebase.firestore().collection("dropdowns").doc("draughting");
        await db.get().then((doc) => {
            if (doc.exists) {
                // @ts-ignore
                setDraughting(doc.data().values)
            } else {
                setDraughting([])
            }
        })
    }

    const searchForPart = async () => {
        if (partSearchText.length > 0) {
            const db = firebase.firestore().collection("parts").doc(partSearchText);
            await db.get().then((doc) => {
                let temp: { partNumber?: string, description?: string, quantity?: number, tagNumber?: string } = {}
                if (doc.exists) {
                    temp.partNumber = partSearchText
                    // @ts-ignore
                    temp.description = `${doc.data().description}, ${doc.data().dimensions}, ${doc.data().material}, ${doc.data().finish}`
                } else {
                    temp.partNumber = partSearchText
                }
                // @ts-ignore
                setPartList([...partList, temp])
            })
        } else {
            toast.error(`This part number does not exist. Please create a new part in the parts inventory.`)
        }
    }

    const handlePartChange = (partNumber: string, objectKey: string, input: string) => {
        const temp = partList.map((value) => {
            if (value.partNumber === partNumber) {
                // @ts-ignore
                value[objectKey] = input
            }
            return value
        })
        setPartList(temp)
    }

    const saveEnquiryForm = async () => {
        const temp = { tnNumber: tnNumber, revision: 0, parts: partList, enquiryOwner: currentUser.currentUser, ...enquiryForm }
        const db = firebase.firestore().collection("enquiries").doc(tnNumber.toString());
        if (temp) {
            await db.set(temp)
        }
        toast.success("Enquiry created!")
        submit()
    }

    useEffect(() => {
        getTnNumber()
        getCustomers()
        getThermalScope()
        getMechanicalScope()
        getDraughting()
        getAllUsers()
        // @ts-ignore
        setEnquiryForm({ notes: [], ...enquiryForm })
    }, [])

    return (
        <Modal dialogClassName="large-dialog" show={show} onHide={() => hide()}>
            <Modal.Header closeButton>
                <Modal.Title>{`Create TN${tnNumber} enquiry`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tab.Container id="left-tabs-example" defaultActiveKey="enquiryInformation">
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column" onSelect={(eventKey) => setKey(eventKey)}>
                                <Nav.Item>
                                    <Nav.Link eventKey="enquiryInformation">Enquiry information</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="parts">Parts</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="enquiryAssessment">Enquiry assessment</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="notes">Notes</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="enquiryInformation">
                                    <Row>
                                        <Col>
                                            <div className="mb-2">
                                                <p className="form-label">Enquiry owner: *</p>
                                                <Form.Control onChange={(event) => {
                                                    // @ts-ignore
                                                    setEnquiryForm({
                                                        ...enquiryForm,
                                                        enquiryOwner: enquiryOwners.filter((val) => val.uid === event.currentTarget.value)[0]
                                                    })
                                                }} as="select">
                                                    {enquiryOwners.map((value) =>
                                                        <option value={value.uid}>{value.name}</option>
                                                    )}
                                                </Form.Control>
                                            </div>
                                            <div className="mb-2">
                                                <p className="form-label">Enquiry logged:</p>
                                                <DatePicker
                                                    dateFormat={"dd/MM/yyyy"}
                                                    placeholderText="Enter logged date..." className="form-control"
                                                    wrapperClassName="w-100"
                                                    value={moment(enquiryForm?.loggedDate).format("DD/MM/YYYY")}
                                                    onChange={(date) => {
                                                        if (date instanceof Date) {
                                                            // @ts-ignore
                                                            setEnquiryForm({
                                                                ...enquiryForm,
                                                                loggedDate: date.toDateString()
                                                            })
                                                        }
                                                    }} />
                                            </div>
                                            <div className="mb-5">
                                                <p className="form-label">Quote due: *</p>
                                                <DatePicker
                                                    dateFormat={"dd/MM/yyyy"}
                                                    placeholderText="Enter quote due date..." className="form-control"
                                                    wrapperClassName="w-100"
                                                    value={moment(enquiryForm?.quoteDueDate).format("DD/MM/YYYY")}
                                                    onChange={(date) => {
                                                        if (date instanceof Date) {
                                                            // @ts-ignore
                                                            setEnquiryForm({
                                                                ...enquiryForm,
                                                                quoteDueDate: date.toDateString()
                                                            })
                                                        }
                                                    }} />
                                            </div>

                                            <div className="mb-2">
                                                <p className="form-label">Customer enquiry reference: *</p>
                                                <Form.Control
                                                    onChange={(event) => {
                                                        // @ts-ignore
                                                        setEnquiryForm({
                                                            ...enquiryForm,
                                                            customerRef: event.currentTarget.value
                                                        })
                                                    }}
                                                    value={enquiryForm?.customerRef}
                                                    placeholder="Customer reference..."
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <p className="form-label">Budget only: *</p>
                                                <div>
                                                    <Form.Check onClick={() => {
                                                        // @ts-ignore
                                                        setEnquiryForm({ ...enquiryForm, budgetOnly: true })
                                                    }} inline name="budget-radio" label="Yes" type="radio"
                                                        id={`inline-true`} />
                                                    <Form.Check onClick={() => {
                                                        // @ts-ignore
                                                        setEnquiryForm({ ...enquiryForm, budgetOnly: false })
                                                    }} inline name="budget-radio" label="No" type="radio"
                                                        id={`inline-false`} />
                                                </div>
                                            </div>
                                            <div>
                                                <p className="form-label">Description: *</p>
                                                <Form.Control
                                                    as={"textarea"}
                                                    rows={3}
                                                    onChange={(event) => {
                                                        // @ts-ignore
                                                        setEnquiryForm({
                                                            ...enquiryForm,
                                                            description: event.currentTarget.value
                                                        })
                                                    }}
                                                    value={enquiryForm?.description}
                                                    placeholder="Description..."
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="mb-2">
                                                <p className="form-label">Customer: *</p>
                                                <Typeahead
                                                    onChange={(selected) => {
                                                        if (selected.length === 0) {
                                                            const temp = enquiryForm
                                                            // @ts-ignore
                                                            delete temp?.customer
                                                            // @ts-ignore
                                                            delete temp?.technicalContact
                                                            // @ts-ignore
                                                            delete temp?.commercialContact
                                                            setEnquiryForm(temp)
                                                            setSelectedCustomer(undefined)
                                                        } else {
                                                            // @ts-ignore
                                                            setEnquiryForm({ ...enquiryForm, customer: selected[0] })
                                                            setSelectedCustomer(selected[0])
                                                        }
                                                    }}
                                                    options={customers}
                                                    labelKey="name"
                                                    placeholder="Search for customer..."
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <p className="form-label">Technical contact:</p>
                                                <Typeahead
                                                    onChange={(selected) => {
                                                        // @ts-ignore
                                                        setEnquiryForm({ ...enquiryForm, technicalContact: selected[0] })
                                                    }}
                                                    options={selectedCustomer?.contacts || []}
                                                    labelKey="name"
                                                    placeholder="Search for technical contact..."
                                                />
                                            </div>
                                            <div className="mb-5">
                                                <p className="form-label">Commercial contact:</p>
                                                <Typeahead
                                                    onChange={(selected) => {
                                                        // @ts-ignore
                                                        setEnquiryForm({ ...enquiryForm, commercialContact: selected[0] })
                                                    }}
                                                    options={selectedCustomer?.contacts || []}
                                                    labelKey="name"
                                                    placeholder="Search for commercial contact..."
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <p className="form-label">Thermal design scope: *</p>
                                                <Form.Control onChange={(event) => {
                                                    // @ts-ignore
                                                    setEnquiryForm({
                                                        ...enquiryForm,
                                                        thermalDesignScope: event.currentTarget.value
                                                    })
                                                }} as="select">
                                                    <option>Select an option...</option>
                                                    {thermalScope.map((value) =>
                                                        <option value={value}>{value}</option>
                                                    )}
                                                </Form.Control>
                                            </div>
                                            <div className="mb-2">
                                                <p className="form-label">Mechanical design scope: *</p>
                                                <Form.Control onChange={(event) => {
                                                    // @ts-ignore
                                                    setEnquiryForm({
                                                        ...enquiryForm,
                                                        mechanicalDesignScope: event.currentTarget.value
                                                    })
                                                }} as="select">
                                                    <option>Select an option...</option>
                                                    {mechanicalScope.map((value) =>
                                                        <option value={value}>{value}</option>
                                                    )}
                                                </Form.Control>
                                            </div>
                                            <div>
                                                <p className="form-label">Draughting scope: *</p>
                                                <Form.Control onChange={(event) => {
                                                    // @ts-ignore
                                                    setEnquiryForm({
                                                        ...enquiryForm,
                                                        draughtingDesignScope: event.currentTarget.value
                                                    })
                                                }} as="select">
                                                    <option>Select an option...</option>
                                                    {draughting.map((value) =>
                                                        <option value={value}>{value}</option>
                                                    )}
                                                </Form.Control>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="mb-2">
                                                <p className="form-label">Customer specifications:</p>
                                                <Form.Control
                                                    as={"textarea"}
                                                    rows={3}
                                                    onChange={(event) => {
                                                        // @ts-ignore
                                                        setEnquiryForm({
                                                            ...enquiryForm,
                                                            customerSpecs: event.currentTarget.value
                                                        })
                                                    }}
                                                    value={enquiryForm?.customerSpecs}
                                                    placeholder="Customer specifications..."
                                                />
                                            </div>
                                            <div>
                                                <p className="form-label">Confidence:</p>
                                                <TrafficLights onChange={(colour) => {
                                                    // @ts-ignore
                                                    setEnquiryForm({ confidence: colour, ...enquiryForm })
                                                }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="parts">
                                    <p className="form-label">Enter part number:</p>
                                    <div className="d-flex flex-row align-items-center mb-3">
                                        <Form.Control value={partSearchText}
                                            onChange={(event) => setPartSearchText(event.currentTarget.value)}
                                            className="w-25" placeholder="Enter part number..." />
                                        <Button
                                            onClick={() => searchForPart()}
                                            className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                            <MdAdd size={24} color="white" />
                                        </Button>
                                    </div>
                                    {partList.length > 0 &&
                                        <Table bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Quantity</th>
                                                    <th>Part Number</th>
                                                    <th>Description</th>
                                                    <th>Tag Number</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {partList.map((value, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <FormControl
                                                                    onChange={(event) =>
                                                                        handlePartChange(value.partNumber, "quantity", event.currentTarget.value)
                                                                    }
                                                                    type="Number"
                                                                    placeholder="Quantity..."
                                                                />
                                                            </td>
                                                            <td>{value.partNumber}</td>
                                                            <td>
                                                                {value.description ?
                                                                    value.description
                                                                    :
                                                                    <FormControl
                                                                        onBlur={(event: { currentTarget: { value: string; }; }) =>
                                                                            handlePartChange(value.partNumber, "description", event.currentTarget.value)
                                                                        }
                                                                        aria-details={value.partNumber}
                                                                        placeholder="Description..."
                                                                        as="textarea"
                                                                        rows={1}
                                                                    />
                                                                }
                                                            </td>
                                                            <td>
                                                                <FormControl
                                                                    onChange={(event) =>
                                                                        handlePartChange(value.partNumber, "tagNumber", event.currentTarget.value)
                                                                    }
                                                                    aria-details={value.partNumber}
                                                                    placeholder="Tag number..."
                                                                />
                                                            </td>
                                                            <td><a onClick={() => removePart(value.partNumber)}>Remove</a></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="enquiryAssessment">
                                    <p className="mb-4 font-weight-bold">Bid/no bid assessment (answer all questions 1
                                        to 5, where 1 =
                                        'no', 3 = potentially & 5 = 'yes')</p>
                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Question</th>
                                                <th>Rating</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {assessment.map((value) => {
                                                return (
                                                    <tr>
                                                        <td>{value.id}</td>
                                                        <td>{value.text}</td>
                                                        <td>
                                                            <Form.Control
                                                                onChange={(event) => {
                                                                    if (enquiryForm?.assessment === undefined) {
                                                                        // @ts-ignore
                                                                        setEnquiryForm({
                                                                            ...enquiryForm,
                                                                            assessment: [{
                                                                                id: value.id,
                                                                                value: parseInt(event.currentTarget.value)
                                                                            }]
                                                                        })
                                                                    } else {
                                                                        const temp = enquiryForm.assessment
                                                                        const found = temp.findIndex(item => item.id === value.id)
                                                                        console.log(found)
                                                                        if (found < 0) {
                                                                            temp.push({
                                                                                id: value.id,
                                                                                value: parseInt(event.currentTarget.value)
                                                                            })
                                                                        } else {
                                                                            temp[found] = {
                                                                                id: value.id,
                                                                                value: parseInt(event.currentTarget.value)
                                                                            }
                                                                        }
                                                                        console.log(temp)
                                                                        // @ts-ignore
                                                                        setEnquiryForm({ ...enquiryForm, assessment: temp })
                                                                    }
                                                                }}
                                                                type="Number"
                                                                min={1}
                                                                max={5} />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Tab.Pane>
                                <Tab.Pane eventKey="notes">
                                    {enquiryForm?.notes && enquiryForm?.notes.map((value) => {
                                        return (
                                            <div className="mb-3">
                                                <p>{value.text}</p>
                                                <div className="d-flex flex-row align-items-center">
                                                    <p className="font-weight-bold">{`- ${value.username} ${value.date}`}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <Form.Control
                                        onChange={(event) => setNoteText(event.currentTarget.value)}
                                        as="textarea"
                                        rows={3}
                                        className="mt-4"
                                        value={noteText}
                                        placeholder="Enter a note..." />
                                    <div className="w-100 mt-2 d-flex justify-content-end">
                                        <Button className="button-green w-auto" onClick={() => {
                                            let temp: { text: string, username: string, date: string }[] = []
                                            if (enquiryForm?.notes !== undefined) {
                                                temp = enquiryForm.notes
                                            }
                                            temp.push({
                                                text: noteText,
                                                username: currentUser.currentUser.name,
                                                date: moment().format("D/M/YYYY")
                                            })
                                            // @ts-ignore
                                            setEnquiryForm({ ...enquiryForm, notes: temp })
                                            setNoteText("")
                                        }}>Add note</Button>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={key !== "notes"} onClick={() => {
                    saveEnquiryForm()
                    hide()
                }
                } className="button-green w-auto">
                    Save enquiry
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EnquiriesCreate