import React, { useEffect, useState } from 'react';
import logo from "../../Images/logo-dark.png";
import Screen from "../../Components/Screen";
import { useHistory } from "react-router";
import { Table, Form, Button } from "react-bootstrap";
import { SalesOrder } from '../Order processing/OrderProcessing';
import { Quote } from '../Enquiry processing/QuotationView';
import Footer from '../../Components/Footer';

const DeliveryNoteView = () => {
    const [preview, setPreview] = useState<boolean>(false)
    const history = useHistory();
    console.log(history.location)
    // @ts-ignore
    const passedState: { so: SalesOrder, quote: Quote } = history.location.state.state;
    console.log(passedState)

    console.log(history.location)

    if (preview) {
        return (
            <div className="purchaseOrdersCreate">
                <div className="purchaseOrdersCreate-template p-1">

                    <img className="mb-5" src={logo} />

                    <div className='d-flex flex-row justify-content-between align-items-end'>
                        <h1>Advice Note</h1>
                        <p className="mb-3"></p>
                    </div>
                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Section 1.0 - Reference Numbers & Address</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Your Purchase Order Number</th>
                                        <th>Consignor</th>
                                        <th>Consignee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{passedState.so.customerPo}</td>
                                        <td  className='noBottomBorder'>
                                            Thrco Serck Ltd
                                        </td>
                                        <td className='noBottomBorder'>
                                            {passedState.quote.enquiry.customer.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='font-weight-bold  mid-cell'>Our Advice Note Number</td>
                                        <td className='noBottomBorder'>
                                            Rother Valley Way
                                        </td>
                                        <td className='noBottomBorder'>
                                            {passedState.quote.enquiry.customer.deliveryAddressOne}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            TN{passedState.quote.enquiry.tnNumber}{`/SO${passedState.so.quote.enquiry.tnNumber}`}
                                        </td>
                                        <td className='noBottomBorder'>
                                            Sheffield
                                        </td>
                                        <td className='noBottomBorder'>
                                            {passedState.quote.enquiry.customer.deliveryAddressTwo}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='font-weight-bold'>Our Commercial Invoice Number</td>
                                        <td className='noBottomBorder'>
                                            S20 3RW
                                        </td>
                                        <td className='noBottomBorder'>
                                            {passedState.quote.enquiry.customer.deliveryTownCity}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            TN{passedState.quote.enquiry.tnNumber}{`/SO${passedState.so.quote.enquiry.tnNumber}`}
                                        </td>
                                        <td>
                                            United Kingdom
                                        </td>
                                        <td>
                                            {passedState.quote.enquiry.customer.deliveryPostCode}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Section 2.0 - Quantity, Description & Price</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th className='text-center'>Line</th>
                                        <th className='text-right'>Qty</th>
                                        <th>Description</th>
                                        <th className='text-center'>Commodity Code</th>
                                        <th className='text-right'>Packed Dimensions [Each]</th>
                                        <th className='text-right'>Gross Weight [Each]</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {passedState.so.parts.map((part, index) => {
                                        const newVals = passedState.so.DN?.newExtra?.partInfo.filter((a) => a.partNumber === part.partNumber)[0]
                                        return (
                                            <tr key={index}>
                                                <td className='text-center noBottomBorder'>2.{index + 1}</td>
                                                <td className='noBottomBorder'>{part.quantity}</td>
                                                <td className='noBottomBorder'>{part.description}</td>
                                                <td className='noBottomBorder'>
                                                    {newVals?.commodity}
                                                </td>
                                                <td className='noBottomBorder'>
                                                    {newVals?.packDim}
                                                </td>
                                                <td className='noBottomBorder'>
                                                    {newVals?.weight}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Section 3.0 - Notes & Confirmation of Receipt</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th className='text-center'>Line</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {passedState.so.DN?.newExtra?.notes.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className='small-qty text-center noBottomBorder'>3.{index + 1}</td>
                                                <td className='noBottomBorder'>{item}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Goods Received by</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <td style={{height: "8rem", width: "40%"}}>Print Name</td>
                                        <td style={{height: "8rem", width: "20%"}}>Date</td>
                                        <td style={{height: "8rem", width: "40%"}}>Signature</td>
                                    </tr>
                                </thead>
                            </Table>
                        </div>
                    </div>
                    <Footer />
                    <div className="d-flex flex-row justify-content-end w-100 PO-printing">
                        <Button
                            onClick={() => setPreview(!preview)}
                            className="button-green mt-5 w-auto">
                            Cancel preview
                        </Button>
                        <Button
                            onClick={() => window.print()}
                            className="button-green mt-5 ml-2 w-auto">
                            Download PDF
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Screen active="Delivery note">
            <div className="purchaseOrdersCreate">
                <div className="purchaseOrdersCreate-template">

                    <img className="mb-5" src={logo} />

                    <div className='d-flex flex-row justify-content-between align-items-end'>
                        <h1>Advice Note</h1>
                        <p className="mb-3"></p>
                    </div>
                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Section 1.0 - Reference Numbers & Address</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Your Purchase Order Number</th>
                                        <th>Consignor</th>
                                        <th>Consignee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{passedState.so.customerPo}</td>
                                        <td  className='noBottomBorder'>
                                            Thrco Serck Ltd
                                        </td>
                                        <td className='noBottomBorder'>
                                            {passedState.quote.enquiry.customer.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='font-weight-bold  mid-cell'>Our Advice Note Number</td>
                                        <td className='noBottomBorder'>
                                            Rother Valley Way
                                        </td>
                                        <td className='noBottomBorder'>
                                            {passedState.quote.enquiry.customer.deliveryAddressOne}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            TN{passedState.quote.enquiry.tnNumber}{`/SO${passedState.so.quote.enquiry.tnNumber}`}
                                        </td>
                                        <td className='noBottomBorder'>
                                            Sheffield
                                        </td>
                                        <td className='noBottomBorder'>
                                            {passedState.quote.enquiry.customer.deliveryAddressTwo}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='font-weight-bold'>Our Commercial Invoice Number</td>
                                        <td className='noBottomBorder'>
                                            S20 3RW
                                        </td>
                                        <td className='noBottomBorder'>
                                            {passedState.quote.enquiry.customer.deliveryTownCity}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            TN{passedState.quote.enquiry.tnNumber}{`/SO${passedState.so.quote.enquiry.tnNumber}`}
                                        </td>
                                        <td>
                                            United Kingdom
                                        </td>
                                        <td>
                                            {passedState.quote.enquiry.customer.deliveryPostCode}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Section 2.0 - Quantity, Description & Price</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th className='text-center'>Line</th>
                                        <th className='text-right'>Qty</th>
                                        <th>Description</th>
                                        <th className='text-center'>Commodity Code</th>
                                        <th className='text-right'>Packed Dimensions [Each]</th>
                                        <th className='text-right'>Gross Weight [Each]</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {passedState.so.parts.map((part, index) => {
                                        const newVals = passedState.so.DN?.newExtra?.partInfo.filter((a) => a.partNumber === part.partNumber)[0]
                                        return (
                                            <tr key={index}>
                                                <td className='text-center noBottomBorder'>2.{index + 1}</td>
                                                <td className='noBottomBorder'>{part.quantity}</td>
                                                <td className='noBottomBorder'>{part.description}</td>
                                                <td className='noBottomBorder'>
                                                    {newVals?.commodity}
                                                </td>
                                                <td className='noBottomBorder'>
                                                    {newVals?.packDim}
                                                </td>
                                                <td className='noBottomBorder'>
                                                    {newVals?.weight}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Section 3.0 - Notes & Confirmation of Receipt</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th className='text-center'>Line</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {passedState.so.DN?.newExtra?.notes.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className='small-qty text-center noBottomBorder'>3.{index + 1}</td>
                                                <td className='noBottomBorder'>{item}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className='doc-section'>
                        <div className='doc-section-title'>
                            <h4>Goods Received by</h4>
                        </div>
                        <div className='doc-section-content'>
                            <Table>
                                <thead>
                                    <tr>
                                        <td style={{height: "8rem", width: "40%"}}>Print Name</td>
                                        <td style={{height: "8rem", width: "20%"}}>Date</td>
                                        <td style={{height: "8rem", width: "40%"}}>Signature</td>
                                    </tr>
                                </thead>
                            </Table>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end w-100">
                        <Button
                            onClick={() => setPreview(!preview)}
                            className="button-green mt-5 w-auto">
                            View preview & print
                        </Button>
                    </div>
                </div>
            </div>
        </Screen>
    )
}

export default DeliveryNoteView