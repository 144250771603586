import React, {useState} from 'react';

interface TrafficLightsProps {
    onChange: (colour: string) => void
    initialColour?: string;
    noChange?: boolean;
}

const TrafficLights = ({onChange, initialColour, noChange = false}: TrafficLightsProps) => {
    const [colour, setColour] = useState<string>(initialColour || "")

    const handleChange = (text: string) => {
        if (!noChange) {
            if (colour === text) {
                setColour("")
                onChange("")
            } else {
                setColour(text)
                onChange(text)
            }
        }
    }

    return (
        <div className="trafficLights">
            <a
                className={`
                ${noChange && 'trafficLights-box--red--no-hover'}
                trafficLights-box
                trafficLights-box--red
                ${colour === "Red" && "trafficLights-box--red--active"}
                `}
                onClick={() => handleChange("Red")}
            >
            </a>
            <a
                className={`
                ${noChange && 'trafficLights-box--amber--no-hover'}
                trafficLights-box
                trafficLights-box--amber
                ${colour === "Amber" && "trafficLights-box--amber--active"}
                `}
                onClick={() => handleChange("Amber")}
            >
            </a>
            <a
                className={`
                ${noChange && 'trafficLights-box--green--no-hover'}
                trafficLights-box 
                trafficLights-box--green
                ${colour === "Green" && "trafficLights-box--green--active"}
                `}
                onClick={() => handleChange("Green")}
            >
            </a>
        </div>
    )
}

export default TrafficLights