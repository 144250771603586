import React, { useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import logo from "../../Images/logo-dark.png";
import firebase from "firebase";
import { useParams, useHistory } from 'react-router-dom';
import moment from "moment";
import { toast } from "react-hot-toast";
import { Form, Table, Button } from "react-bootstrap";
import { SalesOrder } from "./OrderProcessing";
import DatePicker from "react-datepicker";

export interface OrderAcknowledgement {
    id: string;
    salesOrder: SalesOrder;
    scope: string;
    warranty: string;
    deliveryDate: string;
    delivery: number;
    totalPrice: number;
    dateRaised: string;
    newExtra?: {
        deliveryText: string,
        guarantee: string,
        thercoContact: string,
        cusContact: string
    }
}

const OrderAcknowledgement = () => {
    const [salesOrder, setSalesOrder] = useState<SalesOrder>()
    const [scope, setScope] = useState<string>("The items described in section 1.0 shall be manufactured to proprietary standards and specifications. Unless agreed otherwise, preparation for shipment shall include light oiling of all self-colour surfaces, light ingress protection of all open nozzles and (where relevant) shrink-wrapping before securing to a pallet for domestic deliveries, or into a timber crate for export deliveries.")
    const [warranty, setWarranty] = useState<string>("THERCO-SERCK warrants that (subject to the other provisions of its terms and conditions of sale, see section 5.0) upon delivery, and for a period of 18 months from the date of delivery, or 12 months from the date of installation (whichever expires first) the goods described above shall be of satisfactory quality within the meaning of the sale of goods act 1979.")
    const [deliveryDate, setDeliveryDate] = useState(moment().format("DD/MM/YYYY"))
    const [delivery, setDelivery] = useState<number>(0)

    //NEW
    const [deliveryText, setDeliveryText] = useState<string>("Based on material availability and shop load at the time of acknowledging this order, we estimate a lead time of 18 working weeks.");
    const [guarantee, setGuarantee] = useState<string>("This equipment shall be guaranteed against faulty design, material and workmanship, for a period of 12 months from commissioning/start up or 18 months from delivery, whichever the shorter. This relies on the equipment having been maintained and stored in accordance with THERCO-SERCKs recommendations in the interim period. Any signs of defects must be reported immediately and an opportunity to inspect the equipment granted without delay. No guarantee against failures resulting from erosion, corrosion or fouling of any nature is implied nor offered.")
    const [thercoContact, setThercoContact] = useState<string>("")
    const [cusContact, setCusContact] = useState<string>("")
    const history = useHistory();
    // @ts-ignore
    let { id } = useParams();

    const getSalesOrder = async () => {
        const query = firebase.firestore().collection('salesOrders').doc(`SO${id}`);
        await query.get().then((doc) => {
            // @ts-ignore
            setSalesOrder(doc.data())
        })
    }

    useEffect(() => {
        getSalesOrder()
    }, [])

    let total = 0

    if (salesOrder) {
        salesOrder.parts.map((value, index) =>
            total = total + (salesOrder.quote.itemPrices[index].price * value.quantity) + salesOrder.quote.deliveryValue.valueOf()
        );
        if (salesOrder.quote.newExtra && salesOrder.quote?.newExtra?.manualLinePrices.length > 0) {
            salesOrder.quote?.newExtra?.manualLinePrices.map((line) => {
                total += (line.price * line.qty)
            })
        }
    }

    const saveOrderAcknowledgement = async () => {
        // @ts-ignore
        const temp: OrderAcknowledgement = { newExtra: {cusContact, deliveryText, guarantee, thercoContact}, id, salesOrder, scope, warranty, deliveryDate, delivery, totalPrice: total, dateRaised: moment().format('DD/MM/YYYY') };
        const db = firebase.firestore().collection("salesOrders").doc(`SO${id}`);
        if (temp) {
            await db.update({ OA: temp }).then(() => {
                toast.success(`Order acknowledgement saved successfully`)
                history.push(`/order-processing/order-acknowledgement/view/${id}`)
            })
        }
    }

    return (
        <Screen active="Order acknowledgement">
            <div className="bg-white p-5">
                <img className="mb-5" src={logo} />
                <div className='d-flex flex-row justify-content-between align-items-end'>
                    <h1>Order Acknowledgement</h1>
                    <p className="mb-3">{moment().format('DD/MM/YYYY')}</p>
                </div>
                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 1.0 - Reference Numbers & Address</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                <th>Your Purchase Order Number</th>
                                <th>Delivery Address</th>
                                <th>Invoice Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{salesOrder?.customerPo}</td>
                                    <td  className='noBottomBorder'>
                                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressOne}</p>
                                    </td>
                                    <td  className='noBottomBorder'>
                                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressOne}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='font-weight-bold  mid-cell'>Our Sales Order Number</td>
                                    <td className='noBottomBorder'><p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressTwo}</p></td>
                                    <td className='noBottomBorder'><p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressTwo}</p></td>
                                </tr>
                                <tr>
                                    <td>SO{salesOrder?.quote.tn}</td>
                                    <td className='noBottomBorder'><p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryTownCity}</p></td>
                                    <td className='noBottomBorder'><p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceTownCity}</p></td>
                                </tr>
                                <tr>
                                    <td className='font-weight-bold'>Our Quotation Number</td>
                                    <td><p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryPostCode}</p></td>
                                    <td><p>{salesOrder && salesOrder.quote.enquiry.customer.invoicePostCode}</p></td>
                                </tr>
                                <tr>
                                    <td>TN{salesOrder?.quote.tn}</td>
                                    <td>
                                    <Form.Control
                                            onChange={(e) => setThercoContact(e.currentTarget.value)}
                                            placeholder='Therco-Serck Contact...'
                                            value={thercoContact}
                                        />
                                    </td>
                                    <td>
                                    <Form.Control
                                            onChange={(e) => setCusContact(e.currentTarget.value)}
                                            placeholder={`${salesOrder && salesOrder.quote.enquiry.customer.name} contact...`}
                                            value={cusContact}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 2.0 - Quantity, Description & Price</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Line</th>
                                    <th className='text-right'>Qty</th>
                                    <th>Description</th>
                                    <th>Part Number</th>
                                    <th className='text-right'>Unit Price</th>
                                    <th className='text-right'>Line Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {salesOrder && salesOrder.quote.enquiry.parts.map((value, index) => {
                                    const found = salesOrder.quote.itemPrices.find((item) => item.index === index)
                                    let quantity = 0;
                                    if (typeof value.quantity === 'string') {
                                        quantity = parseInt(value.quantity)
                                    }
                                    return (
                                        <tr>
                                            <td className='text-center noBottomBorder'>2.{index + 1}</td>
                                            <td className="text-right noBottomBorder">{value.quantity}</td>
                                            <td className='noBottomBorder'>{value.description}, {value.tagNumber}</td>
                                            <td className='noBottomBorder'>{value.partNumber}</td>
                                            <td className="text-right noBottomBorder">
                                                £{found?.price.toFixed(2)}
                                            </td>
                                            <td className="text-right noBottomBorder">
                                                £{found && (quantity * found.price).toFixed(2)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td className='text-center noBottomBorder'>2.{salesOrder && salesOrder.quote.enquiry.parts.length + 1}</td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                </tr>
                                {salesOrder && salesOrder.quote.newExtra && salesOrder.quote.newExtra.manualLinePrices.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='text-center noBottomBorder'>2.{salesOrder.quote.enquiry && (salesOrder.quote.enquiry.parts.length + 1 + index + 1)}</td>
                                            <td className='text-right noBottomBorder'>{item.qty}</td>
                                            <td className='noBottomBorder'>{item.desc}</td>
                                            <td className='noBottomBorder'></td>
                                            <td className='text-right noBottomBorder'>£{item.price.toFixed(2)}</td>
                                            <td className='text-right noBottomBorder'>£{(item.price * item.qty).toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td className='borderTop'></td>
                                    <td className='borderTop'></td>
                                    <td className='borderTop'>Total (excluding VAT, or Taxes of any other nature)</td>
                                    <td className='no-border borderTop'></td>
                                    <td className='no-border borderTop'></td>
                                    <td className='text-right borderTop'>£{total.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 3.0 - Scope of Supply</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Line</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {salesOrder && salesOrder.quote.scope && salesOrder.quote.scope.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='text-center noBottomBorder'>3.{index + 1}</td>
                                            <td className='noBottomBorder'>{item}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 4.0 - Delivery</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Form.Control as="textarea" rows={2} onChange={(event) => setDeliveryText(event.target.value)}
                            value={deliveryText} />
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 5.0 - Guarantee</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Form.Control as="textarea" rows={4} onChange={(event) => setGuarantee(event.target.value)}
                            value={guarantee} />
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 6.0 - Terms & Conditions</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <tbody>
                                {salesOrder && salesOrder.quote.newExtra && salesOrder.quote.newExtra.terms.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='small-qty text-center noBottomBorder'>6.{index + 1}</td>
                                            <td className='noBottomBorder'>{item}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>


                <div className="d-flex justify-content-end w-100">
                    <Button
                        onClick={() => saveOrderAcknowledgement()}
                        className="button-green mt-5 w-auto">
                        Create order acknowledgement
                    </Button>
                </div>
            </div>
        </Screen>
    )
}

export default OrderAcknowledgement