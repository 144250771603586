const Footer = () => {
    return (
        <div className="flex-column footer">
            <p><span className="footer__green">Therco-Serck Ltd</span> Rother Valley Way, Holbrook, Sheffield, S20 3RW, United Kingdom</p>
            <p><span className="footer__green">T:</span> +44 (0)114 251 0339<span className="footer__green"> F:</span> +44 (0)844 854 8222<span className="footer__green"> E:</span> info@ThercoSerckHTX.com<span className="footer__green"> W:</span> ThercoSerckHTX.com</p>
            <p className="footer__small">Registered No. 10972519 (England & Wales), VAT No. 279 485 344, Registered Office: 68 Queen Street, Sheffield, S1 1WR, United Kingdom</p>
        </div>
    )
}

export default Footer