import React, { useState, useCallback } from 'react';
import Screen from "../../Components/Screen";
import { Button, Card, Table } from "react-bootstrap";
import Papa from 'papaparse'; // CSV parsing library
import { useDropzone } from 'react-dropzone'; // For drag and drop functionality
import firebase from "firebase";
import { FaUpload } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { MdFileUpload } from 'react-icons/md';

// Define the BOMItem type
interface BOMItem {
    ITEM: string;
    DESCRIPTION: string;
    "DIMENSION / SPECIFICATION": string;
    MATERIAL: string;
    "FINISH (other than self colour)": string;
    "Q.A. (refer to BOM key p.2)": string;
    "PART NUMBER": string;
    QTY: string;
    "PRODUCT CATEGORY": string;
    "MATERIAL GROUP": string;
    "PURCH QTY": string;
    "SUPPLIER": string;
    PO: string;
    "D. DATE": string;
    MTC: string;
    "PAGE NO": string;
    "HEAT NO": string;
}

// Define the Part type
export interface Part {
    id: string;
    description: string;
    certification?: string;
    dimensions: string;
    material: string;
    finish: string;
    qaRequirement: string[];
    nominalCodePurchasing: string;
    nominalCodeSales: string;
    productType: string;
    materialGroup: string;
    notes: { note: string, date: string }[];
    price: string;
    stock: number;
    stockLocation?: string;
    supplierName: string;
}

const BomImport = () => {
    const [csvData, setCsvData] = useState<BOMItem[]>([]); // Use BOMItem type for csvData
    const [complete, setComplete] = useState<boolean>(false)
    const db = firebase.firestore();

    // Handle file upload via drag and drop
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true, // Skip empty lines
                complete: (result) => {
                    const cleanedData: BOMItem[] = result.data.map((item: any) => {
                        const cleanedItem: any = {};
                        for (const key in item) {
                            const cleanedKey = key.replace(/\n/g, ' ').trim();
                            cleanedItem[cleanedKey] = item[key]?.trim(); // Remove any surrounding spaces
                        }
                        return cleanedItem as BOMItem;
                    });
                    console.log('Parsed and Cleaned CSV:', cleanedData);
                    setCsvData(cleanedData); // Store cleaned CSV data
                },
                error: (error) => {
                    console.error('Error parsing CSV:', error);
                }
            });
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv'] // Accept only CSV files
        }
    });
    // Convert BOMItem to Part array
    const convertToParts = (data: BOMItem[]): Part[] => {
        return data
            .filter((item) => item["PART NUMBER"]) // Filter out rows with no part number
            .map((item) => ({
                id: item["PART NUMBER"],
                description: item.DESCRIPTION,
                certification: item.MTC || '',
                dimensions: item["DIMENSION / SPECIFICATION"],
                material: item.MATERIAL,
                finish: item["FINISH (other than self colour)"] || 'None',
                qaRequirement: item["Q.A. (refer to BOM key p.2)"].split(',').map((qa) => qa.trim()), // Split QA requirements by comma
                nominalCodePurchasing: '', // You can set this dynamically if needed
                nominalCodeSales: '', // You can set this dynamically if needed
                productType: item["PRODUCT CATEGORY"] || 'Unknown',
                materialGroup: item["MATERIAL GROUP"] || 'Unknown',
                notes: [],
                price: '', // Placeholder for price if needed
                stock: 0, // Convert stock quantity to number
                stockLocation: '', // Placeholder for stock location
                supplierName: item["SUPPLIER"] || 'Unknown',
            }));
    };

    // Simulate checking if part exists in the collection and adding it if it doesn't
    const importParts = async () => {
        const parts: Part[] = convertToParts(csvData);
        console.log(parts)
        for (const part of parts) {
            const partExists = await checkIfPartExists(part.id);
            if (!partExists) {
                await addPartToCollection(part);
            } else {
                console.log(`Part with ID ${part.id} already exists.`);
            }
        }
        if (csvData.length > 0) {
            createBillPartsLink(csvData); // Pass csvData (BOMItem[]) instead of parts (Part[])
        }
        setComplete(true)
    };

    const checkIfPartExists = async (partId: string): Promise<boolean> => {
        try {
            const partDoc = await db.collection('parts').doc(partId).get();
            if (partDoc.exists) {
                console.log(`Part with ID ${partId} already exists.`);
                return true;
            }
            console.log(`Part with ID ${partId} does not exist.`);
            return false;
        } catch (error) {
            console.error("Error checking part existence: ", error);
            return false; // Return false in case of an error
        }
    };

    // Function to add a part to the "parts" collection
    const addPartToCollection = async (part: Part) => {
        try {
            await db.collection('parts').doc(part.id).set(part);
            console.log('Part added to collection:', part);
        } catch (error) {
            console.error("Error adding part to collection: ", error);
        }
    };

    // Function to add to "billPartsLink" collection
    const addToBillPartsLink = async (item0PartNumber: string, subPartsData: { partNumber: string, partQuantity: number }[]) => {
        try {
            const docRef = db.collection('billPartsLink').doc(item0PartNumber);

            const data = {
                subParts: subPartsData
            };

            await docRef.set(data);
            console.log(`Document with ID ${item0PartNumber} added to "billPartsLink" with subParts:`, subPartsData);
        } catch (error) {
            console.error("Error adding to billPartsLink collection: ", error);
        }
    };

    // Create the billPartsLink with subParts array
    const createBillPartsLink = (parts: BOMItem[]) => {
        if (parts.length === 0) {
            console.error("No parts available to create a billPartsLink.");
            return;
        }

        const item0PartNumber = parts[0]["PART NUMBER"]; // Use ITEM 0's part number as the doc ID
        const subParts = parts.slice(1).map((item) => ({
            partNumber: item["PART NUMBER"],
            partQuantity: parseInt(item.QTY, 10) || 0,
        }));

        addToBillPartsLink(item0PartNumber, subParts);
    };

    const history = useHistory()

    return (
        <Screen active="bomImport">
            <Card>
                <Card.Header
                    className="card-header--sticky purchaseOrders-header d-flex justify-content-between align-items-center">

                    <p className='font-weight-bold'><MdFileUpload size={20}/> Upload BOM</p>
                </Card.Header>
                <Card.Body>
                    {complete ?
                        <div style={{ padding: '20px' }} className='d-flex justify-content-center flex-column w-100 align-items-center'>
                            <h2 className='width-fit'>Bom Import Completed</h2>
                            <div className='d-flex justify-content-center'>
                                <Button className='button-green width-fit' onClick={() => {
                                    history.push("/parts-inventory")
                                }} style={{ marginTop: '20px', marginRight: '1rem' }}>
                                    Go Back
                                </Button>
                                <Button className='button-green width-fit' onClick={() => { 
                                    setCsvData([])
                                    setComplete(false)
                                }} style={{ marginTop: '20px' }}>
                                    Import Another
                                </Button>
                            </div>
                        </div>
                        :
                        <div style={{ padding: '20px' }}>
                            {csvData.length < 1 &&
                                <>
                                    <h3>Upload BOM CSV</h3>

                                    {/* Drag and Drop Area */}
                                    <div {...getRootProps()} style={{
                                        border: '2px dashed green',
                                        borderRadius: '5px',
                                        padding: '20px',
                                        textAlign: 'center',
                                        backgroundColor: isDragActive ? '#f0f8ff' : 'transparent',
                                        cursor: 'pointer',
                                    }}>
                                        <input {...getInputProps()} />

                                        <>
                                            <FaUpload size={28} color='grey' />
                                            <p>Drag 'n' drop a CSV file here, or click to select one</p>
                                        </>
                                    </div>
                                </>
                            }

                            {/* Display CSV data if available */}
                            {csvData.length > 0 && (
                                <div style={{ marginTop: '20px' }}>
                                    <h4>Uploaded Data</h4>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                {Object.keys(csvData[0]).map((header, index) => (
                                                    <th key={index}>{header}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {csvData.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {Object.values(row).map((value, cellIndex) => (
                                                        <td key={cellIndex}>{value || '-'}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                    {/* Import Button */}
                                    <div className='d-flex justify-content-end'>
                                        <Button className='button-green width-fit' onClick={importParts} style={{ marginTop: '20px' }}>
                                            Import BOM
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </Card.Body>
            </Card>
        </Screen>
    );
};

export default BomImport;
