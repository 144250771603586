import React, {useContext, useEffect, useState} from 'react';
import Screen from "../../Components/Screen";
import logo from "../../Images/logo-dark.png"
import {Link, useHistory, useParams} from "react-router-dom";
import {Typeahead} from 'react-bootstrap-typeahead';
import {Button, Card, Form, Modal, Table} from 'react-bootstrap';
import firebase from "firebase";
import {PurchaseOrder} from "./PurchaseOrdersCreate";
import {globalUser, Notification, User} from "../../App";
import searchIcon from "../../Images/icons8-search-96 (1).png";
import {Part} from "../Parts";
import DatePicker from "react-datepicker";
import moment from "moment";
import {Supplier, SupplierContact} from "../ControlPanel";

const PurchaseOrdersEdit = () => {
    const history = useHistory();

    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>()

    const [suppliers, setSuppliers] = useState<Supplier[]>([])
    const [supplierContacts, setSuppliersContacts] = useState<SupplierContact[]>([])
    const [extraInfoText, setExtraInfoText] = useState<string>("")
    const [show, setShow] = useState(false);
    const [addPartShow, setAddPartShow] = useState(false);
    const [approvers, setApprovers] = useState<User[]>([]);
    const currentUser = useContext(globalUser)

    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | undefined>()
    const [selectedSupplierContact, setSelectedSupplierContact] = useState<SupplierContact | undefined>()
    const [refNum, setRefNum] = useState<string>("")
    const [terms, setTerms] = useState<string>("Unless agreed otherwise, THERCO-SERCK Terms and Conditions of Purchase (available on request) apply to this order. Payment 30 days EOM.")
    const [extraInfo, setExtraInfo] = useState<string[]>([])
    const [selectedApprover, setSelectedApprover] = useState<string>("")
    const [raisedBy, setRaisedBy] = useState<User>()
    const [updatedParts, setUpdatedParts] = useState<{quantity: number, dueDate: string, part: Part, price: number}[]>()

    const [newPartSearch, setNewPartSearch] = useState<Part>()
    const [newPartSearchText, setNewPartSearchText] = useState<string>("")
    const [newPartQuantity, setNewPartQuantity] = useState<number>(0)
    const [newPartDueDate, setNewPartDueDate] = useState<Date>()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAddPartClose = () => setAddPartShow(false);
    const handleAddPartShow = () => setAddPartShow(true);

    // @ts-ignore
    let {id} = useParams();

    const getPurchaseOrder = async () => {
        const query = firebase.firestore().collection('purchaseOrders').doc(id);
        await query.get().then((doc) => {
            // @ts-ignore
            setPurchaseOrder(doc.data())
        })
    }

    const getApprovers = async () => {
        const db = firebase.firestore().collection("users");
        let temp: User[] = []
        await db.where("permission", "==", "Level 1").get().then((snapshot) => {
            snapshot.forEach((doc) => {
                if (doc.exists)
                    if (doc.id !== currentUser.currentUser.uid) {
                        temp.push({
                            role: doc.data().role,
                            permission: doc.data().permission,
                            uid: doc.id,
                            email: doc.data().email,
                            name: doc.data().name
                        })
                    }
            })
        }).then(() => setApprovers(temp)).then(() => setSelectedApprover(temp[0].uid))
    }

    const getSuppliers = async () => {
        const db = firebase.firestore().collection("suppliers");
        let temp: Supplier[] = []
        await db.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                temp.push({
                    id: doc.id,
                    addressOne: doc.data().addressOne,
                    addressTwo: doc.data().addressTwo,
                    addressThree: doc.data().addressThree,
                    name: doc.data().name,
                    postCode: doc.data().postCode,
                    townCity: doc.data().townCity,
                    website: doc.data().website
                })
            });
        }).then(() => setSuppliers(temp))
    }

    const getSupplierContacts = async () => {
        const db = firebase.firestore().collection("suppliers");
        let temp: SupplierContact[] = []
        db.doc(selectedSupplier?.id).get()
            .then((doc) => doc.ref.collection("contacts")
                .get().then((subDoc) => {
                    subDoc.forEach((value) => {
                        temp.push({
                            id: value.id,
                            // @ts-ignore
                            name: value.data().name,
                            // @ts-ignore
                            email: value.data().email,
                            // @ts-ignore
                            position: value.data().position,
                            // @ts-ignore
                            directPhone: value.data().directPhone,
                            // @ts-ignore
                            mobilePhone: value.data().mobilePhone,
                        })
                    })
                })).then(() => setSuppliersContacts(temp))
    }

    const getRaisedBy = async (id: string) => {
        const query = firebase.firestore().collection('users').doc(id);
        await query.get().then((doc) => {
            // @ts-ignore
            setRaisedBy({
                ...doc.data(),
                uid: doc.id
            })
        })
    }

    const addUnapprovedPo = async () => {
        const db = firebase.firestore().collection("purchaseOrders");
        if (purchaseOrder && updatedParts) {
            let tempPurchaseOrder: PurchaseOrder = {
                id: purchaseOrder.id,
                approved: false,
                approverId: selectedApprover,
                dateRaised: purchaseOrder.dateRaised,
                raisedById: currentUser.currentUser.uid,
                ref: refNum,
                scope: extraInfo,
                supplier: selectedSupplier,
                supplierContact: selectedSupplierContact,
                terms: terms,
                parts: updatedParts
            }
            await db.doc(tempPurchaseOrder.id.toString()).set(tempPurchaseOrder)
                .then(async () => {
                    const newNotification: Notification = {
                        type: "PO Approval",
                        text: "You've been assigned to approve a purchase order",
                        link: `/purchase-orders/view/${purchaseOrder.id}`,
                        dateTime: new Date().toDateString(),
                        viewed: false,
                    }
                    const notifyQuery = firebase.firestore().collection("notifications").doc(selectedApprover)
                    await notifyQuery.get().then((doc) => {
                        // @ts-ignore
                        firebase.firestore().collection("notifications").doc(selectedApprover).set({notifications: [newNotification, ...doc.data().notifications]})
                    })
                })
                .then(() => history.push(`/purchase-orders/view/${purchaseOrder.id.toString()}`))

        }
    }

    const findNewPart = async () => {
        const query = firebase.firestore().collection('parts').doc(newPartSearchText);
        // @ts-ignore
        await query.get().then((doc) => doc.exists && setNewPartSearch(doc.data()))
    }

    const updateWithNewPart = () => {
        if (purchaseOrder) {
            const newParts = [...purchaseOrder?.parts, {part: {...newPartSearch, id: newPartSearchText}, dueDate: newPartDueDate?.toDateString(), quantity: newPartQuantity, price: 0}]
            // @ts-ignore
            setUpdatedParts(newParts)
        }
    }

    const getTotalPrice = () => {
        let priceArray: number[] = [];
        purchaseOrder?.parts.map((part) => {
            priceArray.push(part.quantity * parseInt(part.part.price))
        })
        return priceArray.reduce((a, b) => a + b, 0)
    }

    useEffect(() => {
        getPurchaseOrder()
        getSuppliers()
        getSupplierContacts()
    }, [])

    useEffect(() => {
        if (purchaseOrder) {
            setSelectedSupplier(purchaseOrder.supplier)
            setSelectedSupplierContact(purchaseOrder.supplierContact)
            setRefNum(purchaseOrder.ref)
            setExtraInfo(purchaseOrder.scope)
            setTerms(purchaseOrder.terms)
            setUpdatedParts(purchaseOrder.parts)
            getRaisedBy(purchaseOrder.raisedById)
        }
    }, [purchaseOrder])

    console.log(updatedParts)

    return (
        <Screen active="Purchase orders create">
            <div className="purchaseOrdersCreate">
                <div className="purchaseOrdersCreate-template">
                    <img className="mb-5" src={logo}/>
                    <h2 className="mb-5 purchaseOrdersCreate-template__green-text">{`PURCHASE ORDER NO. ${purchaseOrder?.id}`}</h2>
                    <h4 className="purchaseOrdersCreate-template__green-text">SUPPLIER</h4>
                    {selectedSupplier ?
                        <div className="d-flex flex-column">
                            <p>{selectedSupplier.name}</p>
                            <p>{selectedSupplier.addressOne}</p>
                            <p>{selectedSupplier.townCity}</p>
                            <p>{selectedSupplier.postCode}</p>
                            <a onClick={() => {
                                setSelectedSupplier(undefined)
                                setSelectedSupplierContact(undefined)
                            }} href="#" className="mt-2">Edit supplier</a>
                        </div>
                        :
                        <div className="purchaseOrdersCreate-template-supplier">
                            <Typeahead
                                onChange={(selected) => {
                                    setSelectedSupplier(selected[0])
                                }}
                                options={suppliers}
                                labelKey="name"
                                placeholder="Search for supplier..."
                            />
                        </div>
                    }
                    <div className="d-flex flex-row justify-content-between mt-4 mb-5">
                        <div className="d-flex align-items-center flex-row">
                            <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">FAO:</h5>
                            {selectedSupplierContact ?
                                <p>{`${selectedSupplierContact.name}`}</p>
                                :
                                <div className="purchaseOrdersCreate-template__textbox">
                                    {selectedSupplier ?
                                        <Typeahead
                                            onChange={(selected) => {
                                                setSelectedSupplierContact(selected[0])
                                            }}
                                            options={supplierContacts}
                                            labelKey={(option) => `${option.name}`}
                                        />
                                        :
                                        <p>Please select a supplier</p>
                                    }
                                </div>
                            }
                        </div>
                        <div className="d-flex align-items-center flex-row">
                            <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">REF:</h5>
                            <Form.Control value={refNum} onChange={(event) => setRefNum(event.currentTarget.value)}
                                          className="purchaseOrdersCreate-template__textbox" type="text"
                                          placeholder="Reference number..."/>
                        </div>
                        <div className="d-flex align-items-center flex-row">
                            <h5 className="mb-0 mr-2 purchaseOrdersCreate-template__green-text">DATE:</h5>
                            <p>{purchaseOrder?.dateRaised}</p>
                        </div>
                    </div>
                    <Card className="mb-4">
                        <Card.Header>
                            <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 1.0 – QUANTITY,
                                DESCRIPTION & PRICE</h4>
                        </Card.Header>
                        <Card.Body>
                            <Table  bordered>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Part ID</th>
                                    <th>Description, dimension, material & finish</th>
                                    <th>Quantity</th>
                                    <th>Due date</th>
                                    <th>Price each</th>
                                    <th>Price total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {updatedParts && updatedParts.map((part, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{part.part.id}</td>
                                            <td>{part.part.description}, {part.part.dimensions}, {part.part.material}, {part.part.finish}</td>
                                            <td>
                                                {part.quantity}
                                            </td>
                                            <td style={{maxWidth: "8rem"}}>
                                                {moment(new Date(part.dueDate)).format("DD/MM/YYYY")}
                                            </td>
                                            <td>£{parseInt(part.part.price).toFixed(2)}</td>
                                            <td>£{(parseInt(part.part.price) * part.quantity).toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td className="font-weight-bold text-right" colSpan={6}>Total GBP (Excluding VAT)
                                    </td>
                                    <td>£{getTotalPrice().toFixed(2)}</td>
                                </tr>
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-end mt-3">
                                <Button onClick={() => handleAddPartShow()} className="button-green w-auto">Add
                                    part</Button>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className="mb-4">
                        <Card.Header>
                            <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 2.0 – SCOPE OF
                                SUPPLY</h4>
                        </Card.Header>
                        <Card.Body>
                            {extraInfo.map((value, index) => {
                                return (
                                    <p className="mb-1"><span
                                        className="font-weight-bold purchaseOrdersCreate-template__green-text">{`2.${index + 1} `}</span>{value}
                                    </p>
                                )
                            })}
                            <Form.Control
                                onChange={
                                    (event) => setExtraInfoText(event.currentTarget.value)
                                }
                                className="mt-4"
                                value={extraInfoText} as="textarea" rows={4}
                                placeholder="Enter extra information..."
                            />
                            <div className="d-flex justify-content-end">
                                <Button
                                    onClick={() => {
                                        const temp = extraInfo;
                                        temp.push(extraInfoText)
                                        setExtraInfo(temp)
                                        setExtraInfoText("")
                                    }}
                                    className="button-green purchaseOrdersCreate-template__button mt-3">
                                    Add
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className="mb-5">
                        <Card.Header>
                            <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 3.0 – TERMS,
                                CONDITIONS & PAYMENT</h4>
                        </Card.Header>
                        <Card.Body>
                            <Form.Control
                                onChange={
                                    (event) => setTerms(event.currentTarget.value)
                                }
                                className="mt-4"
                                value={terms} as="textarea" rows={4}
                                placeholder="Enter extra information..."
                            />
                        </Card.Body>
                    </Card>
                    <div className="purchaseOrdersCreate-template-signatures">
                        <div className="d-flex flex-column">
                            <p>PO raised by</p>
                            <h4 className="mb-0">{raisedBy?.name}</h4>
                            <p className="mb-2">{raisedBy?.role}</p>
                            <p>For & on behalf of Therco - Serck Ltd</p>
                        </div>
                        <div className="d-flex flex-column">
                            <p>PO authorised by</p>
                            <h4 className="mb-0">Not applied</h4>
                            <p className="mb-2">This PO needs approval</p>
                            <p>For & on behalf of Therco - Serck Ltd</p>
                            <Button
                                onClick={() => {
                                    getApprovers()
                                    handleShow()
                                }}
                                className="button-green mt-5">
                                Send for approval
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select an approver</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control onChange={(event) => setSelectedApprover(event.currentTarget.value)} as="select">
                        {approvers.map((value, index) => <option value={value.uid} key={index}>{value.name}</option>)}
                    </Form.Control>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        onClick={() => addUnapprovedPo()}
                        className="button-green w-auto">
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={addPartShow} onHide={handleAddPartClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Search for part by ID</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className={`d-flex w-100 flex-row align-items-center mb-3 ${newPartSearch && 'border-bottom pb-2'}`}>
                        <Form.Control value={newPartSearchText}
                                      onChange={(event) => setNewPartSearchText(event.currentTarget.value)}
                                      placeholder="Enter part ID..."/>
                        <Button
                            onClick={() => findNewPart()}
                            className="button-green button-green--search d-flex align-items-center justify-content-center w-auto ml-2">
                            <img src={searchIcon}/>
                        </Button>
                    </div>
                    {newPartSearch &&
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-column mb-3">
                            <p className="font-weight-bold">Part description:</p>
                            <p>{newPartSearch.description}</p>
                        </div>
                        <div className="d-flex flex-column mb-3">
                            <p className="font-weight-bold">Quantity:</p>
                            <Form.Control
                                min={0}
                                value={newPartQuantity}
                                          onChange={(event) => setNewPartQuantity(parseInt(event.currentTarget.value))}
                                          type="number"/>
                        </div>
                        <div className="d-flex flex-column mb-3">
                            <p className="font-weight-bold">Due date:</p>
                            <DatePicker
                                dateFormat={"dd/MM/yyyy"}
                                placeholderText="Enter due date..." className="form-control"
                                wrapperClassName="purchaseOrdersCreate-template__date"
                                value={newPartDueDate && newPartDueDate.toDateString()}
                                onChange={(date) => {
                                    if (date instanceof Date) {
                                        setNewPartDueDate(date)
                                    }
                                }}/>
                        </div>
                    </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleAddPartClose}>
                        Close
                    </Button>
                    <Button
                        onClick={() => {
                            updateWithNewPart()
                            handleAddPartClose()
                            setNewPartSearch(undefined)
                            setNewPartSearchText("")
                            setNewPartQuantity(0)
                            setNewPartDueDate(undefined)
                        }}
                        disabled={(!newPartDueDate || !newPartQuantity || !newPartSearch)}
                        className="button-green w-auto">
                        Add part
                    </Button>
                </Modal.Footer>
            </Modal>
        </Screen>
    )
}

export default PurchaseOrdersEdit