import React, { useState } from 'react';
import Screen from "../../Components/Screen";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const Reports = () => {
    const [template, setTemplate] = useState<string>("total-stock-list");
    const [year, setYear] = useState<string>("2022");
    const [so, setSo] = useState<string>("1");
    const [month, setMonth] = useState<string>("January");

    return (
        <Screen active="Reports">
            <div className="d-flex justify-content-center align-items-center h-100 w-100 flex-column">
                <div style={{ borderRadius: "15px" }}
                    className="p-5 bg-white d-flex justify-content-center align-items-center h-auto w-auto flex-column">
                    <h3>Select a report</h3>
                    <Form.Control className="w-auto mt-1" onChange={(event) => setTemplate(event.currentTarget.value)}
                        as="select">
                        <option value="">Select a report...</option>
                        <option value="materials-not-received">NEW - Materials not received</option>
                        <option value="sales-order-intake">NEW - Sales order intake</option>
                        <option value="jobs-closed-in-month">NEW - Delivery Performance</option>
                        <option value="total-stock-list">NEW - Positive stock list</option>
                        <option value="goods-received">Goods received in a month</option>
                        <option value="stock-no-SO">Stock with no SO</option>
                        <option value="parts-by-SO">Parts by SO</option>
                        <option value="stock-by-SO">Stock by SO</option>
                        <option value="SO-closed-in-a-month">SO's closed in a month</option>
                    </Form.Control>
                    <div className="mt-2 mb-2 w-100">
                        {(template === "goods-received" || template === "SO-closed-in-a-month" || template === "materials-not-received" || template === "sales-order-intake" || template === "jobs-closed-in-month") &&
                            <>
                                <div className="mb-2 w-100">
                                    <p className="form-label">Year:</p>
                                    <Form.Control className="w-100 mt-1" onChange={(event) => setYear(event.currentTarget.value)}
                                        as="select">
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                    </Form.Control>
                                </div>
                                <div className="mb-2 w-100">
                                    <p className="form-label">Month:</p>
                                    <Form.Control className="w-100 mt-1" onChange={(event) => setMonth(event.currentTarget.value)}
                                        as="select">
                                        <option value="01">January</option>
                                        <option value="02">February</option>
                                        <option value="03">March</option>
                                        <option value="04">April</option>
                                        <option value="05">May</option>
                                        <option value="06">June</option>
                                        <option value="07">July</option>
                                        <option value="08">August</option>
                                        <option value="09">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                    </Form.Control>
                                </div>
                            </>
                        }
                        {(template === "stock-by-SO" || template === "parts-by-SO") &&
                            <>
                                <div className="mb-2 w-100">
                                    <p className="form-label">SO Number:</p>
                                    <Form.Control
                                        className="w-100"
                                        value={so}
                                        type="number"
                                        onChange={(event) => setSo(event.currentTarget.value)}
                                    />
                                </div>
                            </>
                        }
                    </div>
                    <Link to={{
                        pathname: `/reports/${template}`,
                        state: {
                            year: year,
                            month: month,
                            so: so
                        }
                    }} className="button-green w-auto ml-2">Create report</Link>
                </div>
            </div>
        </Screen>
    )
}

export default Reports