import React, { useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import logo from "../../Images/logo-dark.png";
import firebase from "firebase";
import { useParams } from 'react-router-dom';
import moment from "moment";
import { toast } from "react-hot-toast";
import { Form, Table, Button } from "react-bootstrap";
import { SalesOrder } from "./OrderProcessing";
import DatePicker from "react-datepicker";
import Footer from '../../Components/Footer';

export interface OrderAcknowledgement {
    id: string;
    salesOrder: SalesOrder;
    scope: string;
    warranty: string;
    deliveryDate: string;
    delivery: number;
    totalPrice: number;
    dateRaised: string;
}

const ViewOrderAcknowledgement = () => {
    const [salesOrder, setSalesOrder] = useState<SalesOrder>()
    const [preview, setPreview] = useState<boolean>(false)

    // @ts-ignore
    let { id } = useParams();

    const getSalesOrder = async () => {
        const query = firebase.firestore().collection('salesOrders').doc(`SO${id}`);
        await query.get().then((doc) => {
            // @ts-ignore
            setSalesOrder(doc.data())
        })
    }

    useEffect(() => {
        getSalesOrder()
    }, [])

    let total = 0

    if (salesOrder) {
        // @ts-ignore
        salesOrder.parts.map((value, index) => total = total + (salesOrder.quote.itemPrices[index].price * value.quantity) + salesOrder.quote?.deliveryValue
        );
    }

    if (preview) {
        return (
            <div className="bg-white">
                <img className="mb-3" src={logo} />
                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 1.0 - Reference Numbers & Address</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                <th>Your Purchase Order Number</th>
                                <th>Delivery Address</th>
                                <th>Invoice Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{salesOrder?.customerPo}</td>
                                    <td>
                                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressOne}</p>
                                    </td>
                                    <td>
                                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressOne}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='font-weight-bold  mid-cell'>Our Sales Order Number</td>
                                    <td><p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressTwo}</p></td>
                                    <td><p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressTwo}</p></td>
                                </tr>
                                <tr>
                                    <td>SO{salesOrder?.quote.tn}</td>
                                    <td><p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryTownCity}</p></td>
                                    <td><p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceTownCity}</p></td>
                                </tr>
                                <tr>
                                    <td className='font-weight-bold'>Our Quotation Number</td>
                                    <td><p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryPostCode}</p></td>
                                    <td><p>{salesOrder && salesOrder.quote.enquiry.customer.invoicePostCode}</p></td>
                                </tr>
                                <tr>
                                    <td>TN{salesOrder?.quote.tn}</td>
                                    <td>
                                    {salesOrder?.OA?.newExtra?.thercoContact}
                                    </td>
                                    <td>
                                    {salesOrder?.OA?.newExtra?.cusContact}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 2.0 - Quantity, Description & Price</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Line</th>
                                    <th className='text-right'>Qty</th>
                                    <th>Description</th>
                                    <th>Part Number</th>
                                    <th className='text-right'>Unit Price</th>
                                    <th className='text-right'>Line Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {salesOrder && salesOrder.quote.enquiry.parts.map((value, index) => {
                                    const found = salesOrder.quote.itemPrices.find((item) => item.index === index)
                                    let quantity = 0;
                                    if (typeof value.quantity === 'string') {
                                        quantity = parseInt(value.quantity)
                                    }
                                    return (
                                        <tr>
                                            <td className='text-center'>2.{index + 1}</td>
                                            <td className="text-right">{value.quantity}</td>
                                            <td>{value.description}, {value.tagNumber}</td>
                                            <td>{value.partNumber}</td>
                                            <td className="text-right">
                                                £{found?.price.toFixed(2)}
                                            </td>
                                            <td className="text-right">
                                                £{found && (quantity * found.price).toFixed(2)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td className='text-center'>2.{salesOrder && salesOrder.quote.enquiry.parts.length + 1}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                {salesOrder && salesOrder.quote.newExtra && salesOrder.quote.newExtra.manualLinePrices.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='text-center'>2.{salesOrder.quote.enquiry && (salesOrder.quote.enquiry.parts.length + 1 + index + 1)}</td>
                                            <td className='text-right'>{item.qty}</td>
                                            <td>{item.desc}</td>
                                            <td></td>
                                            <td className='text-right'>£{item.price.toFixed(2)}</td>
                                            <td className='text-right'>£{(item.price * item.qty).toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Total (excluding VAT, or Taxes of any other nature)</td>
                                    <td className='no-border'></td>
                                    <td className='no-border'></td>
                                    <td className='text-right'>£{total.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 3.0 - Scope of Supply</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Line</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {salesOrder && salesOrder.quote.scope && salesOrder.quote.scope.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='text-center'>3.{index + 1}</td>
                                            <td>{item}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 4.0 - Delivery</h4>
                    </div>
                    <div className='doc-section-content-padding'>
                        {salesOrder?.OA?.newExtra?.deliveryText}
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 5.0 - Guarantee</h4>
                    </div>
                    <div className='doc-section-content-padding'>
                    {salesOrder?.OA?.newExtra?.guarantee}
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 6.0 - Terms & Conditions</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <tbody>
                                {salesOrder && salesOrder.quote.newExtra && salesOrder.quote.newExtra.terms.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='small-qty text-center'>6.{index + 1}</td>
                                            <td>{item}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Footer />
                <div className="d-flex flex-row justify-content-end w-100 PO-printing">
                    <Button
                        onClick={() => setPreview(!preview)}
                        className="button-green mt-5 w-auto">
                        Cancel preview
                    </Button>
                    <Button
                        onClick={() => window.print()}
                        className="button-green mt-5 ml-2 w-auto">
                        Download PDF
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <Screen active="Order acknowledgement">
            <div className="bg-white p-5">
            <img className="mb-5" src={logo} />
                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 1.0 - Reference Numbers & Address</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                <th>Your Purchase Order Number</th>
                                <th>Delivery Address</th>
                                <th>Invoice Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{salesOrder?.customerPo}</td>
                                    <td className='noBottomBorder'>
                                        <p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressOne}</p>
                                    </td>
                                    <td className='noBottomBorder'>
                                        <p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressOne}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='font-weight-bold  mid-cell'>Our Sales Order Number</td>
                                    <td className='noBottomBorder'><p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryAddressTwo}</p></td>
                                    <td className='noBottomBorder'><p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceAddressTwo}</p></td>
                                </tr>
                                <tr>
                                    <td>SO{salesOrder?.quote.tn}</td>
                                    <td className='noBottomBorder'><p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryTownCity}</p></td>
                                    <td className='noBottomBorder'><p>{salesOrder && salesOrder.quote.enquiry.customer.invoiceTownCity}</p></td>
                                </tr>
                                <tr>
                                    <td className='font-weight-bold'>Our Quotation Number</td>
                                    <td><p>{salesOrder && salesOrder.quote.enquiry.customer.deliveryPostCode}</p></td>
                                    <td><p>{salesOrder && salesOrder.quote.enquiry.customer.invoicePostCode}</p></td>
                                </tr>
                                <tr>
                                    <td>TN{salesOrder?.quote.tn}</td>
                                    <td>
                                    {salesOrder?.OA?.newExtra?.thercoContact}
                                    </td>
                                    <td>
                                    {salesOrder?.OA?.newExtra?.cusContact}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 2.0 - Quantity, Description & Price</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Line</th>
                                    <th className='text-right'>Qty</th>
                                    <th>Description</th>
                                    <th>Part Number</th>
                                    <th className='text-right'>Unit Price</th>
                                    <th className='text-right'>Line Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {salesOrder && salesOrder.quote.enquiry.parts.map((value, index) => {
                                    const found = salesOrder.quote.itemPrices.find((item) => item.index === index)
                                    let quantity = 0;
                                    if (typeof value.quantity === 'string') {
                                        quantity = parseInt(value.quantity)
                                    }
                                    return (
                                        <tr>
                                            <td className='text-center noBottomBorder'>2.{index + 1}</td>
                                            <td className="text-right noBottomBorder">{value.quantity}</td>
                                            <td className='noBottomBorder'>{value.description}, {value.tagNumber}</td>
                                            <td className='noBottomBorder'>{value.partNumber}</td>
                                            <td className="text-right noBottomBorder">
                                                £{found?.price.toFixed(2)}
                                            </td>
                                            <td className="text-right noBottomBorder">
                                                £{found && (quantity * found.price).toFixed(2)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td className='text-center noBottomBorder'>2.{salesOrder && salesOrder.quote.enquiry.parts.length + 1}</td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                </tr>
                                {salesOrder && salesOrder.quote.newExtra && salesOrder.quote.newExtra.manualLinePrices.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='text-center noBottomBorder'>2.{salesOrder.quote.enquiry && (salesOrder.quote.enquiry.parts.length + 1 + index + 1)}</td>
                                            <td className='text-right noBottomBorder'>{item.qty}</td>
                                            <td className='noBottomBorder'>{item.desc}</td>
                                            <td className='noBottomBorder'></td>
                                            <td className='text-right noBottomBorder'>£{item.price.toFixed(2)}</td>
                                            <td className='text-right noBottomBorder'>£{(item.price * item.qty).toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td className='borderTop'></td>
                                    <td className='borderTop'></td>
                                    <td className='borderTop'>Total (excluding VAT, or Taxes of any other nature)</td>
                                    <td className='borderTop'></td>
                                    <td className='borderTop'></td>
                                    <td className='text-right borderTop'>£{total.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 3.0 - Scope of Supply</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Line</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {salesOrder && salesOrder.quote.scope && salesOrder.quote.scope.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='text-center noBottomBorder'>3.{index + 1}</td>
                                            <td className='noBottomBorder'>{item}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 4.0 - Delivery</h4>
                    </div>
                    <div className='doc-section-content-padding'>
                        {salesOrder?.OA?.newExtra?.deliveryText}
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 5.0 - Guarantee</h4>
                    </div>
                    <div className='doc-section-content-padding'>
                    {salesOrder?.OA?.newExtra?.guarantee}
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 6.0 - Terms & Conditions</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <tbody>
                                {salesOrder && salesOrder.quote.newExtra && salesOrder.quote.newExtra.terms.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='small-qty text-center noBottomBorder'>6.{index + 1}</td>
                                            <td className='noBottomBorder'>{item}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="d-flex justify-content-end w-100">
                    <Button
                        onClick={() => setPreview(!preview)}
                        className="button-green mt-5 w-auto">
                        View preview & print
                    </Button>
                </div>
            </div>
        </Screen>
    )
}

export default ViewOrderAcknowledgement