import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Enquiry } from "./Enquiries";
import firebase from "firebase";
import { Supplier } from "../ControlPanel";
import { MdAdd } from "react-icons/all";
import { toast } from "react-hot-toast";
import BOM from "../../Components/BOM";

interface EnquiryEmailModalProps {
    enquiry: Enquiry
    show: boolean
    hide: () => void
}

const EnquiryEmailModal = ({ show = false, hide, enquiry }: EnquiryEmailModalProps) => {
    const [selectedParts, setSelectedParts] = useState<{ partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }[]>([])
    const [allSuppliers, setAllSuppliers] = useState<Supplier[]>()
    const [partSearchText, setPartSearchText] = useState<string>("")
    const [partQuantity, setPartQuantity] = useState<string>("1")
    const [bom, setBom] = useState<any[]>([])
    const [newBom, setNewBom] = useState<any[]>([])

    const handlePartSelect = (value: { partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }) => {
        if (selectedParts) {
            if (!selectedParts.includes(value)) {
                setSelectedParts([...selectedParts, value])
            }
        } else {
            const temp = [];
            temp.push(value)
            setSelectedParts(temp)
        }
    }

    const searchForPart = async () => {
        if (partSearchText.length > 0) {
            const db = firebase.firestore().collection("parts").doc(partSearchText);
            await db.get().then((doc) => {
                let temp: { partNumber?: string, description?: string, tagNumber?: string, quantity?: string, price?: number, dimension?: string, material?: string } = {}
                if (doc.exists) {
                    temp.partNumber = partSearchText
                    // @ts-ignore
                    temp.description = doc.data().description
                    // @ts-ignore
                    temp.tagNumber = ""
                    temp.dimension = ""
                    temp.material = ""
                    temp.quantity = partQuantity
                    // @ts-ignore
                    temp.price = doc.data().price || 0
                } else {
                    temp.partNumber = partSearchText
                }
                // @ts-ignore
                setSelectedParts([...selectedParts, temp])
            })
        } else {
            toast.error(`This part number does not exist. Please create a new part in the parts inventory.`)
        }
    }

    const getSuppliers = async () => {
        let temp: Supplier[] = []
        const query = firebase.firestore().collection('suppliers')
            .orderBy('id', "desc")
            .limit(30)

        const suppliers = await query.get()
        suppliers.docs.map((doc) => {
            // @ts-ignore
            temp.push(doc.data())
        })
        setAllSuppliers(temp)
    }

    const generateEmail = () => {
        const standardText = "Dear Sirs, please quote price and delivery for the following items: \n";
        const formattedBody = selectedParts?.map((value) => `\n${value.partQuantity} x (${value.partNumber} ${value.description})`)
        formattedBody && window.open('mailto:?subject=TN' + encodeURIComponent(enquiry.tnNumber) + ' Therco-Serck enquiry&body=' + encodeURIComponent(standardText) + encodeURIComponent(formattedBody.toString()));
        setSelectedParts([])
    }

    const getBillOfMaterials = async (partId: string) => {
        const query = firebase.firestore().collection("billPartsLink").doc(partId)
        return await query.get().then((doc) => {
            console.log("Request, Parts, get BOM")
            if (doc.exists) {
                // @ts-ignore
                return (doc.data().subParts)
            }
        })
    }

    const getBillOfMaterialsSubsById = async (id: string, level: number, sequence: number, subSequence: number) => {
        const query = firebase.firestore().collection("billPartsLink").doc(id)
        return await query.get().then((doc) => {
            console.log("Request, Parts, getBOM by ID")
            if (doc.exists) {
                // @ts-ignore
                doc.data().subParts.map(async (subDoc, index) => {
                    const query = firebase.firestore().collection("parts").doc(subDoc.partNumber)
                    await query.get().then((doc) => {
                        // @ts-ignore
                        setBom(bom.concat({
                            ...subDoc,
                            level: (level + 1),
                            sequence: sequence,
                            subSequence: (subSequence + index),
                            // @ts-ignore
                            description: doc.data().description + ", " + doc.data().dimensions + ", " + doc.data().material
                        }))
                        checkForSubs(subDoc.partNumber).then((subCheck) => {
                            if (subCheck) {
                                getBillOfMaterialsSubsById(subDoc.partNumber, level + 1, sequence, (subSequence + index))
                            }
                        })
                    })
                })
            }
        })
    }

    const checkForSubs = async (id: string) => {
        const query = firebase.firestore().collection("billPartsLink").doc(id)
        return await query.get().then((doc) => {
            console.log("Request, Parts, Initial data")
            if (doc.exists) {
                return true
            } else {
                return false
            }
        })
    }

    const initiate = async (partId: string) => {
        getBillOfMaterials(partId).then((subParts) => {
            if (subParts) {
                subParts.map(async (part: { partNumber: string, partQuantity: number }, index: number) => {
                    const query = firebase.firestore().collection("parts").doc(part.partNumber)
                    await query.get().then((doc) => {
                        // @ts-ignore
                        setBom(bom.concat({
                            ...part,
                            level: 1,
                            sequence: index,
                            subSequence: index,
                            // @ts-ignore
                            description: doc.data().description + ", " + doc.data().dimensions + ", " + doc.data().material
                        }))
                        checkForSubs(part.partNumber).then((subCheck) => {
                            if (subCheck) {
                                getBillOfMaterialsSubsById(part.partNumber, 1, index, index)
                            }
                        })
                    })
                })
            }
        })
    }

    useEffect(() => {
        if (bom.length === 0) {
            setNewBom(bom)
        } else {
            setNewBom(newBom.concat(...bom))
        }
    }, [bom])

    // @ts-ignore
    useEffect(() => {
        getSuppliers()
        enquiry.parts.map((value: any, index: any, array: any) => {
            initiate(value.partNumber)
        })
    }, [])

    return (
        <Modal dialogClassName="large-dialog" show={show} onHide={() => {
            setSelectedParts([])
            hide()
        }}>
            <Modal.Header closeButton>
                <Modal.Title>{`Generate enquiry email`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-2">
                    <p className="form-label">Add part manually via part number:</p>
                    <div className="d-flex flex-row align-items-center mb-3">
                        <Form.Control value={partSearchText}
                            onChange={(event) => setPartSearchText(event.currentTarget.value)}
                            className="w-25" placeholder="Enter part number..." />
                        <Form.Control value={partQuantity}
                            type="Number"
                            onChange={(event) => setPartQuantity(event.currentTarget.value)}
                            className="ml-1 small-qty" placeholder="QTY..." />
                        <Button
                            onClick={() => searchForPart()}
                            className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                            <MdAdd size={24} color="white" />
                        </Button>
                    </div>
                </div>
                <p className="form-label">Select line items for enquiry email:</p>
                {enquiry.parts.map((value: any, index: any, array: any) => {
                    return (
                        <BOM topLevelQty={parseInt(value.quantity)} explodedProp hideButtons data={newBom} bomRef={value.partNumber} onClick={(part) => {
                            const temp: { partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string } = part
                            temp.partQuantity = temp.partQuantity * value.quantity
                            handlePartSelect(temp)
                        }} />
                    )
                })}
                {selectedParts &&
                    <>
                        <p className="form-label">Items selected for enquiry email:</p>
                        <Table className="mb-4" bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Part number</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedParts.map((value: any, index: any, array: any) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{value.partNumber}</td>
                                            <td>{value.description}</td>
                                            <td>{value.partQuantity}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                {selectedParts &&
                    <Button disabled={selectedParts.length < 1} onClick={() => generateEmail()}
                        className="button-green w-auto">
                        Generate enquiry email
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EnquiryEmailModal