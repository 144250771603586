import React, { useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import logo from "../../Images/logo-dark.png";
import firebase from "firebase";
import { useParams, useHistory } from 'react-router-dom';
import moment from "moment";
import { toast } from "react-hot-toast";
import { Form, Table, Button } from "react-bootstrap";
import { SalesOrder } from "./OrderProcessing";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import Footer from '../../Components/Footer';

const InvoiceView = () => {
    const [salesOrder, setSalesOrder] = useState<SalesOrder>()
    const [contact, setContact] = useState<string>("")
    const [taxDate, setTaxDate] = useState<string>(moment().format("DD/MM/YYYY"))
    const [preview, setPreview] = useState<boolean>(false)

    const history = useHistory();
    // @ts-ignore
    let { id } = useParams();

    const getSalesOrder = async () => {
        const query = firebase.firestore().collection('salesOrders').doc(`SO${id}`);
        await query.get().then((doc) => {
            // @ts-ignore
            setSalesOrder(doc.data())
        })
    }

    useEffect(() => {
        getSalesOrder()
    }, [])

    let total = 0

    if (salesOrder) {
        salesOrder.parts.map((value, index) =>
            total = total + (salesOrder.quote.itemPrices[index].price * value.quantity)
        );
        salesOrder.quote.newExtra?.manualLinePrices.map((item) => {
            total = total + (item.price * item.qty)
        })
    }

    const vat = (total / 100) * 20

    const gross = total + vat


    if(preview) {
        return (
            <div className="bg-white p-1">
                 <img className="mb-5" src={logo} />
                <div className='d-flex flex-row justify-content-between align-items-end'>
                    <h1>Commericial Invoice</h1>
                    <p className="mb-3">{moment().format('DD/MM/YYYY')}</p>
                </div>
                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 1.0 - Reference Numbers & Address</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Your Purchase Order Number</th>
                                    <th>Delivery Address</th>
                                    <th>Invoice Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{salesOrder?.customerPo}</td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.name}
                                    </td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='font-weight-bold  mid-cell'>Our Commercial Invoice</td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.deliveryAddressOne}
                                    </td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.invoiceAddressOne}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {id}SI1
                                    </td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.deliveryAddressTwo}
                                    </td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.invoiceAddressTwo}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='font-weight-bold'>Our Quotation Number</td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.deliveryPostCode}
                                    </td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.invoicePostCode}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        TN{salesOrder?.quote.enquiry.tnNumber}
                                    </td>
                                    <td>
                                        {salesOrder?.quote.enquiry.customer.deliveryTownCity}
                                    </td>
                                    <td>
                                        {salesOrder?.quote.enquiry.customer.invoiceTownCity}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 2.0 - Quantity, Description & Price</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Line</th>
                                    <th className='text-right'>Qty</th>
                                    <th>Description</th>
                                    <th>Part Number</th>
                                    <th className='text-right'>Unit Price</th>
                                    <th className='text-right'>Line Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {salesOrder?.quote.enquiry.parts.map((value, index) => {
                                    const found = salesOrder.quote.itemPrices.find((item) => item.index === index)
                                    console.log(found)
                                    let quantity = 0;
                                    if (typeof value.quantity === 'string') {
                                        quantity = parseInt(value.quantity)
                                    }
                                    return (
                                        <tr>
                                            <td className='text-center noBottomBorder'>2.{index + 1}</td>
                                            <td className="text-right noBottomBorder">{value.quantity}</td>
                                            <td className='noBottomBorder'>{value.description}, {value.tagNumber}</td>
                                            <td className='noBottomBorder'>{value.partNumber}</td>
                                            <td className="text-right noBottomBorder">
                                                £{found?.price.toFixed(2)}
                                            </td>
                                            <td className="text-right noBottomBorder">
                                                £{found && (quantity * found.price).toFixed(2)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td className='text-center noBottomBorder'>2.{salesOrder?.quote.enquiry && salesOrder?.quote.enquiry.parts.length + 1}</td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                </tr>
                                {salesOrder?.quote.newExtra && salesOrder?.quote.newExtra.manualLinePrices.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='text-center noBottomBorder'>2.{salesOrder?.quote.enquiry && (salesOrder?.quote.enquiry.parts.length + 1 + index + 1)}</td>
                                            <td className='text-right noBottomBorder'>{item.qty}</td>
                                            <td className='noBottomBorder'>{item.desc}</td>
                                            <td className='noBottomBorder'></td>
                                            <td className='text-right noBottomBorder'>£{item.price.toFixed(2)}</td>
                                            <td className='text-right noBottomBorder'>£{(item.price * item.qty).toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td className='text-center borderTop'>2.{salesOrder?.quote.enquiry.parts && salesOrder?.quote?.newExtra?.manualLinePrices && (salesOrder.quote.enquiry.parts.length + salesOrder?.quote?.newExtra?.manualLinePrices.length + 2)}</td>
                                    <td className='borderTop'>

                                    </td>
                                    <td className='borderTop'>

                                    </td>
                                    <td className='no-border borderTop'></td>
                                    <td className='no-border text-right borderTop'>
                                        Sub Total, Nett
                                    </td>
                                    <td className='text-right borderTop'>
                                        £{total.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center'>2.{salesOrder?.quote.enquiry.parts && salesOrder?.quote?.newExtra?.manualLinePrices && (salesOrder.quote.enquiry.parts.length + salesOrder?.quote?.newExtra?.manualLinePrices.length + 3)}</td>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                    <td className='no-border'></td>
                                    <td className='no-border text-right'>
                                        VAT
                                    </td>
                                    <td className='text-right'>
                                        £{vat.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center'>2.{salesOrder?.quote.enquiry.parts && salesOrder?.quote?.newExtra?.manualLinePrices && (salesOrder.quote.enquiry.parts.length + salesOrder?.quote?.newExtra?.manualLinePrices.length + 4)}</td>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                    <td className='no-border'></td>
                                    <td className='no-border text-right'>
                                        Total, Gross
                                    </td>
                                    <td className='text-right'>
                                        £{(total + vat).toFixed(2)}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 3.0 - Terms & Conditions</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <tbody>
                                <tr>
                                    <td className='small-qty text-center noBottomBorder'>3.1</td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.SI?.newExtra?.termOne}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='small-qty text-center noBottomBorder'>3.2</td>
                                    <td className='noBottomBorder'>
                                    {salesOrder?.SI?.newExtra?.termTwo}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Footer/>
                <div className="d-flex flex-row justify-content-end w-100 PO-printing">
                    <Button
                        onClick={() => setPreview(!preview)}
                        className="button-green mt-5 w-auto">
                        Cancel preview
                    </Button>
                    <Button
                        onClick={() => window.print()}
                        className="button-green mt-5 ml-2 w-auto">
                        Download PDF
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <Screen active="Order acknowledgement">
            <div className="bg-white p-5">
            <img className="mb-5" src={logo} />
                <div className='d-flex flex-row justify-content-between align-items-end'>
                    <h1>Commericial Invoice</h1>
                    <p className="mb-3">{moment().format('DD/MM/YYYY')}</p>
                </div>
                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 1.0 - Reference Numbers & Address</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Your Purchase Order Number</th>
                                    <th>Delivery Address</th>
                                    <th>Invoice Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{salesOrder?.customerPo}</td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.name}
                                    </td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='font-weight-bold  mid-cell'>Our Commercial Invoice</td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.deliveryAddressOne}
                                    </td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.invoiceAddressOne}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {id}SI1
                                    </td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.deliveryAddressTwo}
                                    </td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.invoiceAddressTwo}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='font-weight-bold'>Our Quotation Number</td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.deliveryPostCode}
                                    </td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.quote.enquiry.customer.invoicePostCode}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        TN{salesOrder?.quote.enquiry.tnNumber}
                                    </td>
                                    <td>
                                        {salesOrder?.quote.enquiry.customer.deliveryTownCity}
                                    </td>
                                    <td>
                                        {salesOrder?.quote.enquiry.customer.invoiceTownCity}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 2.0 - Quantity, Description & Price</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Line</th>
                                    <th className='text-right'>Qty</th>
                                    <th>Description</th>
                                    <th>Part Number</th>
                                    <th className='text-right'>Unit Price</th>
                                    <th className='text-right'>Line Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {salesOrder?.quote.enquiry.parts.map((value, index) => {
                                    const found = salesOrder.quote.itemPrices.find((item) => item.index === index)
                                    console.log(found)
                                    let quantity = 0;
                                    if (typeof value.quantity === 'string') {
                                        quantity = parseInt(value.quantity)
                                    }
                                    return (
                                        <tr>
                                            <td className='text-center noBottomBorder'>2.{index + 1}</td>
                                            <td className="text-right noBottomBorder">{value.quantity}</td>
                                            <td className='noBottomBorder'>{value.description}, {value.tagNumber}</td>
                                            <td className='noBottomBorder'>{value.partNumber}</td>
                                            <td className="text-right noBottomBorder">
                                                £{found?.price.toFixed(2)}
                                            </td>
                                            <td className="text-right noBottomBorder">
                                                £{found && (quantity * found.price).toFixed(2)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td className='text-center noBottomBorder'>2.{salesOrder?.quote.enquiry && salesOrder?.quote.enquiry.parts.length + 1}</td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                </tr>
                                {salesOrder?.quote.newExtra && salesOrder?.quote.newExtra.manualLinePrices.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='text-center noBottomBorder'>2.{salesOrder?.quote.enquiry && (salesOrder?.quote.enquiry.parts.length + 1 + index + 1)}</td>
                                            <td className='text-right noBottomBorder'>{item.qty}</td>
                                            <td className='noBottomBorder'>{item.desc}</td>
                                            <td className='noBottomBorder'></td>
                                            <td className='text-right noBottomBorder'>£{item.price.toFixed(2)}</td>
                                            <td className='text-right noBottomBorder'>£{(item.price * item.qty).toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td className='text-center borderTop'>2.{salesOrder?.quote.enquiry.parts && salesOrder?.quote?.newExtra?.manualLinePrices && (salesOrder.quote.enquiry.parts.length + salesOrder?.quote?.newExtra?.manualLinePrices.length + 2)}</td>
                                    <td className='borderTop'>

                                    </td>
                                    <td className='borderTop'>

                                    </td>
                                    <td className='no-border borderTop'></td>
                                    <td className='no-border text-right borderTop'>
                                        Sub Total, Nett
                                    </td>
                                    <td className='text-right borderTop'>
                                        £{total.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center'>2.{salesOrder?.quote.enquiry.parts && salesOrder?.quote?.newExtra?.manualLinePrices && (salesOrder.quote.enquiry.parts.length + salesOrder?.quote?.newExtra?.manualLinePrices.length + 3)}</td>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                    <td className='no-border'></td>
                                    <td className='no-border text-right'>
                                        VAT
                                    </td>
                                    <td className='text-right'>
                                        £{vat.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center'>2.{salesOrder?.quote.enquiry.parts && salesOrder?.quote?.newExtra?.manualLinePrices && (salesOrder.quote.enquiry.parts.length + salesOrder?.quote?.newExtra?.manualLinePrices.length + 4)}</td>
                                    <td>

                                    </td>
                                    <td>

                                    </td>
                                    <td className='no-border'></td>
                                    <td className='no-border text-right'>
                                        Total, Gross
                                    </td>
                                    <td className='text-right'>
                                        £{(total + vat).toFixed(2)}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 3.0 - Terms & Conditions</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <tbody>
                                <tr>
                                    <td className='small-qty text-center noBottomBorder'>3.1</td>
                                    <td className='noBottomBorder'>
                                        {salesOrder?.SI?.newExtra?.termOne}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='small-qty text-center noBottomBorder'>3.2</td>
                                    <td className='noBottomBorder'>
                                    {salesOrder?.SI?.newExtra?.termTwo}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="d-flex justify-content-end w-100">
                    <Button
                        onClick={() => setPreview(!preview)}
                        className="button-green mt-5 w-auto">
                    View preview & print
                    </Button>
                </div>
            </div>
        </Screen>
    )
}

export default InvoiceView