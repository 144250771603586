import React, { useEffect, useState } from 'react';
import Screen from "../../../Components/Screen";
import firebase from "firebase";
import { GRN } from "../../Goods in/CreateGRN";
import { useLocation } from "react-router";
import logo from "../../../Images/logo-dark.png";
import { Button, Card, Table } from "react-bootstrap";
import { SalesOrder } from '../../Order processing/OrderProcessing';
import { Part } from '../../Parts';
import { PurchaseOrder } from '../../Purchase Orders/PurchaseOrdersCreate';

const PartsBySo = () => {
    const [so, setSo] = useState<SalesOrder>()
    const [parts, setParts] = useState<Part[]>([])
    const [rec, setRec] = useState<{ date: string, partId: string }[]>([])
    const location = useLocation()
    const getGrns = async () => {
        // @ts-ignore
        const id = `SO${location.state?.so}`
        const query = firebase.firestore().collection('salesOrders').doc(id)
        await query.get().then((doc) => {
            // @ts-ignore
            setSo(doc.data())
        })
    }

    const getParts = async () => {
        if (so) {
            const parts: Part[] = []
            so.parts.map(async (part) => {
                const query = firebase.firestore().collection('parts').doc(part.partNumber)
                await query.get().then((doc) => {
                    // @ts-ignore
                    parts.push({ id: part.partNumber, ...doc.data() })
                })
            })
            setParts(parts)
        }

    }

    const searchForGRN = async (po: PurchaseOrder[]) => {
        po.map(async (purchase) => {
            const query = firebase.firestore().collection('GRN').where("poNumber", "==", purchase.id.toString())
            await query.get().then(async (doc) => {
                //@ts-ignore
                const goods: { date: string, partId: string }[] = []
                doc.forEach((ind) => {
                    //@ts-ignore
                    goods.push(...ind.data().dateReceived)
                })
                setRec(goods)

            })
        })
    }

    const searchForPO = async () => {
        //@ts-ignore
        const query = firebase.firestore().collection('purchaseOrders').where("soNumber", "==", parseInt(location.state?.so))
        await query.get().then(async (doc) => {
            //@ts-ignore
            const po: PurchaseOrder[] = []
            doc.forEach((ind) => {
                //@ts-ignore
                po.push(ind.data())
            })
            searchForGRN(po)
        })
    }

    useEffect(() => {
        getGrns()
    }, [])

    useEffect(() => {
        getParts()
        searchForPO()
    }, [so])

    // @ts-ignore
    const title = `Parts list for SO${location.state?.so}`
    console.log(rec)
    console.log(so)

    return (
        <Screen active="Reports">
            <div className="purchaseOrdersView">
                <div className="purchaseOrdersCreate-template">
                    <img className="mb-5" src={logo} />
                    <h2 className="mb-5 purchaseOrdersCreate-template__green-text text-uppercase">{title}</h2>
                    <Card className="mb-4">
                        <Card.Header>
                            <h4 className="mb-0 purchaseOrdersCreate-template__green-text">SECTION 1.0 – PARTS LIST</h4>
                        </Card.Header>
                        <Card.Body>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Part number</th>
                                        <th>Description</th>
                                        <th className="text-right">Stock QTY</th>
                                        <th className="text-right">Item cost</th>
                                        <th className="text-right">Line cost</th>
                                        <th>QTY</th>
                                        <th>Supplier</th>
                                        <th>PO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {parts.map((part) => {
                                        console.log(rec)
                                        return (
                                            <>
                                                <td>{part.id}</td>
                                                <td>{part.description}, {part.dimensions}, {part.material}</td>
                                                <td>{part.stock}</td>
                                                <td>{part.price}</td>
                                                <td>£{(parseInt(part.price) * part.stock)}</td>
                                                <td>{so?.parts.filter((a) => a.partNumber === part.id)[0].quantity}</td>
                                                <td>{part.supplierName}</td>
                                                <td>{so?.customerPo}</td>
                                                {/* <td>{rec.filter((b) => {
                                                console.log(b)
                                                console.log(part.id)
                                            })}</td> */}
                                            </>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <div className="d-flex flex-row justify-content-end w-100 mt-5">
                        <div className="d-flex flex-row align-items-center">
                            <Button
                                className="button-green ml-3">
                                View preview & print
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Screen>
    )
}

export default PartsBySo