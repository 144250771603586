import React, { ReactNode, useContext, useEffect, useState } from 'react';
import logo from '../Images/logo.png'
import { Link, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import {
    MdApps,
    MdAssignment,
    MdChat,
    MdChevronLeft,
    MdDashboard,
    MdExpandLess,
    MdExpandMore,
    MdExtension,
    MdFlightLand,
    MdHelp,
    MdInsertDriveFile,
    MdMessage,
    MdNotifications,
    MdPerson,
    MdSearch,
    MdMenu,
    MdExitToApp,
    MdSettings,
    MdStore
} from "react-icons/md";
import { FormControl, InputGroup, OverlayTrigger, Popover } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { globalNotifications, globalUser } from "../App";
import { Toaster } from "react-hot-toast";
import firebase from "firebase";
import moment from "moment";
import { staticBoms } from "../Static Data/BOMS.js"

interface ScreenProps {
    children: ReactNode;
    active: string;
}

const Screen = ({ children, active }: ScreenProps) => {
    const currentUser = useContext(globalUser)
    const allNotifications = useContext(globalNotifications)
    const history = useHistory();
    const [openExtra, setOpenExtra] = useState<string>("")
    const [sideCollapsed, setSideCollapsed] = useState<boolean>(false)
    const [utilitiesOpen, setUtilitiesOpen] = useState<boolean>(false)
    const mediaQuery = window.matchMedia('(max-width: 1280px)')
    const mobileQuery = window.matchMedia('(max-width: 850px)')

    useEffect(() => {
        if (mobileQuery.matches) {
            setSideCollapsed(true)
        }
        var user = firebase.auth().currentUser;
        if (!user) {
            history.push("/")
        }
    }, [])

    const viewNotification = async (viewed: { dateTime: string; viewed: boolean; link: string; text: string; type: string }) => {
        const temp = allNotifications.allNotifications
        const resubmit = temp.map((value) => {
            if (value === viewed) {
                value.viewed = true;
            }
            return value
        })
        const query = firebase.firestore().collection("notifications").doc(currentUser.currentUser.uid)
        await query.set({ notifications: resubmit })
    }

    const importBoms = async () => {
        const submitTemp: any[] = []
        staticBoms.map(async (item, index) => {
            const newPart = {
                description: item.PartName || "",
                dimensions: "",
                finish: "",
                material: "",
                materialGroup: "",
                nominalCodePurchasing: "",
                nominalCodeSales: "",
                price: 0,
                productType: "",
                stock: 0,
                supplierName: "",
            }
            if (item.PartNumber !== "") {
                await firebase.firestore().collection("parts").doc(item.PartNumber).set(newPart).then(() => console.log(`Part ${item.PartNumber} added - ${index}`))
            }
        })
    }

    return (
        <div className="screen">
            <div className="screen-top">
                <img src={logo} className="screen-top__logo" />
                {/* <Button onClick={() => importBoms()} className="button-green w-auto">Import Static</Button> */}

                {!mobileQuery.matches &&
                    <div className="screen-top-user">
                        <div className="screen-top-user-extra">
                            {/* <OverlayTrigger
                            trigger="click"
                            placement={"bottom"}
                            show={openExtra === "popover-messages"}
                            onToggle={(open) => open ? setOpenExtra("popover-messages") : setOpenExtra("")}
                            overlay={
                                <Popover id={`popover-messages`}>
                                    <Popover.Title as="h3">
                                        <MdChat style={{marginRight: ".5rem"}} size={18} color="#131313"/>
                                        Messages
                                    </Popover.Title>
                                    <Popover.Content style={{padding: 0}}>
                                        <Table  bordered hover>
                                            <tbody>
                                            <tr>
                                                <td className="screen-top-user-extra-message">
                                                    <MdPerson size={24} color="#131313"/>
                                                    <div className="screen-top-user-extra-message-content">
                                                        <p className="screen-top-user-extra-message-content__user">
                                                            James Mack
                                                        </p>
                                                        <p>Hello world my name is James Mack</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="screen-top-user-extra-message">
                                                    <MdPerson size={24} color="#131313"/>
                                                    <div className="screen-top-user-extra-message-content">
                                                        <p className="screen-top-user-extra-message-content__user">
                                                            Ellis West
                                                        </p>
                                                        <p>Hello world my name is Ellis West</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="screen-top-user-extra-message">
                                                    <MdPerson size={24} color="#131313"/>
                                                    <div className="screen-top-user-extra-message-content">
                                                        <p className="screen-top-user-extra-message-content__user">
                                                            Tessa Broke
                                                        </p>
                                                        <p>Hello world my name is Tessa Broke</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="screen-top-user-extra-message">
                                                    <MdPerson size={24} color="#131313"/>
                                                    <div className="screen-top-user-extra-message-content">
                                                        <p className="screen-top-user-extra-message-content__user">
                                                            Mark Right
                                                        </p>
                                                        <p>Hello world my name is Mark Right</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Popover.Content>
                                </Popover>
                            }
                        >
                            <MdChat size={20} color="white"/>
                        </OverlayTrigger> */}
                            <OverlayTrigger
                                trigger="click"
                                placement={"bottom"}
                                show={openExtra === "popover-notifications"}
                                onToggle={(open) => {
                                    open ? setOpenExtra("popover-notifications") : setOpenExtra("");
                                }}
                                overlay={
                                    <Popover id={`popover-notifications`}>
                                        <Popover.Title as="h3">
                                            <MdNotifications style={{ marginRight: ".5rem" }} size={18} color="#131313" />
                                            Notifications
                                        </Popover.Title>
                                        <Popover.Content className="overflow-auto" style={{ padding: 0, height: "20rem" }}>
                                            <Table bordered hover>
                                                <tbody>
                                                    {allNotifications.allNotifications.length === 0 &&
                                                        <tr>

                                                            <td className="screen-top-user-extra-notification">
                                                                <div className="d-flex flex-column ml-2">
                                                                    <p className={`screen-top-user-extra-notification__title `}>
                                                                        You have no notifications
                                                                    </p>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    }
                                                    {allNotifications.allNotifications?.map((notification) => {
                                                        return (
                                                            <tr onClick={() => viewNotification(notification)}>
                                                                <Link className={notification.viewed ? "plain-link" : "plain-link plain-link--bold"} to={notification.link}>
                                                                    <td className="screen-top-user-extra-notification">
                                                                        <MdAssignment size={30}
                                                                            color={notification.viewed ? "#131313" : "#51ad32"} />
                                                                        <div className="d-flex flex-column ml-2">
                                                                            <p className={`screen-top-user-extra-notification__title ${!notification.viewed && 'font-weight-bold'}`}>
                                                                                {notification.text}
                                                                            </p>
                                                                            <p className="mt-1 pt-1">{moment(new Date(notification.dateTime)).format("DD/MM/YYYY")}</p>
                                                                        </div>
                                                                    </td>
                                                                </Link>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Popover.Content>
                                    </Popover>
                                }
                            >
                                <MdNotifications size={20} color={allNotifications.allNotifications.filter((value) => !value.viewed).length > 0 ? "#51ad32" : "white"} />
                            </OverlayTrigger>
                            <OverlayTrigger
                                trigger="click"
                                placement={"bottom"}
                                show={openExtra === "popover-help"}
                                onToggle={(open) => open ? setOpenExtra("popover-help") : setOpenExtra("")}
                                overlay={
                                    <Popover id={`popover-help`}>
                                        <Popover.Title as="h3">
                                            <MdHelp style={{ marginRight: ".5rem" }} size={18} color="#131313" />
                                            Help
                                        </Popover.Title>
                                        <Popover.Content style={{ padding: 0 }}>
                                            <Table bordered hover>
                                                <tbody>
                                                    <tr>
                                                        <td className="screen-top-user-extra-help">
                                                            <MdHelp size={24} color="#131313" />
                                                            <p className="screen-top-user-extra-help__title">
                                                                How to create graphs
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="screen-top-user-extra-help">
                                                            <MdHelp size={24} color="#131313" />
                                                            <p className="screen-top-user-extra-help__title">
                                                                How to create and assign tasks
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="screen-top-user-extra-help">
                                                            <MdHelp size={24} color="#131313" />
                                                            <p className="screen-top-user-extra-help__title">
                                                                How to add to the part list
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="screen-top-user-extra-help">
                                                            <MdHelp size={24} color="#131313" />
                                                            <p className="screen-top-user-extra-help__title">
                                                                How to create invoices
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Popover.Content>
                                    </Popover>
                                }
                            >
                                <MdHelp size={20} color="white" />
                            </OverlayTrigger>
                        </div>
                        {currentUser.currentUser &&
                            <p>{currentUser.currentUser.name}</p>
                        }
                        <OverlayTrigger
                            trigger="click"
                            placement={"bottom"}
                            show={openExtra === "user"}
                            onToggle={(open) => open ? setOpenExtra("user") : setOpenExtra("")}
                            overlay={
                                <Popover id={`user`}>
                                    <Popover.Title as="h3">
                                        <MdSettings style={{ marginRight: ".5rem" }} size={18} color="#131313" />
                                        User settings
                                    </Popover.Title>
                                    <Popover.Content style={{ padding: 0 }}>
                                        <Table bordered hover>
                                            <tbody>
                                                <tr>
                                                    <td onClick={() => window.location.reload()} className="screen-top-user-extra-help">
                                                        <MdExitToApp  size={24} color="#131313" />
                                                        <p className="screen-top-user-extra-help__title">
                                                            Sign out
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Popover.Content>
                                </Popover>
                            }
                        >
                            <MdPerson className='cursor' size={24} color="white" />
                        </OverlayTrigger>
                    </div>
                }
                {mobileQuery.matches && <MdMenu onClick={() => setSideCollapsed(!sideCollapsed)} size={30} color="white" />}
            </div>
            <div className="screen-content">
                {!sideCollapsed &&
                    <div className="screen-content-side">
                        <div className="screen-content-side-user">
                            <div className="screen-content-side-user__image">
                                <MdPerson size={80} color="white" />
                            </div>
                            {currentUser.currentUser &&
                                <p className="screen-content-side-user__name">{currentUser.currentUser.name}</p>
                            }
                            <div className="screen-content-side-user-info">
                                <p className="screen-content-side-user-info__text">
                                    <MdNotifications size={18} color="white" />
                                    {`${allNotifications.allNotifications.filter((value) => !value.viewed).length} new notifications`}
                                </p>
                                {/* <p className="screen-content-side-user-info__text">
                                <MdAssignment size={18} color="white"/>
                                0 new tasks
                            </p>
                            <p className="screen-content-side-user-info__text">
                                <MdChat size={18} color="white"/>
                                0 new messages
                            </p> */}
                            </div>
                        </div>
                        <ul className="screen-content-side-links">
                            <Link className={
                                `screen-content-side-links__link 
                            ${active === "Dashboard" &&
                                "screen-content-side-links__link--active"}`
                            }
                                to="/dashboard">
                                <MdDashboard size={mediaQuery.matches ? 30 : 20} color="white" />
                                {!mediaQuery.matches && "Dashboard"}
                            </Link>
                            <Link className={
                                `screen-content-side-links__link 
                            ${active === "Enquiries" &&
                                "screen-content-side-links__link--active"}`
                            }
                                to="/enquiries">
                                <MdInsertDriveFile size={mediaQuery.matches ? 30 : 20} color="white" />
                                {!mediaQuery.matches && "Enquiry Processing"}
                            </Link>
                            <Link className={
                                `screen-content-side-links__link 
                            ${active === "Order processing" &&
                                "screen-content-side-links__link--active"}`
                            }
                                to="/order-processing">
                                <MdAssignment size={mediaQuery.matches ? 30 : 20} color="white" />
                                {!mediaQuery.matches && "Order Processing"}
                            </Link>
                            <Link className={
                                `screen-content-side-links__link 
                            ${active === "Parts inventory" &&
                                "screen-content-side-links__link--active"}`
                            }
                                to="/parts-inventory">
                                <MdStore size={mediaQuery.matches ? 30 : 20} color="white" />
                                {!mediaQuery.matches && "Parts Inventory"}
                            </Link>
                            <Link className={
                                `screen-content-side-links__link 
                            ${(
                                    active === "Purchase orders" ||
                                    active === "Purchase orders view" ||
                                    active === "Purchase orders create"
                                ) &&
                                "screen-content-side-links__link--active"}`
                            }
                                to="/purchase-orders">
                                <MdAssignment size={mediaQuery.matches ? 30 : 20} color="white" />
                                {!mediaQuery.matches && "Purchasing"}
                            </Link>
                            <Link className={
                                `screen-content-side-links__link 
                            ${active === "GoodsIn" &&
                                "screen-content-side-links__link--active"}`
                            }
                                to="/goods-in">
                                <MdFlightLand size={mediaQuery.matches ? 30 : 20} color="white" />
                                {!mediaQuery.matches && "Goods In"}
                            </Link>
                            <Link className={
                                `screen-content-side-links__link 
                            ${active === "Reports" &&
                                "screen-content-side-links__link--active"}`
                            }
                                to="/reports">
                                <MdAssignment size={mediaQuery.matches ? 30 : 20} color="white" />
                                {!mediaQuery.matches && "Reports"}
                            </Link>
                            {/* <a className={
                            `screen-content-side-links__link
                            ${utilitiesOpen && "screen-content-side-links__link--dropdown"}
                            `
                        }
                           onClick={() => setUtilitiesOpen(!utilitiesOpen)}
                        >
                            <MdApps size={20} color="white"/>
                            Utilities
                            {utilitiesOpen ?
                                <MdExpandLess className="screen-content-side-links__link--chevron" size={30}
                                              color="white"/>
                                :
                                <MdExpandMore className="screen-content-side-links__link--chevron" size={30}
                                              color="white"/>
                            }
                        </a>
                        {utilitiesOpen &&
                        <ul>
                            <Link className={
                                `screen-content-side-links__link 
                                    screen-content-side-links__link--sub 
                            ${active === "Messages" &&
                                "screen-content-side-links__link--active"}`
                            }
                                  to="/messages">
                                <MdMessage size={20} color="white"/>
                                Messages
                            </Link>
                            <Link className={
                                `screen-content-side-links__link 
                                    screen-content-side-links__link--sub 
                            ${active === "Tasks" &&
                                "screen-content-side-links__link--active"}`
                            }
                                  to="/tasks">
                                <MdAssignment size={20} color="white"/>
                                Tasks
                            </Link>
                        </ul>
                        } */}
                            {/* <Link className={
                            `screen-content-side-links__link 
                            ${active === "Account" &&
                            "screen-content-side-links__link--active"}`
                        }
                              to="/account/userid=47893256">
                            <MdPerson size={20} color="white"/>
                            Account
                        </Link> */}
                            {currentUser.currentUser.permission === "Level 1" &&
                                <Link className={
                                    `screen-content-side-links__link 
                            ${active === "Control panel" &&
                                    "screen-content-side-links__link--active"}`
                                }
                                    to="/control-panel">
                                    <MdExtension size={mediaQuery.matches ? 30 : 20} color="white" />
                                    {!mediaQuery.matches && "Control Panel"}
                                </Link>
                            }
                            {/* <Link className={
                            `screen-content-side-links__link 
                            ${active === "Settings" &&
                            "screen-content-side-links__link--active"}`
                        }
                              to="/settings">
                            <MdSettings size={20} color="white"/>
                            Settings
                        </Link> */}
                        </ul>
                    </div>
                }
                <div className={`
                screen-content-main
                ${active === 'Dashboard' && 'screen-content-main--padding'}
                ${(
                        (active === 'Dashboard' && mobileQuery.matches) ||
                        active === 'Sandbox' ||
                        active === 'Customers' ||
                        active === 'GoodsIn' ||
                        active === 'Order acknowledgement' ||
                        active === 'Order processing' ||
                        active === 'createBomDocument' ||
                        active === 'EngineeringBOM' ||
                        active === 'Control panel' ||
                        active === 'Purchase orders create' ||
                        active === 'Delivery note' ||
                        active === 'Purchase orders view' ||
                        active === 'Enquiries' ||
                        active === 'Reports' ||
                        active === 'Purchase orders')
                    && 'screen-content-main--scroll'
                    }
                `}
                    id="screen-container"
                >
                    {children}
                </div>
                <Toaster position="bottom-right" toastOptions={{ style: { fontWeight: "bold" } }} />
            </div>
        </div>
    )
}

export default Screen