import React, { useEffect, useState } from 'react';
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import firebase from "firebase";
import { PurchaseOrder } from '../Pages/Purchase Orders/PurchaseOrdersCreate';
interface BOMProps {
    data: { partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }[]
    bomRef: string
    explodedProp?: boolean
    hideButtons?: boolean
    so?: number
    purchase?: boolean
    deleteView?: boolean
    deleteLine?: (partNumber: string) => void
    topLevelQty?: number;
    onClick?: (part: { partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }) => void;
}

const BOM = ({ data, purchase = false, so, topLevelQty = 1, deleteLine, bomRef, explodedProp = false, hideButtons = false, onClick, deleteView = false }: BOMProps) => {
    const [exploded, setExploded] = useState<boolean>(explodedProp)
    const [boms, setBoms] = useState<{ partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }[]>()
    const [topLevelBoms, setTopLevelBoms] = useState<{ partNumber: string, partQuantity: number, sequence: number, level: number, subSequence: number, description?: string }[]>()
    const [purchases, setPurchases] = useState<{ partId: String, partQty: number, purchaseId: number }[]>([])

    console.log("boms2", boms)

    const groupBy = (arr: any, property: any) => {
        return arr.reduce(function (memo: any, x: any) {
            if (!memo[x[property]]) {
                memo[x[property]] = [];
            }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    useEffect(() => {
        const split = groupBy(data, "sequence")
        setBoms(split)
    }, [])

    useEffect(() => {
        const split = groupBy(data, "sequence")
        setBoms(split)
    }, [data])

    const getMatchingPurchaseOrders = async () => {
        const query = firebase.firestore().collection("purchaseOrders").where("soNumber", "==", so);
        return await query.get().then((querySnapshot) => {
            console.log("Request, Purchase Orders, get matching SO");
            let matchingOrders: any[] = [];

            querySnapshot.forEach((doc) => {
                if (doc.exists) {
                    // Add document data to the results array
                    matchingOrders.push({ id: doc.id, ...doc.data() });
                }
            });

            return matchingOrders; // Return the array of matching orders
        }).catch((error) => {
            console.error("Error getting documents: ", error);
        });
    };

    useEffect(() => {
        if (purchase && so) {
            getMatchingPurchaseOrders().then((items) => {
                //@ts-ignore
                const temp: PurchaseOrder[] = items
                const partSummary = temp.flatMap(order =>
                    order.parts.map(part => ({
                        partId: part.part.id,
                        partQty: part.quantity,
                        purchaseId: order.id
                    }))
                );
                setPurchases(partSummary)
            })
        }
        const split = groupBy(data, "sequence")
        const test = Object.keys(split).map((search) => {
            return groupBy(split[search], "subSequence")
        })
        const temp: any[] = []
        Object.keys(test).map((topArr) => {
            // @ts-ignore
            Object.keys(test[topArr]).map((value) => {
                // @ts-ignore
                temp.push(...test[topArr][value])
            })
        })

        setBoms(temp)

        const levels = groupBy(data, "level")
        Object.keys(levels).map((key) => {
            if (key === "1") {
                setTopLevelBoms(levels[key])
            }
        })
    }, [data])

    const findPO = (partId: string, partQty: number) => {
        return purchases.filter(a => a.partId === partId && a.partQty === partQty);
    };

    return (
        <div className="bom">
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Level</th>
                        <th>Seq.</th>
                        <th>Part number</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        {deleteView &&
                            <th>

                            </th>
                        }
                        {purchase &&
                            <th>Purchase Order</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {exploded && boms &&
                        Array.from(
                            new Map(
                                boms.map((item) =>
                                    [`${item.level}-${item.sequence}-${item.partNumber}`, item]
                                )
                            ).values()
                        ).map((item) => {
                            if (item.level === 2) {
                                return (
                                    <tr key={`${item.level}-${item.subSequence}-${item.partNumber}`} onClick={() => {
                                        // @ts-ignore
                                        onClick(item);
                                    }}>
                                        <td>{item.level}</td>
                                        <td>{item.subSequence + 1}.00</td>
                                        <td style={{ paddingLeft: `${item.level - 0.25}rem` }}>{item.partNumber}</td>
                                        <td>{item.description || ""}</td>
                                        <td>{item.partQuantity * topLevelQty}</td>
                                        {purchase &&
                                            <td>
                                                {(() => {
                                                    const po = findPO(item.partNumber, item.partQuantity * topLevelQty);
                                                    return po.length > 0 ? "PO" + po[0].purchaseId : "";
                                                })()}
                                            </td>
                                        }
                                    </tr>
                                );
                            }
                            return (
                                <tr key={`${item.level}-${item.sequence}-${item.partNumber}`} onClick={() => {
                                    // @ts-ignore
                                    onClick(item);
                                }}>
                                    <td>{item.level}</td>
                                    <td>{item.sequence + 1}.00</td>
                                    <td style={{ paddingLeft: `${item.level - 0.25}rem` }}>{item.partNumber}</td>
                                    <td>{item.description || ""}</td>
                                    <td>{item.partQuantity * topLevelQty}</td>
                                    {purchase &&
                                        <td>
                                            {(() => {
                                                const po = findPO(item.partNumber, item.partQuantity * topLevelQty);
                                                return po.length > 0 ? "PO" + po[0].purchaseId : "";
                                            })()}
                                        </td>
                                    }
                                </tr>
                            );
                        })
                    }

                    {!exploded && topLevelBoms &&
                        Array.from(
                            new Map(
                                topLevelBoms.map((item) =>
                                    [`${item.level}-${item.sequence}-${item.partNumber}`, item]
                                )
                            ).values()
                        ).map((item) => {
                            return (
                                <tr key={`${item.level}-${item.sequence}-${item.partNumber}`} onClick={() => {
                                    // @ts-ignore
                                    onClick(item);
                                }}>
                                    <td>{item.level}</td>
                                    <td>{item.sequence + 1}</td>
                                    <td style={{ paddingLeft: `${item.level - 0.25}rem` }}>{item.partNumber}</td>
                                    <td>{item.description || ""}</td>
                                    <td>{item.partQuantity * topLevelQty}</td>
                                    {deleteView && deleteLine &&
                                        <td><MdDelete size={20} onClick={() => deleteLine(item.partNumber)} /></td>
                                    }
                                </tr>
                            );
                        })
                    }
                </tbody>
            </Table>
            {!hideButtons &&
                <div className="d-flex align-items-center justify-content-end mt-3">
                    <Button onClick={() => setExploded(!exploded)}
                        className="button-green w-auto">{exploded ? "Retract BOM" : `Explode BOM`}</Button>
                    <Link to={{
                        pathname: '/create-bill-of-materials',
                        state: { bomState: boms, bomRef: bomRef }
                    }} className="button-green w-auto ml-2">Create report</Link>
                    <Link to={{
                        pathname: '/create-bill-of-materials/indented-parts-list',
                        state: { bomState: boms, bomRef: bomRef, print: "true" }
                    }} className="button-green w-auto ml-2">Print report</Link>
                </div>
            }
        </div>
    )
}

export default BOM