import React, { useEffect, useReducer, useState } from 'react';
import Screen from "../Components/Screen";
import { Accordion, Button, Card, Col, Form, Modal, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { MdAdd, MdExtension, MdRefresh, MdRemove } from "react-icons/md";
import { Field, Formik } from "formik";
import ThercoDropdown from "../Components/ThercoDropdown";
import firebase from "firebase";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-hot-toast";

export interface SupplierContact {
    id: string,
    name: string,
    email: string,
    position: string,
    directPhone: string,
    mobilePhone: string
}

export interface Supplier {
    id: string;
    name: string;
    addressOne: string;
    addressTwo: string;
    addressThree: string;
    townCity: string;
    postCode: string;
    switchboard?: number;
    website: string;
    contacts?: SupplierContact[]
}

export interface Customer {
    id: string,
    name: string,
    switchboard: number,
    addressOne: string,
    addressTwo: string,
    addressThree: string,
    townCity: string,
    postCode: string,

    deliveryAddressOne: string,
    deliveryAddressTwo: string,
    deliveryAddressThree: string,
    deliveryTownCity: string,
    deliveryPostCode: string,

    invoiceAddressOne: string,
    invoiceAddressTwo: string,
    invoiceAddressThree: string,
    invoiceTownCity: string,
    invoicePostCode: string,

    contacts?: SupplierContact[]
}

interface dropdown {
    productCategory: string[]
    materialGrade: string[]
    materialGroup: string[]
    finish: string[]
    qaRequirement: string[]
    nominalCodes: string[]
    thermalDesign: string[]
    mechanicalDesign: string[]
    draughting: string[]
    stockLocation: string[]
}

const ControlPanel = () => {

    const [dropdowns, setDropdowns] = useState<dropdown>({
        draughting: [],
        finish: [],
        materialGrade: [],
        materialGroup: [],
        mechanicalDesign: [],
        nominalCodes: [],
        productCategory: [],
        qaRequirement: [],
        thermalDesign: [],
        stockLocation: [],
    })

    const [newDropdowns, setNewDropdowns] = useState<dropdown>({
        draughting: [],
        finish: [],
        materialGrade: [],
        materialGroup: [],
        mechanicalDesign: [],
        nominalCodes: [],
        productCategory: [],
        qaRequirement: [],
        thermalDesign: [],
        stockLocation: [],
    })

    const [allSuppliers, setAllSuppliers] = useState<Supplier[]>()
    const [allDataSuppliers, setAllDataSuppliers] = useState<any>()
    const [supplierAcitveKey, setSupplierActiveKey] = useState<number>()
    const [supplierContactAcitveKey, setSupplierContactActiveKey] = useState<number>()
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier>()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [allCustomers, setAllCustomers] = useState<Customer[]>()
    const [allDataCustomers, setAllDataCustomers] = useState<any>()
    const [customerAcitveKey, setCustomerActiveKey] = useState<number>()
    const [customerContactAcitveKey, setCustomerContactActiveKey] = useState<number>()
    const [selectedCustomer, setSelectedCustomer] = useState<Customer>()
    const [customerShow, setCustomerShow] = useState(false);

    const [editShow, setEditShow] = useState(false);


    const [acitveKey, setActiveKey] = useState<string>()
    const [sectionTwo, setSectionTwo] = useState<string[]>([])
    const [sectionFive, setSectionFive] = useState<string[]>([])
    const [sectionSix, setSectionSix] = useState<string[]>([])
    const [sectionSeven, setSectionSeven] = useState<string[]>([])
    const [sectionEight, setSectionEight] = useState<string[]>([])
    const [sectionNine, setSectionNine] = useState<string[]>([])
    const [sectionTen, setSectionTen] = useState<string[]>([])

    const [sectionTwoText, setSectionTwoText] = useState<string>("")
    const [sectionFiveText, setSectionFiveText] = useState<string>("")
    const [sectionSixText, setSectionSixText] = useState<string>("")
    const [sectionSevenText, setSectionSevenText] = useState<string>("")
    const [sectionEightText, setSectionEightText] = useState<string>("")
    const [sectionNineText, setSectionNineText] = useState<string>("")
    const [sectionTenText, setSectionTenText] = useState<string>("")
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [loading, setLoading] = useState<boolean>(true)


    const handleCustomerClose = () => setCustomerShow(false);
    const handleCustomerShow = () => setCustomerShow(true);

    const handleEditClose = () => setEditShow(false);
    const handleEditShow = () => setEditShow(true);

    const addCustomerContact = async (contactValues: {
        name: string,
        email: string,
        position: string,
        directPhone: string,
        mobilePhone: string
    }) => {
        const query = firebase.firestore().collection("customers").doc(selectedCustomer?.id)
        await query.get().then((doc) => {
            // @ts-ignore
            if (doc.data().contacts && doc.exists) {
                // @ts-ignore
                const arr = doc.data().contacts
                const num = Math.max.apply(Math, arr.map(function (o: any) {
                    return o.id;
                }))
                query.update(
                    {
                        contacts: firebase.firestore.FieldValue.arrayUnion({ id: (num + 1), ...contactValues })
                    }
                )
            } else {
                const temp = doc.data()
                query.set({ contacts: [{ id: 1, ...contactValues }], ...temp })
            }
        }).then(() => {
            toast.success("Customer contact successfully added!")
            getCustomers()
        })
    }

    const getMoreCustomers = async () => {
        if (allCustomers) {
            let temp: Customer[] = []
            const lastVisible = allDataCustomers && allDataCustomers.docs[allDataCustomers.docs.length - 1]
            const query = firebase.firestore().collection('customers')
                .orderBy('id', "desc")
                .startAfter(lastVisible)
                .limit(30)

            const customerDocs = await query.get()
            customerDocs.docs.map((doc) => {
                // @ts-ignore
                temp.push(doc.data())
            })
            setAllCustomers([...allCustomers, ...temp])
            setAllDataCustomers(customerDocs)
        }
    }

    const getCustomers = async () => {
        let temp: Customer[] = []
        const query = firebase.firestore().collection('customers')
            .orderBy('id', "desc")
            .limit(30)

        const customers = await query.get()
        customers.docs.map((doc) => {
            // @ts-ignore
            temp.push(doc.data())
        })
        setAllCustomers(temp)
        setAllDataCustomers(customers)
    }

    const addCustomer = async (customer: any) => {
        const letters = customer.customerName.replace(/\s/g, '').substring(0, 3);
        const query = firebase.firestore().collection("customers").doc(`${letters}001`)
        await query.get().then((doc) => {
            query.set({ id: `${letters}001`, name: customer.customerName, ...customer })
        }).then(() => {
            toast.success("Customer successfully added!")
            getCustomers()
        })
    }

    const editCustomer = async (customer: any) => {
        const letters = customer.customerName.replace(/\s/g, '').substring(0, 3);
        const query = firebase.firestore().collection("customers").doc(`${letters}001`)
        await query.get().then((doc) => {
            query.set({ id: `${letters}001`, name: customer.customerName, ...customer })
        }).then(() => {
            toast.success("Customer successfully edited!")
            getCustomers()
        })
    }


    const addSupplier = async (supplier: any) => {
        const query = firebase.firestore().collection("suppliers").doc(supplier.supplierNumber)
        await query.get().then((doc) => {
            query.set({ id: supplier.supplierNumber, name: supplier.supplierName, ...supplier })
        }).then(() => {
            toast.success("Supplier successfully added!")
            getSuppliers()
        })
    }

    const editSupplier = async (supplier: any) => {
        const query = firebase.firestore().collection("suppliers").doc(supplier.supplierNumber)
        await query.get().then((doc) => {
            query.set({ id: supplier.supplierNumber, name: supplier.supplierName, ...supplier })
        }).then(() => {
            toast.success("Supplier successfully edited!")
            getSuppliers()
        })
    }

    const addSupplierContact = async (contactValues: {
        name: string,
        email: string,
        position: string,
        directPhone: string,
        mobilePhone: string
    }) => {
        const query = firebase.firestore().collection("suppliers").doc(selectedSupplier?.id)
        await query.get().then((doc) => {
            // @ts-ignore
            if (doc.data().contacts && doc.exists) {
                // @ts-ignore
                const arr = doc.data().contacts
                const num = Math.max.apply(Math, arr.map(function (o: any) {
                    return o.id;
                }))
                query.update(
                    {
                        contacts: firebase.firestore.FieldValue.arrayUnion({ id: (num + 1), ...contactValues })
                    }
                )
            } else {
                const temp = doc.data()
                query.set({ contacts: [{ id: 1, ...contactValues }], ...temp })
            }
        }).then(() => {
            toast.success("Supplier contact successfully added!")
            getSuppliers()
        })
    }

    const addNewDropdown = async (obj: any, docText: string) => {
        console.log(obj)
        const query = firebase.firestore().collection("dropdowns").doc(docText)
        await query.get().then((doc) => {
            if (doc.exists) {
                query.update(
                    {
                        values: firebase.firestore.FieldValue.arrayUnion(obj[docText])
                    }
                )
            } else {
                query.set({ values: [obj[docText]] })
            }
        }).then(() => {
            toast.success(`Dropdown values updated successfully!`)
            getDropdowns()
            forceUpdate()

        })
    }

    const deleteItem = async (docText: string, delVal: string) => {
        //@ts-ignore
        const dropdown = dropdowns[docText]
        const newDd: string[] = []
        //@ts-ignore
        dropdown.map((item) => {
            if (item !== delVal) {
                newDd.push(item)
            }
        })
        firebase.firestore().collection("dropdowns").doc(docText).set({
            values: newDd
        })
            .then(() => {
                toast.success(`Dropdown value deleted successfully!`)
                const temp = dropdowns
                //@ts-ignore
                temp[docText] = newDd
                setDropdowns(temp)
                forceUpdate()
            })

    }

    const editItem = async (docText: string, editVal: string, original: string) => {
        //@ts-ignore
        const dropdown = dropdowns[docText]
        const newDd: string[] = []
        //@ts-ignore
        dropdown.map((item) => {
            if (item === original) {
                newDd.push(editVal)
            } else {
                newDd.push(item)
            }
        })
        firebase.firestore().collection("dropdowns").doc(docText).set({
            values: newDd
        })
            .then(() => {
                toast.success(`Dropdown value updated successfully!`)
                const temp = dropdowns
                //@ts-ignore
                temp[docText] = newDd
                setDropdowns(temp)
                forceUpdate()
            })

    }

    const getDropdowns = async () => {
        await firebase.firestore().collection("dropdowns").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                console.log(doc.id)
                if (doc.id === "sections") {
                    Object.keys(doc.data().values).map((value) => {
                        switch (value) {
                            case 'sectionTwo':
                                setSectionTwo(doc.data().values[value])
                                break;
                            case 'sectionFive':
                                setSectionFive(doc.data().values[value])
                                break;
                            case 'sectionSix':
                                setSectionSix(doc.data().values[value])
                                break;
                            case 'sectionSeven':
                                setSectionSeven(doc.data().values[value])
                                break;
                            case 'sectionEight':
                                setSectionEight(doc.data().values[value])
                                break;
                            case 'sectionNine':
                                setSectionNine(doc.data().values[value])
                                break;
                            case 'sectionTen':
                                setSectionTen(doc.data().values[value])
                                break;
                            default:
                                console.log("none")
                                break;
                        }
                    })
                }

                const temp: dropdown = dropdowns
                // @ts-ignore
                temp[doc.id] = doc.data().values
                setDropdowns(temp)
            });
        });
    }

    const getSuppliers = async () => {
        let temp: Supplier[] = []
        const query = firebase.firestore().collection('suppliers')
            .orderBy('id', "desc")
            .limit(30)

        const suppliers = await query.get()
        suppliers.docs.map((doc) => {
            // @ts-ignore
            temp.push(doc.data())
        })
        setAllSuppliers(temp)
        setAllDataSuppliers(suppliers)
    }

    const getMoreSuppliers = async () => {
        if (allSuppliers) {
            let temp: Supplier[] = []
            const lastVisible = allDataSuppliers && allDataSuppliers.docs[allDataSuppliers.docs.length - 1]
            const query = firebase.firestore().collection('suppliers')
                .orderBy('id', "desc")
                .startAfter(lastVisible)
                .limit(30)

            const supplierDocs = await query.get()
            supplierDocs.docs.map((doc) => {
                // @ts-ignore
                temp.push(doc.data())
            })
            setAllSuppliers([...allSuppliers, ...temp])
            setAllDataSuppliers(supplierDocs)
        }
    }

    const handleSectionUpdate = async () => {
        const query = firebase.firestore().collection("dropdowns").doc("sections")
        await query.set({
            values: {
                sectionTwo,
                sectionFive,
                sectionSix,
                sectionSeven,
                sectionEight,
                sectionNine,
                sectionTen,
            }
        })
    }

    useEffect(() => {
        if (!loading) {
            handleSectionUpdate()
        }
    }, [
        sectionTwo,
        sectionFive,
        sectionSix,
        sectionSeven,
        sectionEight,
        sectionNine,
        sectionTen,
    ])

    useEffect(() => {
        getDropdowns().then(() => setLoading(false))
        getSuppliers()
        getCustomers()
    }, [])

    useEffect(() => {
        setNewDropdowns(dropdowns)
    }, [dropdowns])

    const [editText, setEditText] = useState<string>("")
    const [originalEditText, setOriginalEditText] = useState<string>("")
    const [editType, setEditType] = useState<string>("")

    const [selectedEditCustomer, setSelectedEditCustomer] = useState<Customer>()
    const [editCustomerShow, setEditCustomerShow] = useState<boolean>(false)
    const handleEditCustomerClose = () => setEditCustomerShow(false);
    const handleEditCustomerShow = () => setEditCustomerShow(true);

    const [selectedEditSupplier, setSelectedEditSupplier] = useState<Supplier>()
    const [editSupplierShow, setEditSupplierShow] = useState<boolean>(false)
    const handleEditSupplierClose = () => setEditSupplierShow(false);
    const handleEditSupplierShow = () => setEditSupplierShow(true);
    return (
        <Screen active="Control panel">
            <Modal size='xl' show={editSupplierShow} onHide={handleEditSupplierClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedEditSupplier?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            supplierNumber: selectedEditSupplier?.id,
                            supplierName: selectedEditSupplier?.name,
                            switchboard: selectedEditSupplier?.switchboard,
                            website: selectedEditSupplier?.website,
                            addressOne: selectedEditSupplier?.addressOne,
                            addressTwo: selectedEditSupplier?.addressTwo,
                            addressThree: selectedEditSupplier?.addressThree,
                            townCity: selectedEditSupplier?.townCity,
                            postCode: selectedEditSupplier?.postCode,
                        }}
                        onSubmit={values => editSupplier(values)}
                    >
                        {({
                            handleSubmit,
                            handleChange
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Supplier
                                                number:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"supplierNumber"}
                                                onChange={handleChange}
                                                placeholder="Supplier number..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Supplier
                                                name:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"supplierName"}
                                                onChange={handleChange}
                                                placeholder="Supplier name..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label
                                                style={{ fontWeight: "bold" }}>Switchboard:</Form.Label>
                                            <Field
                                                className="form-control" type="Number"
                                                name={"switchboard"}
                                                onChange={handleChange}
                                                placeholder="Switchboard..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label
                                                style={{ fontWeight: "bold" }}>Website:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"website"}
                                                onChange={handleChange}
                                                placeholder="Website..." />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Address
                                                line one:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"addressOne"}
                                                onChange={handleChange}
                                                placeholder="Address line one..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Address
                                                line two:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"addressTwo"}
                                                onChange={handleChange}
                                                placeholder="Address line two..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Address
                                                line three:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"addressThree"}
                                                onChange={handleChange}
                                                placeholder="Address line three..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label
                                                style={{ fontWeight: "bold" }}>Town/City:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"townCity"}
                                                onChange={handleChange}
                                                placeholder="Town/City..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Post
                                                code:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"postCode"}
                                                onChange={handleChange}
                                                placeholder="Post code..." />
                                        </Form.Group>
                                        <div className="d-flex justify-content-end">

                                            <Button type="submit"
                                                className="button-green w-auto">Add
                                                supplier</Button>
                                        </div>
                                    </Col>
                                </Form.Row>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            <Modal size='xl' show={editCustomerShow} onHide={handleEditCustomerClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedEditCustomer?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            customerNumber: selectedEditCustomer?.id,
                            customerName: selectedEditCustomer?.name,
                            switchboard: selectedEditCustomer?.switchboard,
                            addressOne: selectedEditCustomer?.addressOne,
                            addressTwo: selectedEditCustomer?.addressTwo,
                            addressThree: selectedEditCustomer?.addressThree,
                            townCity: selectedEditCustomer?.townCity,
                            postCode: selectedEditCustomer?.postCode,

                            deliveryAddressOne: selectedEditCustomer?.deliveryAddressOne,
                            deliveryAddressTwo: selectedEditCustomer?.deliveryAddressTwo,
                            deliveryAddressThree: selectedEditCustomer?.deliveryAddressThree,
                            deliveryTownCity: selectedEditCustomer?.deliveryTownCity,
                            deliveryPostCode: selectedEditCustomer?.deliveryPostCode,

                            invoiceAddressOne: selectedEditCustomer?.invoiceAddressOne,
                            invoiceAddressTwo: selectedEditCustomer?.invoiceAddressTwo,
                            invoiceAddressThree: selectedEditCustomer?.invoiceAddressThree,
                            invoiceTownCity: selectedEditCustomer?.invoiceTownCity,
                            invoicePostCode: selectedEditCustomer?.invoicePostCode,
                        }}
                        onSubmit={values => editCustomer(values)}
                    >
                        {({
                            handleSubmit,
                            handleChange
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label
                                                style={{ fontWeight: "bold" }}>Customer
                                                number:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"customerNumber"}
                                                onChange={handleChange}
                                                placeholder="Customer number..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label
                                                style={{ fontWeight: "bold" }}>Customer
                                                name:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"customerName"}
                                                onChange={handleChange}
                                                placeholder="Customer name..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label
                                                style={{ fontWeight: "bold" }}>Switchboard:</Form.Label>
                                            <Field
                                                className="form-control" type="Number"
                                                name={"switchboard"}
                                                onChange={handleChange}
                                                placeholder="Switchboard..." />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label
                                                style={{ fontWeight: "bold" }}>Main
                                                address
                                                line one:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"addressOne"}
                                                onChange={handleChange}
                                                placeholder="Address line one..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label
                                                style={{ fontWeight: "bold" }}>Main
                                                address
                                                line two:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"addressTwo"}
                                                onChange={handleChange}
                                                placeholder="Address line two..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label
                                                style={{ fontWeight: "bold" }}>Main
                                                address
                                                line three:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"addressThree"}
                                                onChange={handleChange}
                                                placeholder="Address line three..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label
                                                style={{ fontWeight: "bold" }}>Town/City:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"townCity"}
                                                onChange={handleChange}
                                                placeholder="Town/City..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label
                                                style={{ fontWeight: "bold" }}>Post
                                                code:</Form.Label>
                                            <Field
                                                className="form-control"
                                                name={"postCode"}
                                                onChange={handleChange}
                                                placeholder="Post code..." />
                                        </Form.Group>
                                        <ThercoDropdown className="mb-2"
                                            title="Delivery address">
                                            <Form.Group>
                                                <Form.Label
                                                    style={{ fontWeight: "bold" }}>Delivery
                                                    address
                                                    line one:</Form.Label>
                                                <Field
                                                    className="form-control"
                                                    name={"deliveryAddressOne"}
                                                    onChange={handleChange}
                                                    placeholder="Address line one..." />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label
                                                    style={{ fontWeight: "bold" }}>Delivery
                                                    address
                                                    line two:</Form.Label>
                                                <Field
                                                    className="form-control"
                                                    name={"deliveryAddressTwo"}
                                                    onChange={handleChange}
                                                    placeholder="Address line two..." />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label
                                                    style={{ fontWeight: "bold" }}>Delivery
                                                    address
                                                    line three:</Form.Label>
                                                <Field
                                                    className="form-control"
                                                    name={"deliveryAddressThree"}
                                                    onChange={handleChange}
                                                    placeholder="Address line three..." />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label
                                                    style={{ fontWeight: "bold" }}>Town/City:</Form.Label>
                                                <Field
                                                    className="form-control"
                                                    name={"deliveryTownCity"}
                                                    onChange={handleChange}
                                                    placeholder="Town/City..." />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label
                                                    style={{ fontWeight: "bold" }}>Post
                                                    code:</Form.Label>
                                                <Field
                                                    className="form-control"
                                                    name={"deliveryPostCode"}
                                                    onChange={handleChange}
                                                    placeholder="Post code..." />
                                            </Form.Group>
                                        </ThercoDropdown>
                                        <ThercoDropdown title="Invoice address">
                                            <Form.Group>
                                                <Form.Label
                                                    style={{ fontWeight: "bold" }}>Invoice
                                                    address
                                                    line one:</Form.Label>
                                                <Field
                                                    className="form-control"
                                                    name={"invoiceAddressOne"}
                                                    onChange={handleChange}
                                                    placeholder="Address line one..." />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label
                                                    style={{ fontWeight: "bold" }}>Invoice
                                                    address
                                                    line two:</Form.Label>
                                                <Field
                                                    className="form-control"
                                                    name={"invoiceAddressTwo"}
                                                    onChange={handleChange}
                                                    placeholder="Address line two..." />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label
                                                    style={{ fontWeight: "bold" }}>Invoice
                                                    address
                                                    line three:</Form.Label>
                                                <Field
                                                    className="form-control"
                                                    name={"invoiceAddressThree"}
                                                    onChange={handleChange}
                                                    placeholder="Address line three..." />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label
                                                    style={{ fontWeight: "bold" }}>Town/City:</Form.Label>
                                                <Field
                                                    className="form-control"
                                                    name={"invoiceTownCity"}
                                                    onChange={handleChange}
                                                    placeholder="Town/City..." />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label
                                                    style={{ fontWeight: "bold" }}>Post
                                                    code:</Form.Label>
                                                <Field
                                                    className="form-control"
                                                    name={"invoicePostCode"}
                                                    onChange={handleChange}
                                                    placeholder="Post code..." />
                                            </Form.Group>
                                        </ThercoDropdown>
                                        <div
                                            className="d-flex justify-content-end mt-4">
                                            <Button type="submit"
                                                className="button-green w-auto">Edit
                                                customer</Button>
                                        </div>
                                    </Col>
                                </Form.Row>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            <Modal show={editShow} onHide={handleEditClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit {originalEditText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <Form.Label style={{ fontWeight: "bold" }}>Item:</Form.Label>
                                <Form.Control
                                    value={editText}
                                    onChange={(e) => setEditText(e.currentTarget.value)}
                                    placeholder="Edit this item..." />
                            </Form.Group>
                            <div
                                className="d-flex justify-content-end mt-4">
                                <Button onClick={() => deleteItem(editType, originalEditText)}
                                    className="button-green w-auto">Delete</Button>
                                <Button type="submit"
                                    className="button-green w-auto" onClick={() => {
                                        editItem(editType, editText, originalEditText)
                                        handleEditClose()
                                    }}>Save</Button>
                            </div>
                        </Col>
                    </Form.Row>
                </Modal.Body>
            </Modal>
            <Modal show={customerShow} onHide={handleCustomerClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add contact for {selectedCustomer?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                            position: "",
                            directPhone: "",
                            mobilePhone: "",
                        }}
                        onSubmit={values => {
                            addCustomerContact(values)
                            handleClose()
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Name</Form.Label>
                                            <Form.Control
                                                name={"name"}
                                                onChange={handleChange}
                                                placeholder="Name..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Email</Form.Label>
                                            <Form.Control
                                                name={"email"}
                                                onChange={handleChange}
                                                placeholder="Email..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Position</Form.Label>
                                            <Form.Control
                                                name={"position"}
                                                onChange={handleChange}
                                                placeholder="Position..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Direct phone</Form.Label>
                                            <Form.Control
                                                name={"directPhone"}
                                                onChange={handleChange}
                                                placeholder="Direct phone..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Mobile phone</Form.Label>
                                            <Form.Control
                                                name={"mobilePhone"}
                                                onChange={handleChange}
                                                placeholder="Mobile phone..." />
                                        </Form.Group>
                                        <div
                                            className="d-flex justify-content-end mt-4">
                                            <Button type="submit"
                                                className="button-green w-auto">Add Contact</Button>
                                        </div>
                                    </Col>
                                </Form.Row>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add contact for {selectedSupplier?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                            position: "",
                            directPhone: "",
                            mobilePhone: "",
                        }}
                        onSubmit={values => {
                            addSupplierContact(values)
                            handleClose()
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Name</Form.Label>
                                            <Form.Control
                                                name={"name"}
                                                onChange={handleChange}
                                                placeholder="Name..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Email</Form.Label>
                                            <Form.Control
                                                name={"email"}
                                                onChange={handleChange}
                                                placeholder="Email..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Position</Form.Label>
                                            <Form.Control
                                                name={"position"}
                                                onChange={handleChange}
                                                placeholder="Position..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Direct phone</Form.Label>
                                            <Form.Control
                                                name={"directPhone"}
                                                onChange={handleChange}
                                                placeholder="Direct phone..." />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: "bold" }}>Mobile phone</Form.Label>
                                            <Form.Control
                                                name={"mobilePhone"}
                                                onChange={handleChange}
                                                placeholder="Mobile phone..." />
                                        </Form.Group>
                                        <div
                                            className="d-flex justify-content-end mt-4">
                                            <Button type="submit"
                                                className="button-green w-auto">Add Contact</Button>
                                        </div>
                                    </Col>
                                </Form.Row>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            <Card>
                <Card.Header className="purchaseOrders-header d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-row align-items-center">
                        <MdExtension style={{ marginRight: ".3rem" }} size={18} color="#131313" />
                        <p className="font-weight-bold">Control panel</p>
                    </div>
                    <div className="d-flex flex-row align-items-center purchaseOrders-header-controls">
                        <a onClick={() => {

                        }}>
                            <MdRefresh size={20} color="#131313" />
                            {` Reload data`}
                        </a>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Tabs defaultActiveKey="dropdown" id="uncontrolled-tab-example">
                        <Tab eventKey="dropdown" title="Dropdown configuration">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="suppliers">
                                <div className="tab-border">
                                    <Row className="p-4">
                                        <Col sm={3}>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="customers">Customers</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="draughting">Draughting</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="finish">Finish</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="materialGrade">Material grade</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="materialGroup">Material group</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="mechanicalDesign">Mechanical design</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="nominalCodes">Nominal codes</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="productCategory">Product category</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="suppliers">Suppliers</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="stockLocation">Stock locations</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="thermalDesign">Thermal design</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="qaRequirement">QA requirement</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="quotationSections">Quotation sections</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={9}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="quotationSections">
                                                    <Accordion>
                                                        <Card>
                                                            <Accordion.Toggle
                                                                onClick={() => {
                                                                    if (acitveKey === "2.0") {
                                                                        setActiveKey(undefined)
                                                                    } else {
                                                                        setActiveKey("2.0")
                                                                    }
                                                                }}
                                                                className="purchaseOrders-body-accordion-header"
                                                                as={Card.Header} eventKey={"2.0"}>
                                                                SECTION 2.0 - SCOPE OF SUPPLY
                                                                {acitveKey === "2.0" ? <MdRemove size={20} /> :
                                                                    <MdAdd size={20} />}
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={"2.0"}>
                                                                <Card.Body>
                                                                    <div className="d-flex flex-column">
                                                                        {sectionTwo.map((value, index, array) => <p className="mb-2">{value}</p>)}
                                                                    </div>
                                                                    <Form.Control as="textarea" rows={3}
                                                                        className="mt-2"
                                                                        onChange={(event) => setSectionTwoText(event.target.value)}
                                                                        placeholder="Enter section note..."
                                                                        value={sectionTwoText}
                                                                    />
                                                                    <div className="d-flex justify-content-end mt-3">
                                                                        <Button
                                                                            onClick={() => setSectionTwo([...sectionTwo, sectionTwoText])}
                                                                            className="button-green w-auto">
                                                                            Add note
                                                                        </Button>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>

                                                        <Card>
                                                            <Accordion.Toggle
                                                                onClick={() => {
                                                                    if (acitveKey === "5.0") {
                                                                        setActiveKey(undefined)
                                                                    } else {
                                                                        setActiveKey("5.0")
                                                                    }
                                                                }}
                                                                className="purchaseOrders-body-accordion-header"
                                                                as={Card.Header} eventKey={"5.0"}>
                                                                SECTION 5.0 - DESIGN, MATERIALS OF CONSTRUCTION & FINISH
                                                                {acitveKey === "5.0" ? <MdRemove size={20} /> :
                                                                    <MdAdd size={20} />}
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={"5.0"}>
                                                                <Card.Body>
                                                                    <div className="d-flex flex-column">
                                                                        {sectionFive.map((value, index, array) => <p className="mb-2">{value}</p>)}
                                                                    </div>
                                                                    <Form.Control as="textarea" rows={3}
                                                                        className="mt-2"
                                                                        onChange={(event) => setSectionFiveText(event.target.value)}
                                                                        placeholder="Enter section note..."
                                                                        value={sectionFiveText}
                                                                    />
                                                                    <div className="d-flex justify-content-end mt-3">
                                                                        <Button
                                                                            onClick={() => setSectionFive([...sectionFive, sectionFiveText])}
                                                                            className="button-green w-auto">
                                                                            Add note
                                                                        </Button>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>

                                                        <Card>
                                                            <Accordion.Toggle
                                                                onClick={() => {
                                                                    if (acitveKey === "6.0") {
                                                                        setActiveKey(undefined)
                                                                    } else {
                                                                        setActiveKey("6.0")
                                                                    }
                                                                }}
                                                                className="purchaseOrders-body-accordion-header"
                                                                as={Card.Header} eventKey={"6.0"}>
                                                                SECTION 6.0 - HSEQ, INSPECTION & TESTING
                                                                {acitveKey === "6.0" ? <MdRemove size={20} /> :
                                                                    <MdAdd size={20} />}
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={"6.0"}>
                                                                <Card.Body>
                                                                    <div className="d-flex flex-column">
                                                                        {sectionSix.map((value, index, array) => <p className="mb-2">{value}</p>)}
                                                                    </div>
                                                                    <Form.Control as="textarea" rows={3}
                                                                        className="mt-2"
                                                                        onChange={(event) => setSectionSixText(event.target.value)}
                                                                        placeholder="Enter section note..."
                                                                        value={sectionSixText}
                                                                    />
                                                                    <div className="d-flex justify-content-end mt-3">
                                                                        <Button
                                                                            onClick={() => setSectionSix([...sectionSix, sectionSixText])}
                                                                            className="button-green w-auto">
                                                                            Add note
                                                                        </Button>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>

                                                        <Card>
                                                            <Accordion.Toggle
                                                                onClick={() => {
                                                                    if (acitveKey === "7.0") {
                                                                        setActiveKey(undefined)
                                                                    } else {
                                                                        setActiveKey("7.0")
                                                                    }
                                                                }}
                                                                className="purchaseOrders-body-accordion-header"
                                                                as={Card.Header} eventKey={"7.0"}>
                                                                SECTION 7.0 - PREPARATION FOR SHIPMENT
                                                                {acitveKey === "7.0" ? <MdRemove size={20} /> :
                                                                    <MdAdd size={20} />}
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={"7.0"}>
                                                                <Card.Body>
                                                                    <div className="d-flex flex-column">
                                                                        {sectionSeven.map((value, index, array) => <p className="mb-2">{value}</p>)}
                                                                    </div>
                                                                    <Form.Control as="textarea" rows={3}
                                                                        className="mt-2"
                                                                        onChange={(event) => setSectionSevenText(event.target.value)}
                                                                        placeholder="Enter section note..."
                                                                        value={sectionSevenText}
                                                                    />
                                                                    <div className="d-flex justify-content-end mt-3">
                                                                        <Button
                                                                            onClick={() => setSectionSeven([...sectionSeven, sectionSevenText])}
                                                                            className="button-green w-auto">
                                                                            Add note
                                                                        </Button>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>

                                                        <Card>
                                                            <Accordion.Toggle
                                                                onClick={() => {
                                                                    if (acitveKey === "8.0") {
                                                                        setActiveKey(undefined)
                                                                    } else {
                                                                        setActiveKey("8.0")
                                                                    }
                                                                }}
                                                                className="purchaseOrders-body-accordion-header"
                                                                as={Card.Header} eventKey={"8.0"}>
                                                                SECTION 8.0 - GUARANTEE
                                                                {acitveKey === "8.0" ? <MdRemove size={20} /> :
                                                                    <MdAdd size={20} />}
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={"8.0"}>
                                                                <Card.Body>
                                                                    <div className="d-flex flex-column">
                                                                        {sectionEight.map((value, index, array) => <p className="mb-2">{value}</p>)}
                                                                    </div>
                                                                    <Form.Control as="textarea" rows={3}
                                                                        className="mt-2"
                                                                        onChange={(event) => setSectionEightText(event.target.value)}
                                                                        placeholder="Enter section note..."
                                                                        value={sectionEightText}
                                                                    />
                                                                    <div className="d-flex justify-content-end mt-3">
                                                                        <Button
                                                                            onClick={() => setSectionEight([...sectionEight, sectionEightText])}
                                                                            className="button-green w-auto">
                                                                            Add note
                                                                        </Button>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>

                                                        <Card>
                                                            <Accordion.Toggle
                                                                onClick={() => {
                                                                    if (acitveKey === "9.0") {
                                                                        setActiveKey(undefined)
                                                                    } else {
                                                                        setActiveKey("9.0")
                                                                    }
                                                                }}
                                                                className="purchaseOrders-body-accordion-header"
                                                                as={Card.Header} eventKey={"9.0"}>
                                                                SECTION 9.0 - TERMS & CONDITIONS
                                                                {acitveKey === "9.0" ? <MdRemove size={20} /> :
                                                                    <MdAdd size={20} />}
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={"9.0"}>
                                                                <Card.Body>
                                                                    <div className="d-flex flex-column">
                                                                        {sectionNine.map((value, index, array) => <p className="mb-2">{value}</p>)}
                                                                    </div>
                                                                    <Form.Control as="textarea" rows={3}
                                                                        className="mt-2"
                                                                        onChange={(event) => setSectionNineText(event.target.value)}
                                                                        placeholder="Enter section note..."
                                                                        value={sectionNineText}
                                                                    />
                                                                    <div className="d-flex justify-content-end mt-3">
                                                                        <Button
                                                                            onClick={() => setSectionNine([...sectionNine, sectionNineText])}
                                                                            className="button-green w-auto">
                                                                            Add note
                                                                        </Button>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>

                                                        <Card>
                                                            <Accordion.Toggle
                                                                onClick={() => {
                                                                    if (acitveKey === "10.0") {
                                                                        setActiveKey(undefined)
                                                                    } else {
                                                                        setActiveKey("10.0")
                                                                    }
                                                                }}
                                                                className="purchaseOrders-body-accordion-header"
                                                                as={Card.Header} eventKey={"10.0"}>
                                                                SECTION 10.0 - EXCLUSIONS
                                                                {acitveKey === "10.0" ? <MdRemove size={20} /> :
                                                                    <MdAdd size={20} />}
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={"10.0"}>
                                                                <Card.Body>
                                                                    <div className="d-flex flex-column">
                                                                        {sectionTen.map((value, index, array) => <p className="mb-2">{value}</p>)}
                                                                    </div>
                                                                    <Form.Control as="textarea" rows={3}
                                                                        className="mt-2"
                                                                        onChange={(event) => setSectionTenText(event.target.value)}
                                                                        placeholder="Enter section note..."
                                                                        value={sectionTenText}
                                                                    />
                                                                    <div className="d-flex justify-content-end mt-3">
                                                                        <Button
                                                                            onClick={() => setSectionTen([...sectionTen, sectionTenText])}
                                                                            className="button-green w-auto">
                                                                            Add note
                                                                        </Button>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="suppliers">
                                                    <Formik
                                                        initialValues={{
                                                            supplierNumber: "",
                                                            supplierName: "",
                                                            switchboard: "",
                                                            website: "",
                                                            addressOne: "",
                                                            addressTwo: "",
                                                            addressThree: "",
                                                            townCity: "",
                                                            postCode: "",
                                                        }}
                                                        onSubmit={values => addSupplier(values)}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange
                                                        }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <Form.Row>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label style={{ fontWeight: "bold" }}>Supplier
                                                                                number:</Form.Label>
                                                                            <Form.Control
                                                                                name={"supplierNumber"}
                                                                                onChange={handleChange}
                                                                                placeholder="Supplier number..." />
                                                                        </Form.Group>
                                                                        <Form.Group>
                                                                            <Form.Label style={{ fontWeight: "bold" }}>Supplier
                                                                                name:</Form.Label>
                                                                            <Form.Control
                                                                                name={"supplierName"}
                                                                                onChange={handleChange}
                                                                                placeholder="Supplier name..." />
                                                                        </Form.Group>
                                                                        <Form.Group>
                                                                            <Form.Label
                                                                                style={{ fontWeight: "bold" }}>Switchboard:</Form.Label>
                                                                            <Form.Control type="Number"
                                                                                name={"switchboard"}
                                                                                onChange={handleChange}
                                                                                placeholder="Switchboard..." />
                                                                        </Form.Group>
                                                                        <Form.Group>
                                                                            <Form.Label
                                                                                style={{ fontWeight: "bold" }}>Website:</Form.Label>
                                                                            <Form.Control
                                                                                name={"website"}
                                                                                onChange={handleChange}
                                                                                placeholder="Website..." />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label style={{ fontWeight: "bold" }}>Address
                                                                                line one:</Form.Label>
                                                                            <Form.Control
                                                                                name={"addressOne"}
                                                                                onChange={handleChange}
                                                                                placeholder="Address line one..." />
                                                                        </Form.Group>
                                                                        <Form.Group>
                                                                            <Form.Label style={{ fontWeight: "bold" }}>Address
                                                                                line two:</Form.Label>
                                                                            <Form.Control
                                                                                name={"addressTwo"}
                                                                                onChange={handleChange}
                                                                                placeholder="Address line two..." />
                                                                        </Form.Group>
                                                                        <Form.Group>
                                                                            <Form.Label style={{ fontWeight: "bold" }}>Address
                                                                                line three:</Form.Label>
                                                                            <Form.Control
                                                                                name={"addressThree"}
                                                                                onChange={handleChange}
                                                                                placeholder="Address line three..." />
                                                                        </Form.Group>
                                                                        <Form.Group>
                                                                            <Form.Label
                                                                                style={{ fontWeight: "bold" }}>Town/City:</Form.Label>
                                                                            <Form.Control
                                                                                name={"townCity"}
                                                                                onChange={handleChange}
                                                                                placeholder="Town/City..." />
                                                                        </Form.Group>
                                                                        <Form.Group>
                                                                            <Form.Label style={{ fontWeight: "bold" }}>Post
                                                                                code:</Form.Label>
                                                                            <Form.Control
                                                                                name={"postCode"}
                                                                                onChange={handleChange}
                                                                                placeholder="Post code..." />
                                                                        </Form.Group>
                                                                        <div className="d-flex justify-content-end">

                                                                            <Button type="submit"
                                                                                className="button-green w-auto">Add
                                                                                supplier</Button>
                                                                        </div>
                                                                    </Col>
                                                                </Form.Row>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                    {allSuppliers &&
                                                        <InfiniteScroll
                                                            dataLength={allSuppliers.length}
                                                            next={() => getMoreSuppliers()}
                                                            hasMore={true}
                                                            scrollableTarget="screen-container"
                                                            loader=""
                                                        >

                                                            <div className="border-top d-flex flex-column pt-3 mt-3">
                                                                <Accordion>
                                                                    {allSuppliers?.sort((a, b) => a.name.localeCompare(b.name)).map((supplier, index) => {
                                                                        return (
                                                                            <Card>
                                                                                <Accordion.Toggle
                                                                                    onClick={() => {
                                                                                        if (supplierAcitveKey === index) {
                                                                                            setSupplierActiveKey(undefined)
                                                                                            setSelectedSupplier(undefined)
                                                                                        } else {
                                                                                            setSupplierActiveKey(index)
                                                                                            setSelectedSupplier(supplier)
                                                                                        }
                                                                                    }}
                                                                                    className="purchaseOrders-body-accordion-header"
                                                                                    as={Card.Header}
                                                                                    eventKey={index.toString()}>
                                                                                    {supplier.name}
                                                                                    {supplierAcitveKey === index ?
                                                                                        <MdRemove size={20} /> :
                                                                                        <MdAdd size={20} />}
                                                                                </Accordion.Toggle>
                                                                                <Accordion.Collapse
                                                                                    eventKey={index.toString()}>
                                                                                    <Card.Body>
                                                                                        <Row>
                                                                                            <Col sm={3}>
                                                                                                <div className="mb-2">
                                                                                                    <p className="font-weight-bold">
                                                                                                        Supplier number:
                                                                                                    </p>
                                                                                                    <p>{supplier.id}</p>
                                                                                                </div>
                                                                                                <div className="mb-2">
                                                                                                    <p className="font-weight-bold">
                                                                                                        Supplier name:
                                                                                                    </p>
                                                                                                    <p>{supplier.name}</p>
                                                                                                </div>
                                                                                                <div className="mb-2">
                                                                                                    <p className="font-weight-bold">
                                                                                                        Website:
                                                                                                    </p>
                                                                                                    <p>{supplier.website}</p>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p className="font-weight-bold">
                                                                                                        Switchboard:
                                                                                                    </p>
                                                                                                    <p>{supplier.switchboard}</p>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col sm={3}>
                                                                                                <div>
                                                                                                    <p className="font-weight-bold">
                                                                                                        Address:
                                                                                                    </p>
                                                                                                    <p>{supplier.addressOne}</p>
                                                                                                    <p>{supplier.addressTwo}</p>
                                                                                                    <p>{supplier.addressThree}</p>
                                                                                                    <p>{supplier.townCity}</p>
                                                                                                    <p>{supplier.postCode}</p>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col sm={6}>
                                                                                                <div>
                                                                                                    <p className="font-weight-bold">
                                                                                                        Contacts:
                                                                                                    </p>
                                                                                                    {(supplier.contacts && supplier.contacts.length > 0) ?
                                                                                                        <Accordion>
                                                                                                            {supplier.contacts.map((contact, index) => {
                                                                                                                return (
                                                                                                                    <Card>
                                                                                                                        <Accordion.Toggle
                                                                                                                            onClick={() => {
                                                                                                                                if (supplierContactAcitveKey === index) {
                                                                                                                                    setSupplierContactActiveKey(undefined)
                                                                                                                                } else {
                                                                                                                                    setSupplierContactActiveKey(index)
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            className="purchaseOrders-body-accordion-header"
                                                                                                                            as={Card.Header}
                                                                                                                            eventKey={index.toString()}>
                                                                                                                            {contact.name}
                                                                                                                            {supplierContactAcitveKey === index ?
                                                                                                                                <MdRemove
                                                                                                                                    size={20} /> :
                                                                                                                                <MdAdd
                                                                                                                                    size={20} />}
                                                                                                                        </Accordion.Toggle>
                                                                                                                        <Accordion.Collapse
                                                                                                                            eventKey={index.toString()}>
                                                                                                                            <Card.Body>
                                                                                                                                <Row>
                                                                                                                                    <Col>
                                                                                                                                        <div>
                                                                                                                                            <p className="font-weight-bold">
                                                                                                                                                Name:
                                                                                                                                            </p>
                                                                                                                                            <p>{contact.name}</p>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <p className="font-weight-bold">
                                                                                                                                                Position:
                                                                                                                                            </p>
                                                                                                                                            <p>{contact.position}</p>
                                                                                                                                        </div>
                                                                                                                                    </Col>
                                                                                                                                    <Col>
                                                                                                                                        <div>
                                                                                                                                            <p className="font-weight-bold">
                                                                                                                                                Email:
                                                                                                                                            </p>
                                                                                                                                            <p>{contact.email}</p>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <p className="font-weight-bold">
                                                                                                                                                Direct
                                                                                                                                                number:
                                                                                                                                            </p>
                                                                                                                                            <p>{contact.directPhone}</p>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <p className="font-weight-bold">
                                                                                                                                                Mobile
                                                                                                                                                number:
                                                                                                                                            </p>
                                                                                                                                            <p>{contact.mobilePhone}</p>
                                                                                                                                        </div>
                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>
                                                                                                                    </Card>
                                                                                                                )
                                                                                                            })}
                                                                                                        </Accordion>
                                                                                                        :
                                                                                                        <p>No contacts
                                                                                                            available</p>
                                                                                                    }
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <div
                                                                                                    className="d-flex flex-row justify-content-end align-items-center mt-3">
                                                                                                    <Button type="submit"
                                                                                                        className="button-green w-auto mr-2"
                                                                                                        onClick={() => {
                                                                                                            handleEditSupplierShow()
                                                                                                            setSelectedEditSupplier(supplier)
                                                                                                        }}
                                                                                                    >Edit
                                                                                                        supplier</Button>
                                                                                                    <Button
                                                                                                        onClick={() => handleShow()}
                                                                                                        className="button-green w-auto">
                                                                                                        Add contact
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Card.Body>
                                                                                </Accordion.Collapse>
                                                                            </Card>
                                                                        )
                                                                    })}
                                                                </Accordion>
                                                            </div>
                                                        </InfiniteScroll>
                                                    }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="customers">
                                                    <>
                                                        <Formik
                                                            initialValues={{
                                                                customerNumber: "",
                                                                customerName: "",
                                                                switchboard: "",
                                                                addressOne: "",
                                                                addressTwo: "",
                                                                addressThree: "",
                                                                townCity: "",
                                                                postCode: "",

                                                                deliveryAddressOne: "",
                                                                deliveryAddressTwo: "",
                                                                deliveryAddressThree: "",
                                                                deliveryTownCity: "",
                                                                deliveryPostCode: "",

                                                                invoiceAddressOne: "",
                                                                invoiceAddressTwo: "",
                                                                invoiceAddressThree: "",
                                                                invoiceTownCity: "",
                                                                invoicePostCode: "",
                                                            }}
                                                            onSubmit={values => addCustomer(values)}
                                                        >
                                                            {({
                                                                handleSubmit,
                                                                handleChange
                                                            }) => (
                                                                <Form onSubmit={handleSubmit}>
                                                                    <Form.Row>
                                                                        <Col>
                                                                            <Form.Group>
                                                                                <Form.Label
                                                                                    style={{ fontWeight: "bold" }}>Customer
                                                                                    number:</Form.Label>
                                                                                <Form.Control
                                                                                    name={"customerNumber"}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Customer number..." />
                                                                            </Form.Group>
                                                                            <Form.Group>
                                                                                <Form.Label
                                                                                    style={{ fontWeight: "bold" }}>Customer
                                                                                    name:</Form.Label>
                                                                                <Form.Control
                                                                                    name={"customerName"}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Customer name..." />
                                                                            </Form.Group>
                                                                            <Form.Group>
                                                                                <Form.Label
                                                                                    style={{ fontWeight: "bold" }}>Switchboard:</Form.Label>
                                                                                <Form.Control type="Number"
                                                                                    name={"switchboard"}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Switchboard..." />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col>
                                                                            <Form.Group>
                                                                                <Form.Label
                                                                                    style={{ fontWeight: "bold" }}>Main
                                                                                    address
                                                                                    line one:</Form.Label>
                                                                                <Form.Control
                                                                                    name={"addressOne"}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Address line one..." />
                                                                            </Form.Group>
                                                                            <Form.Group>
                                                                                <Form.Label
                                                                                    style={{ fontWeight: "bold" }}>Main
                                                                                    address
                                                                                    line two:</Form.Label>
                                                                                <Form.Control
                                                                                    name={"addressTwo"}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Address line two..." />
                                                                            </Form.Group>
                                                                            <Form.Group>
                                                                                <Form.Label
                                                                                    style={{ fontWeight: "bold" }}>Main
                                                                                    address
                                                                                    line three:</Form.Label>
                                                                                <Form.Control
                                                                                    name={"addressThree"}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Address line three..." />
                                                                            </Form.Group>
                                                                            <Form.Group>
                                                                                <Form.Label
                                                                                    style={{ fontWeight: "bold" }}>Town/City:</Form.Label>
                                                                                <Form.Control
                                                                                    name={"townCity"}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Town/City..." />
                                                                            </Form.Group>
                                                                            <Form.Group>
                                                                                <Form.Label
                                                                                    style={{ fontWeight: "bold" }}>Post
                                                                                    code:</Form.Label>
                                                                                <Form.Control
                                                                                    name={"postCode"}
                                                                                    onChange={handleChange}
                                                                                    placeholder="Post code..." />
                                                                            </Form.Group>
                                                                            <ThercoDropdown className="mb-2"
                                                                                title="Delivery address">
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontWeight: "bold" }}>Delivery
                                                                                        address
                                                                                        line one:</Form.Label>
                                                                                    <Form.Control
                                                                                        name={"deliveryAddressOne"}
                                                                                        onChange={handleChange}
                                                                                        placeholder="Address line one..." />
                                                                                </Form.Group>
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontWeight: "bold" }}>Delivery
                                                                                        address
                                                                                        line two:</Form.Label>
                                                                                    <Form.Control
                                                                                        name={"deliveryAddressTwo"}
                                                                                        onChange={handleChange}
                                                                                        placeholder="Address line two..." />
                                                                                </Form.Group>
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontWeight: "bold" }}>Delivery
                                                                                        address
                                                                                        line three:</Form.Label>
                                                                                    <Form.Control
                                                                                        name={"deliveryAddressThree"}
                                                                                        onChange={handleChange}
                                                                                        placeholder="Address line three..." />
                                                                                </Form.Group>
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontWeight: "bold" }}>Town/City:</Form.Label>
                                                                                    <Form.Control
                                                                                        name={"deliveryTownCity"}
                                                                                        onChange={handleChange}
                                                                                        placeholder="Town/City..." />
                                                                                </Form.Group>
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontWeight: "bold" }}>Post
                                                                                        code:</Form.Label>
                                                                                    <Form.Control
                                                                                        name={"deliveryPostCode"}
                                                                                        onChange={handleChange}
                                                                                        placeholder="Post code..." />
                                                                                </Form.Group>
                                                                            </ThercoDropdown>
                                                                            <ThercoDropdown title="Invoice address">
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontWeight: "bold" }}>Invoice
                                                                                        address
                                                                                        line one:</Form.Label>
                                                                                    <Form.Control
                                                                                        name={"invoiceAddressOne"}
                                                                                        onChange={handleChange}
                                                                                        placeholder="Address line one..." />
                                                                                </Form.Group>
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontWeight: "bold" }}>Invoice
                                                                                        address
                                                                                        line two:</Form.Label>
                                                                                    <Form.Control
                                                                                        name={"invoiceAddressTwo"}
                                                                                        onChange={handleChange}
                                                                                        placeholder="Address line two..." />
                                                                                </Form.Group>
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontWeight: "bold" }}>Invoice
                                                                                        address
                                                                                        line three:</Form.Label>
                                                                                    <Form.Control
                                                                                        name={"invoiceAddressThree"}
                                                                                        onChange={handleChange}
                                                                                        placeholder="Address line three..." />
                                                                                </Form.Group>
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontWeight: "bold" }}>Town/City:</Form.Label>
                                                                                    <Form.Control
                                                                                        name={"invoiceTownCity"}
                                                                                        onChange={handleChange}
                                                                                        placeholder="Town/City..." />
                                                                                </Form.Group>
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontWeight: "bold" }}>Post
                                                                                        code:</Form.Label>
                                                                                    <Form.Control
                                                                                        name={"invoicePostCode"}
                                                                                        onChange={handleChange}
                                                                                        placeholder="Post code..." />
                                                                                </Form.Group>
                                                                            </ThercoDropdown>
                                                                            <div
                                                                                className="d-flex justify-content-end mt-4">
                                                                                <Button type="submit"
                                                                                    className="button-green w-auto">Add
                                                                                    customer</Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Form.Row>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                        {allCustomers &&
                                                            <InfiniteScroll
                                                                dataLength={allCustomers.length}
                                                                next={() => getMoreCustomers()}
                                                                hasMore={true}
                                                                scrollableTarget="screen-container"
                                                                loader=""
                                                            >

                                                                <div className="border-top d-flex flex-column pt-3 mt-3">
                                                                    <Accordion>
                                                                        {allCustomers?.sort((a, b) => a.name.localeCompare(b.name)).map((customer, index) => {
                                                                            return (
                                                                                <Card>
                                                                                    <Accordion.Toggle
                                                                                        onClick={() => {
                                                                                            if (customerAcitveKey === index) {
                                                                                                setCustomerActiveKey(undefined)
                                                                                                setSelectedCustomer(undefined)
                                                                                            } else {
                                                                                                setCustomerActiveKey(index)
                                                                                                setSelectedCustomer(customer)
                                                                                            }
                                                                                        }}
                                                                                        className="purchaseOrders-body-accordion-header"
                                                                                        as={Card.Header}
                                                                                        eventKey={index.toString()}>
                                                                                        {customer.name}
                                                                                        {supplierAcitveKey === index ?
                                                                                            <MdRemove size={20} /> :
                                                                                            <MdAdd size={20} />}
                                                                                    </Accordion.Toggle>
                                                                                    <Accordion.Collapse
                                                                                        eventKey={index.toString()}>
                                                                                        <Card.Body>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <div className="mb-2">
                                                                                                        <p className="font-weight-bold">
                                                                                                            Customer number:
                                                                                                        </p>
                                                                                                        <p>{customer.id}</p>
                                                                                                    </div>
                                                                                                    <div className="mb-2">
                                                                                                        <p className="font-weight-bold">
                                                                                                            Customer name:
                                                                                                        </p>
                                                                                                        <p>{customer.name}</p>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <p className="font-weight-bold">
                                                                                                            Switchboard:
                                                                                                        </p>
                                                                                                        <p>{customer.switchboard}</p>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <div>
                                                                                                        <p className="font-weight-bold">
                                                                                                            Main address:
                                                                                                        </p>
                                                                                                        <p>{customer.addressOne}</p>
                                                                                                        <p>{customer.addressTwo}</p>
                                                                                                        <p>{customer.addressThree}</p>
                                                                                                        <p>{customer.townCity}</p>
                                                                                                        <p>{customer.postCode}</p>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <div>
                                                                                                        <p className="font-weight-bold">
                                                                                                            Delivery
                                                                                                            address:
                                                                                                        </p>
                                                                                                        <p>{customer.deliveryAddressOne}</p>
                                                                                                        <p>{customer.deliveryAddressTwo}</p>
                                                                                                        <p>{customer.deliveryAddressThree}</p>
                                                                                                        <p>{customer.deliveryTownCity}</p>
                                                                                                        <p>{customer.deliveryPostCode}</p>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <div>
                                                                                                        <p className="font-weight-bold">
                                                                                                            Invoice address:
                                                                                                        </p>
                                                                                                        <p>{customer.invoiceAddressOne}</p>
                                                                                                        <p>{customer.invoiceAddressTwo}</p>
                                                                                                        <p>{customer.invoiceAddressThree}</p>
                                                                                                        <p>{customer.invoiceTownCity}</p>
                                                                                                        <p>{customer.invoicePostCode}</p>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col></Col>
                                                                                                <Col>
                                                                                                    <div className="mt-4">
                                                                                                        <p className="font-weight-bold">
                                                                                                            Contacts:
                                                                                                        </p>
                                                                                                        {(customer.contacts && customer.contacts.length > 0) ?
                                                                                                            <Accordion>
                                                                                                                {customer.contacts.map((contact, index) => {
                                                                                                                    return (
                                                                                                                        <Card>
                                                                                                                            <Accordion.Toggle
                                                                                                                                onClick={() => {
                                                                                                                                    if (customerContactAcitveKey === index) {
                                                                                                                                        setCustomerContactActiveKey(undefined)
                                                                                                                                    } else {
                                                                                                                                        setCustomerContactActiveKey(index)
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                className="purchaseOrders-body-accordion-header"
                                                                                                                                as={Card.Header}
                                                                                                                                eventKey={index.toString()}>
                                                                                                                                {contact.name}
                                                                                                                                {customerContactAcitveKey === index ?
                                                                                                                                    <MdRemove
                                                                                                                                        size={20} /> :
                                                                                                                                    <MdAdd
                                                                                                                                        size={20} />}
                                                                                                                            </Accordion.Toggle>
                                                                                                                            <Accordion.Collapse
                                                                                                                                eventKey={index.toString()}>
                                                                                                                                <Card.Body>
                                                                                                                                    <Row>
                                                                                                                                        <Col>
                                                                                                                                            <div>
                                                                                                                                                <p className="font-weight-bold">
                                                                                                                                                    Name:
                                                                                                                                                </p>
                                                                                                                                                <p>{contact.name}</p>
                                                                                                                                            </div>
                                                                                                                                            <div>
                                                                                                                                                <p className="font-weight-bold">
                                                                                                                                                    Position:
                                                                                                                                                </p>
                                                                                                                                                <p>{contact.position}</p>
                                                                                                                                            </div>
                                                                                                                                        </Col>
                                                                                                                                        <Col>
                                                                                                                                            <div>
                                                                                                                                                <p className="font-weight-bold">
                                                                                                                                                    Email:
                                                                                                                                                </p>
                                                                                                                                                <p>{contact.email}</p>
                                                                                                                                            </div>
                                                                                                                                            <div>
                                                                                                                                                <p className="font-weight-bold">
                                                                                                                                                    Direct
                                                                                                                                                    number:
                                                                                                                                                </p>
                                                                                                                                                <p>{contact.directPhone}</p>
                                                                                                                                            </div>
                                                                                                                                            <div>
                                                                                                                                                <p className="font-weight-bold">
                                                                                                                                                    Mobile
                                                                                                                                                    number:
                                                                                                                                                </p>
                                                                                                                                                <p>{contact.mobilePhone}</p>
                                                                                                                                            </div>
                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                </Card.Body>
                                                                                                                            </Accordion.Collapse>
                                                                                                                        </Card>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </Accordion>
                                                                                                            :
                                                                                                            <p>No contacts
                                                                                                                available</p>
                                                                                                        }
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col></Col>
                                                                                                <Col>
                                                                                                    <div
                                                                                                        className="d-flex flex-row justify-content-end align-items-center mt-3">
                                                                                                        <Button onClick={() => {
                                                                                                            handleEditCustomerShow()
                                                                                                            setSelectedEditCustomer(customer)
                                                                                                        }}
                                                                                                            className="button-green w-auto mr-2">Edit customer</Button>
                                                                                                        <Button
                                                                                                            onClick={() => handleCustomerShow()}
                                                                                                            className="button-green w-auto">
                                                                                                            Add contact
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Card.Body>
                                                                                    </Accordion.Collapse>
                                                                                </Card>
                                                                            )
                                                                        })}
                                                                    </Accordion>
                                                                </div>
                                                            </InfiniteScroll>
                                                        }
                                                    </>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="productCategory">
                                                    <Formik
                                                        initialValues={{
                                                            productCategory: ""
                                                        }}
                                                        onSubmit={values => addNewDropdown(values, "productCategory")}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange
                                                        }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <Form.Row>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label
                                                                                style={{ fontWeight: "bold" }}>Category:</Form.Label>
                                                                            <Form.Control
                                                                                name={"productCategory"}
                                                                                onChange={handleChange}
                                                                                placeholder="Category..." />
                                                                        </Form.Group>
                                                                        <div
                                                                            className="d-flex justify-content-end mt-4">
                                                                            <Button type="submit"
                                                                                className="button-green w-auto">Add
                                                                                category</Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label style={{ fontWeight: "bold" }}>Items
                                                                            currently in the dropdown:</Form.Label>
                                                                        <ul style={{
                                                                            listStyleType: "disc",
                                                                            listStylePosition: "inside"
                                                                        }}>
                                                                            {dropdowns.productCategory.sort().map((value) => {
                                                                                return (
                                                                                    <li className='cursor' onClick={() => {
                                                                                        handleEditShow()
                                                                                        setOriginalEditText(value)
                                                                                        setEditText(value)
                                                                                        setEditType("productCategory")
                                                                                    }}><a>{value}</a></li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </Col>
                                                                </Form.Row>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="materialGrade">
                                                    <Formik
                                                        initialValues={{
                                                            materialGrade: ""
                                                        }}
                                                        onSubmit={values => addNewDropdown(values, "materialGrade")}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange
                                                        }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <Form.Row>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label style={{ fontWeight: "bold" }}>Material
                                                                                grade:</Form.Label>
                                                                            <Form.Control
                                                                                name={"materialGrade"}
                                                                                onChange={handleChange}
                                                                                placeholder="Material grade..." />
                                                                        </Form.Group>
                                                                        <div
                                                                            className="d-flex justify-content-end mt-4">
                                                                            <Button type="submit"
                                                                                className="button-green w-auto">Add
                                                                                material grade</Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label style={{ fontWeight: "bold" }}>Items
                                                                            currently in the dropdown:</Form.Label>
                                                                        <ul style={{
                                                                            listStyleType: "disc",
                                                                            listStylePosition: "inside"
                                                                        }}>
                                                                            {dropdowns.materialGrade.sort().map((value) => {
                                                                                return (
                                                                                    <li className='cursor' onClick={() => {
                                                                                        handleEditShow()
                                                                                        setOriginalEditText(value)
                                                                                        setEditText(value)
                                                                                        setEditType("materialGrade")
                                                                                    }}><a>{value}</a></li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </Col>
                                                                </Form.Row>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="materialGroup">
                                                    <Formik
                                                        initialValues={{
                                                            materialGroup: ""
                                                        }}
                                                        onSubmit={values => addNewDropdown(values, "materialGroup")}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange
                                                        }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <Form.Row>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label style={{ fontWeight: "bold" }}>Material
                                                                                group:</Form.Label>
                                                                            <Form.Control
                                                                                name={"materialGroup"}
                                                                                onChange={handleChange}
                                                                                placeholder="Material group..." />
                                                                        </Form.Group>
                                                                        <div
                                                                            className="d-flex justify-content-end mt-4">
                                                                            <Button type="submit"
                                                                                className="button-green w-auto">Add
                                                                                material group</Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label style={{ fontWeight: "bold" }}>Items
                                                                            currently in the dropdown:</Form.Label>
                                                                        <ul style={{
                                                                            listStyleType: "disc",
                                                                            listStylePosition: "inside"
                                                                        }}>
                                                                            {dropdowns.materialGroup.sort().map((value) => {
                                                                                return (
                                                                                    <li className='cursor' onClick={() => {
                                                                                        handleEditShow()
                                                                                        setOriginalEditText(value)
                                                                                        setEditText(value)
                                                                                        setEditType("materialGroup")
                                                                                    }}><a>{value}</a></li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </Col>
                                                                </Form.Row>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="finish">
                                                    <Formik
                                                        initialValues={{
                                                            finish: ""
                                                        }}
                                                        onSubmit={values => addNewDropdown(values, "finish")}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange
                                                        }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <Form.Row>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label
                                                                                style={{ fontWeight: "bold" }}>Finish:</Form.Label>
                                                                            <Form.Control
                                                                                name={"finish"}
                                                                                onChange={handleChange}
                                                                                placeholder="Finish..." />
                                                                        </Form.Group>
                                                                        <div
                                                                            className="d-flex justify-content-end mt-4">
                                                                            <Button type="submit"
                                                                                className="button-green w-auto">Add
                                                                                finish</Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label style={{ fontWeight: "bold" }}>Items
                                                                            currently in the dropdown:</Form.Label>
                                                                        <ul style={{
                                                                            listStyleType: "disc",
                                                                            listStylePosition: "inside"
                                                                        }}>
                                                                            {dropdowns.finish.sort().map((value) => {
                                                                                return (
                                                                                    <li className='cursor' onClick={() => {
                                                                                        handleEditShow()
                                                                                        setOriginalEditText(value)
                                                                                        setEditText(value)
                                                                                        setEditType("finish")
                                                                                    }}><a>{value}</a></li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </Col>
                                                                </Form.Row>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="qaRequirement">
                                                    <Formik
                                                        initialValues={{
                                                            qaRequirement: ""
                                                        }}
                                                        onSubmit={values => addNewDropdown(values, "qaRequirement")}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange
                                                        }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <Form.Row>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label style={{ fontWeight: "bold" }}>QA
                                                                                Requirement:</Form.Label>
                                                                            <Form.Control
                                                                                name={"qaRequirement"}
                                                                                onChange={handleChange}
                                                                                placeholder="QA Requirement..." />
                                                                        </Form.Group>
                                                                        <div
                                                                            className="d-flex justify-content-end mt-4">
                                                                            <Button type="submit"
                                                                                className="button-green w-auto">Add
                                                                                QA requirement</Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label style={{ fontWeight: "bold" }}>Items
                                                                            currently in the dropdown:</Form.Label>
                                                                        <ul style={{
                                                                            listStyleType: "disc",
                                                                            listStylePosition: "inside"
                                                                        }}>
                                                                            {dropdowns.qaRequirement.sort().map((value) => {
                                                                                return (
                                                                                    <li className='cursor' onClick={() => {
                                                                                        handleEditShow()
                                                                                        setOriginalEditText(value)
                                                                                        setEditText(value)
                                                                                        setEditType("qaRequirement")
                                                                                    }}><a>{value}</a></li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </Col>
                                                                </Form.Row>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="nominalCodes">
                                                    <Formik
                                                        initialValues={{
                                                            nominalCodes: ""
                                                        }}
                                                        onSubmit={values => addNewDropdown(values, "nominalCodes")}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange
                                                        }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <Form.Row>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label style={{ fontWeight: "bold" }}>Nominal
                                                                                codes:</Form.Label>
                                                                            <Form.Control
                                                                                name={"nominalCodes"}
                                                                                onChange={handleChange}
                                                                                placeholder="Nominal codes..." />
                                                                        </Form.Group>
                                                                        <div
                                                                            className="d-flex justify-content-end mt-4">
                                                                            <Button type="submit"
                                                                                className="button-green w-auto">Add
                                                                                nominal codes</Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label style={{ fontWeight: "bold" }}>Items
                                                                            currently in the dropdown:</Form.Label>
                                                                        <ul style={{
                                                                            listStyleType: "disc",
                                                                            listStylePosition: "inside"
                                                                        }}>
                                                                            {dropdowns.nominalCodes.sort().map((value) => {
                                                                                return (
                                                                                    <li className='cursor' onClick={() => {
                                                                                        handleEditShow()
                                                                                        setOriginalEditText(value)
                                                                                        setEditText(value)
                                                                                        setEditType("nominalCodes")
                                                                                    }}><a>{value}</a></li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </Col>
                                                                </Form.Row>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="thermalDesign">
                                                    <Formik
                                                        initialValues={{
                                                            thermalDesign: ""
                                                        }}
                                                        onSubmit={values => addNewDropdown(values, "thermalDesign")}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange
                                                        }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <Form.Row>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label style={{ fontWeight: "bold" }}>Thermal
                                                                                design:</Form.Label>
                                                                            <Form.Control
                                                                                name={"thermalDesign"}
                                                                                onChange={handleChange}
                                                                                placeholder="Thermal design..." />
                                                                        </Form.Group>
                                                                        <div
                                                                            className="d-flex justify-content-end mt-4">
                                                                            <Button type="submit"
                                                                                className="button-green w-auto">Add
                                                                                thermal design</Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label style={{ fontWeight: "bold" }}>Items
                                                                            currently in the dropdown:</Form.Label>
                                                                        <ul style={{
                                                                            listStyleType: "disc",
                                                                            listStylePosition: "inside"
                                                                        }}>
                                                                            {dropdowns.thermalDesign.sort().map((value) => {
                                                                                return (
                                                                                    <li className='cursor' onClick={() => {
                                                                                        handleEditShow()
                                                                                        setOriginalEditText(value)
                                                                                        setEditText(value)
                                                                                        setEditType("thermalDesign")
                                                                                    }}><a>{value}</a></li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </Col>
                                                                </Form.Row>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="mechanicalDesign">
                                                    <Formik
                                                        initialValues={{
                                                            mechanicalDesign: ""
                                                        }}
                                                        onSubmit={values => addNewDropdown(values, "mechanicalDesign")}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange
                                                        }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <Form.Row>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label style={{ fontWeight: "bold" }}>Mechanical
                                                                                design:</Form.Label>
                                                                            <Form.Control
                                                                                name={"mechanicalDesign"}
                                                                                onChange={handleChange}
                                                                                placeholder="Mechanical design..." />
                                                                        </Form.Group>
                                                                        <div
                                                                            className="d-flex justify-content-end mt-4">
                                                                            <Button type="submit"
                                                                                className="button-green w-auto">Add
                                                                                mechanical design</Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label style={{ fontWeight: "bold" }}>Items
                                                                            currently in the dropdown:</Form.Label>
                                                                        <ul style={{
                                                                            listStyleType: "disc",
                                                                            listStylePosition: "inside"
                                                                        }}>
                                                                            {dropdowns.mechanicalDesign.sort().map((value) => {
                                                                                return (
                                                                                    <li className='cursor' onClick={() => {
                                                                                        handleEditShow()
                                                                                        setOriginalEditText(value)
                                                                                        setEditText(value)
                                                                                        setEditType("mechanicalDesign")
                                                                                    }}><a>{value}</a></li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </Col>
                                                                </Form.Row>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="draughting">
                                                    <Formik
                                                        initialValues={{
                                                            draughting: ""
                                                        }}
                                                        onSubmit={values => addNewDropdown(values, "draughting")}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange
                                                        }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <Form.Row>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label
                                                                                style={{ fontWeight: "bold" }}>Draughting:</Form.Label>
                                                                            <Form.Control
                                                                                name={"draughting"}
                                                                                onChange={handleChange}
                                                                                placeholder="Draughting..." />
                                                                        </Form.Group>
                                                                        <div
                                                                            className="d-flex justify-content-end mt-4">
                                                                            <Button type="submit"
                                                                                className="button-green w-auto">Add
                                                                                draughting</Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label style={{ fontWeight: "bold" }}>Items
                                                                            currently in the dropdown:</Form.Label>
                                                                        <ul style={{
                                                                            listStyleType: "disc",
                                                                            listStylePosition: "inside"
                                                                        }}>
                                                                            {dropdowns.draughting.sort().map((value) => {
                                                                                return (
                                                                                    <li className='cursor' onClick={() => {
                                                                                        handleEditShow()
                                                                                        setOriginalEditText(value)
                                                                                        setEditText(value)
                                                                                        setEditType("draughting")
                                                                                    }}><a>{value}</a></li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </Col>
                                                                </Form.Row>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="stockLocation">
                                                    <Formik
                                                        initialValues={{
                                                            draughting: ""
                                                        }}
                                                        onSubmit={values => addNewDropdown(values, "stockLocation")}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            handleChange
                                                        }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <Form.Row>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label
                                                                                style={{ fontWeight: "bold" }}>Stock Location:</Form.Label>
                                                                            <Form.Control
                                                                                name={"stockLocation"}
                                                                                onChange={handleChange}
                                                                                placeholder="Stock location..." />
                                                                        </Form.Group>
                                                                        <div
                                                                            className="d-flex justify-content-end mt-4">
                                                                            <Button type="submit"
                                                                                className="button-green w-auto">Add stock location</Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Label style={{ fontWeight: "bold" }}>Items
                                                                            currently in the dropdown:</Form.Label>
                                                                        <ul style={{
                                                                            listStyleType: "disc",
                                                                            listStylePosition: "inside"
                                                                        }}>
                                                                            {dropdowns.stockLocation.sort().map((value) => {
                                                                                return (
                                                                                    <li className='cursor' onClick={() => {
                                                                                        handleEditShow()
                                                                                        setOriginalEditText(value)
                                                                                        setEditText(value)
                                                                                        setEditType("stockLocation")
                                                                                    }}><a>{value}</a></li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </Col>
                                                                </Form.Row>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </div>
                            </Tab.Container>
                        </Tab>
                        {/* <Tab eventKey="profile" title="Profile">

                        </Tab> */}
                    </Tabs>
                </Card.Body>
            </Card>
        </Screen>
    )
}

export default ControlPanel
