import React, {ReactNode, useEffect, useState} from 'react';
import {MdExpandLess, MdExpandMore} from "react-icons/all";

interface ThercoDropdownProps {
    title: string;
    initialOpen?: boolean;
    className?: string;
    children: ReactNode
}

const ThercoDropdown = ({title, children, initialOpen = false, className}: ThercoDropdownProps) => {
    const [open, setOpen] = useState<boolean>(false)

    useEffect(() => {
        setOpen(initialOpen)
    }, [])
    return (
        <div className={`thercoDropdown ${className}`}>
            <a onClick={() => setOpen(!open)} className={`thercoDropdown__title ${open && "thercoDropdown__title--open"}`}>
                {title}
                <div className="d-flex flex-row align-items-center">
                    {open ? <p>View less</p> : <p>View more</p>}
                    {open ? <MdExpandLess color="#51ad32" size={22}/> : <MdExpandMore color="#51ad32" size={22}/>}
                </div>
            </a>
            {open &&
            <div className="thercoDropdown-content">
                {children}
            </div>
            }
        </div>
    )
}

export default ThercoDropdown