import React, { useContext, useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import { Accordion, Button, Card, Col, Container, Form, FormControl, Row, Table } from "react-bootstrap";
import { MdAdd, MdAssignment, MdAvTimer, MdHelp, MdNoteAdd, MdRemove } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroll-component";
import searchIcon from "../../Images/icons8-search-96 (1).png";
import { globalUser, User } from "../../App";
import { Link } from 'react-router-dom';
import EnquiriesCreate from "./EnquiriesCreate";
import { Customer, SupplierContact } from "../ControlPanel";
import firebase from "firebase";
import ThercoDropdown from "../../Components/ThercoDropdown";
import { assessment } from "../../Static Data/enquiryAssessment";
import moment from "moment";
import TrafficLights from "../../Components/TrafficLights";
import { Quote } from "./QuotationView";
import EnquiryEmailModal from "./EnquiryEmailModal";
import { toast } from "react-hot-toast";
import EnquiriesRevalidate from './EnquiriesRevalidate';
export interface Enquiry {
    tnNumber: string
    loggedDate: string
    quoteDueDate: string
    quoteSentDate?: string
    enquiryOwner: User
    customerRef: string
    budgetOnly: boolean
    commercialContact: SupplierContact
    technicalContact: SupplierContact
    customer: Customer
    customerSpecs: string;
    description: string
    thermalDesignScope: string
    mechanicalDesignScope: string
    draughtingDesignScope: string
    parts: { partNumber: string, description?: string, tagNumber?: string, quantity?: string, price?: number }[]
    notes: { text: string, username: string, date: string }[]
    status?: string
    confidence: string
    revision?: number
    assessment: { id: number, value: number }[]
}

const Enquiries = () => {
    const [userPendings, setUserPendings] = useState<Quote[]>()
    const [recents, setRecents] = useState<Quote[]>()
    const [acitveKey, setActiveKey] = useState<number>()
    const [showCreate, setShowCreate] = useState<boolean>(false)
    const [showReval, setShowReval] = useState<boolean>(false)
    const [showEmail, setShowEmail] = useState<boolean>(false)
    const [emailEnquiry, setEmailEnquiry] = useState<Enquiry>()
    const [searchType, setSearchType] = useState<string>("TN number")
    const [searchText, setSearchText] = useState<string>("")
    const currentUser = useContext(globalUser)

    const [enquiries, setEnquiries] = useState<{ enquiry: Enquiry, quote: boolean, quotations: Quote[] }[]>([])
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const mobileQuery = window.matchMedia('(max-width: 850px)')
    const getEnquiries = async () => {
        const query = firebase.firestore().collection('enquiries')
            .orderBy('tnNumber', "desc")
            .limit(50)

        const enquiriesRequest = await query.get()
        const loopData = enquiriesRequest.docs
        console.log(loopData)

        const promiseArray = loopData.map(async (doc) => {
            // Get the tnNumber from the enquiry document
            const tnNumber = doc.data().tnNumber;

            // Query the 'quotations' collection where 'tn' equals the tnNumber from the enquiry
            const quotationsQuery = firebase.firestore().collection("quotations")
                .where("tn", "==", tnNumber);

            const quotationsSnapshot = await quotationsQuery.get();

            // Check if any quotation documents were found
            const quoteExists = !quotationsSnapshot.empty;

            // Create a response object with enquiry and quote status
            const request: { enquiry: any, quote: boolean, quotations: any[] } = {
                enquiry: doc.data(),
                quote: quoteExists,
                quotations: []
            };

            // If quotations exist, add them to the array
            if (quoteExists) {
                quotationsSnapshot.forEach(quotationDoc => {
                    request.quotations.push(quotationDoc.data());
                });
            }

            return request;
        });

        // Wait for all promises to resolve
        const resultArray = await Promise.all(promiseArray);

        // Update your state with the enquiries and the fetched data
        setEnquiries([...resultArray]);
        setData(enquiriesRequest);
    }

    const getMostRecentQuote = (quotations: Quote[]): Quote | null => {
        if (quotations.length === 0) {
            return null; // Return null if no quotations exist
        }

        // Sort the quotations by `quoteDate` in descending order (most recent first)
        const sortedQuotations = quotations.sort((a, b) => {
            const dateA = new Date(a.quoteDate).getTime();
            const dateB = new Date(b.quoteDate).getTime();
            return dateB - dateA; // Sort by descending date
        });

        // Return the most recent quote (first in the sorted array)
        return sortedQuotations[0];
    };

    const getMoreEnquiries = async () => {
        if (enquiries) {
            // Get the last visible document for pagination
            const lastVisible = data && data.docs[data.docs.length - 1];
            const query = firebase.firestore().collection('enquiries')
                .orderBy('tnNumber', "desc")
                .startAfter(lastVisible)
                .limit(50);

            // Fetch the next set of enquiries
            const enquiriesRequest = await query.get();
            const loopData = enquiriesRequest.docs;

            // Map over the documents and fetch the corresponding quotations
            const promiseArray = loopData.map(async (doc) => {
                // Get the tnNumber from the enquiry document
                const tnNumber = doc.data().tnNumber;

                // Query the 'quotations' collection where 'tn' equals the tnNumber
                const quotationsQuery = firebase.firestore().collection("quotations")
                    .where("tn", "==", tnNumber);

                const quotationsSnapshot = await quotationsQuery.get();

                // Check if any quotation documents were found
                const quoteExists = !quotationsSnapshot.empty;

                // Create a response object with enquiry and quote status
                const request: { enquiry: any, quote: boolean, quotations: any[] } = {
                    enquiry: doc.data(),
                    quote: quoteExists,
                    quotations: []
                };

                // If quotations exist, add them to the array
                if (quoteExists) {
                    quotationsSnapshot.forEach(quotationDoc => {
                        request.quotations.push(quotationDoc.data());
                    });
                }

                return request;
            });

            // Wait for all promises to resolve
            const resultArray = await Promise.all(promiseArray);

            // Update the state with the new enquiries and data
            setEnquiries([...enquiries, ...resultArray]);
            setData(enquiriesRequest);
        }
    }


    const getRecents = async () => {
        console.log("hit")
        let temp: Quote[] = []
        const query = firebase.firestore().collection('quotations')
            .where("raisedById", "==", currentUser.currentUser.uid)
            .limit(4)

        const userPendings = await query.get()
        console.log(userPendings)
        userPendings.docs.map((doc) => {
            console.log(doc)
            // @ts-ignore
            temp.push(doc.data())
        })
        console.log(temp)
        setRecents(temp)
    }

    useEffect(() => {
        setLoading(true)
        getRecents()
        getEnquiries().then(() => setLoading(false))
    }, [])

    console.log(enquiries)

    const [selectedEnq, setSelectedEnq] = useState<Enquiry>()

    useEffect(() => {
        console.log(selectedEnq)
    }, [selectedEnq])

    return (
        <Screen active="Enquiries">
            <EnquiriesCreate submit={() => {
                setEnquiries([])
                getEnquiries()
            }} show={showCreate} hide={() => setShowCreate(false)} />
            {emailEnquiry &&
                <EnquiryEmailModal enquiry={emailEnquiry} show={showEmail} hide={() => setShowEmail(false)} />
            }
            {selectedEnq && showReval &&
                <EnquiriesRevalidate submit={() => {
                    setEnquiries([])
                    getEnquiries()
                }} enquiry={selectedEnq} show={showReval} hide={() => {
                    setShowReval(false)
                }} />
            }

            <Card>
                <Card.Header
                    className="card-header--sticky purchaseOrders-header d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-row align-items-center">
                        <MdAssignment style={{ marginRight: ".3rem" }} size={18} color="#131313" />
                        <p className="font-weight-bold">Enquiry processing</p>
                    </div>
                    {!mobileQuery.matches &&
                        <div className="d-flex flex-row align-items-center">
                            <Form.Control onChange={(event) => setSearchType(event.currentTarget.value)}
                                style={{ width: "10rem", marginLeft: "-10.5rem" }} as="select">
                                <option value="tnNumber">TN number</option>
                                <option value="customerName">Customer name</option>
                                <option value="partNumber">Part number</option>
                                <option value="serialNumber">Serial number</option>
                                <option value="tagNumber">Tag</option>
                            </Form.Control>
                            <FormControl
                                onKeyDown={(e: any) => e.key === "Enter"}
                                onChange={(event) => setSearchText(event.currentTarget.value)}
                                value={searchText}
                                style={{ marginLeft: ".3rem", marginRight: ".3rem" }}
                                className="datasheet-list-head__search"
                                placeholder={`Search enquiries by ${searchType}...`}
                            />
                            <Button
                                onClick={() => {
                                }}
                                className="button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                <img src={searchIcon} />
                            </Button>
                        </div>
                    }
                    <div className="d-flex flex-row align-items-center purchaseOrders-header-controls">
                        <a onClick={() => setShowCreate(true)}>
                            <MdNoteAdd size={20} color="#131313" />
                            {` New enquiry`}
                        </a>
                    </div>
                </Card.Header>
                <Card.Body className="purchaseOrders-body">
                    {(enquiries && !loading) &&
                        <InfiniteScroll
                            dataLength={enquiries.length}
                            next={() => getMoreEnquiries()}
                            hasMore={true}
                            scrollableTarget="screen-container"
                            loader=""
                        >
                            <Container fluid={true}>
                                {!mobileQuery.matches &&
                                    <Row noGutters className="border-bottom pb-3 mobile-row">
                                        <Col md={9}>
                                            {(currentUser.currentUser.permission === "Level 1" && userPendings && userPendings?.length > 0) ?
                                                <Card>
                                                    <Card.Header
                                                        className="purchaseOrders-header d-flex justify-content-between align-items-center">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <MdAvTimer style={{ marginRight: ".3rem" }} size={18}
                                                                color="#131313" />
                                                            <p className="font-weight-bold">Quotations awaiting your
                                                                approval</p>
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Table bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>PO Number</th>
                                                                    <th>Supplier</th>
                                                                    <th>No. Parts</th>
                                                                    <th>Total parts</th>
                                                                    <th>Price (Excluding VAT)</th>
                                                                    <th>Price (Including VAT)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {userPendings?.map((quote) => {
                                                                    <div></div>
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Card>
                                                :
                                                <Card>
                                                    <Card.Header
                                                        className="purchaseOrders-header d-flex justify-content-between align-items-center">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <MdAvTimer style={{ marginRight: ".3rem" }} size={18}
                                                                color="#131313" />
                                                            <p className="font-weight-bold">Quotes raised by you</p>
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Table bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>TN Number</th>
                                                                    <th>Customer</th>
                                                                    <th>Date raised</th>
                                                                    <th>Price (Excluding VAT)</th>
                                                                    <th>Approval status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {recents?.map((quote) => {
                                                                    let pr = 0
                                                                    quote.itemPrices.map((val) => pr = pr + val.price)
                                                                    return (
                                                                        <tr>
                                                                            <td>TN{quote.enquiry.tnNumber}</td>
                                                                            <td>{quote.enquiry.customer.name}</td>
                                                                            <td>{quote.quoteDate}</td>
                                                                            <td>£{pr}</td>
                                                                            <td>
                                                                                <span
                                                                                    className={`tag ${quote.approved ? 'tag--green' : 'tag--orange'}`}>
                                                                                    {quote.approved ? 'Approved' : 'Awaiting approval'}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Card>
                                            }
                                        </Col>
                                        <Col md={3} className="pl-3 mobile-column">
                                            <Card className="h-100">
                                                <Card.Header
                                                    className="purchaseOrders-header d-flex justify-content-between align-items-center">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <MdHelp style={{ marginRight: ".3rem" }} size={18} color="#131313" />
                                                        <p className="font-weight-bold">Enquiry & Quotations documentation</p>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body
                                                    className="purchaseOrders-body d-flex align-items-center justify-content-center ">

                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                }
                            </Container>
                            <InfiniteScroll
                                dataLength={enquiries.length}
                                next={() => getMoreEnquiries()}
                                hasMore={true}
                                scrollableTarget="screen-container"
                                loader=""
                            >
                                <div className={`d-flex flex-column p-3 ${mobileQuery.matches && "p-0"}`}>
                                    <Accordion>
                                        {enquiries.map((enquiry, index) => {
                                            let averageScore = 0;
                                            assessment.map((value) => {
                                                const rating = enquiry.enquiry.assessment.find((id: any) => id.id === value.id)
                                                if (rating) {
                                                    averageScore = averageScore + rating.value
                                                }
                                            })
                                            averageScore = averageScore / assessment.length
                                            return (
                                                <Card>
                                                    <Accordion.Toggle
                                                        onClick={() => {
                                                            if (acitveKey === index) {
                                                                
                                                                setActiveKey(undefined)
                                                            } else {
                                                                setActiveKey(index)
                                                            }
                                                        }}
                                                        className="purchaseOrders-body-accordion-header"
                                                        as={Card.Header} eventKey={index.toString()}>
                                                        {`TN${enquiry.enquiry.tnNumber}${enquiry.enquiry.revision && enquiry.enquiry.revision > 0 ? `Rev` + enquiry.enquiry.revision : ""} - Customer: ${enquiry.enquiry.customer.name}`}
                                                        {acitveKey === index ? <MdRemove size={20} /> : <MdAdd size={20} />}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={index.toString()}>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col>
                                                                    <div className="d-flex flex-column mb-2">
                                                                        <p className="font-weight-bold mb-1"></p>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row sm={1} xl={4}>
                                                                <Col>
                                                                    <Row className="mb-2">
                                                                        <Col>
                                                                            <div className="d-flex flex-column mb-2">
                                                                                <p className="font-weight-bold">TN
                                                                                    number:</p>
                                                                                <p className="mb-1">{enquiry.enquiry.tnNumber}</p>
                                                                                <p className="font-weight-bold">Revision:</p>
                                                                                <p className="mb-1">{enquiry.enquiry.revision ? `Rev${enquiry.enquiry.revision}` : `Rev0`}</p>
                                                                                <p className="font-weight-bold">Enquiry
                                                                                    owner:</p>
                                                                                <p className="mb-1">{enquiry.enquiry.enquiryOwner && enquiry.enquiry.enquiryOwner.name}</p>
                                                                                <p className="font-weight-bold">Customer
                                                                                    reference:</p>
                                                                                <p className="mb-1">{enquiry.enquiry.customerRef}</p>
                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <div className="d-flex flex-column mb-2">
                                                                                <p className="font-weight-bold">Date
                                                                                    logged:</p>
                                                                                <p className="mb-1">{moment(enquiry.enquiry.loggedDate).format("DD/MM/YYYY")}</p>
                                                                                <p className="font-weight-bold">Quote due
                                                                                    date:</p>
                                                                                <p className="mb-1">{moment(enquiry.enquiry.quoteDueDate).format("DD/MM/YYYY")}</p>
                                                                                <p className="font-weight-bold">Quote sent
                                                                                    date:</p>
                                                                                <p className="mb-1">{enquiry.enquiry.quoteSentDate ? enquiry.enquiry.quoteSentDate : "Quote not sent"}</p>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="d-flex flex-column mb-2">
                                                                                <p className="font-weight-bold">Description:</p>
                                                                                <p className="mb-1">{enquiry.enquiry.description}</p>
                                                                            </div>
                                                                            <div className="d-flex flex-column">
                                                                                <p className="font-weight-bold">Customer specifications:</p>
                                                                                <p className="mb-1">{enquiry.enquiry.customerSpecs}</p>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col>
                                                                    <div className="d-flex flex-column mb-2">
                                                                        <p className="font-weight-bold">Customer:</p>
                                                                        <p>{enquiry.enquiry.customer.name}</p>
                                                                        <p>{enquiry.enquiry.customer.addressOne}</p>
                                                                        <p>{enquiry.enquiry.customer.addressTwo}</p>
                                                                        <p>{enquiry.enquiry.customer.addressThree}</p>
                                                                        <p>{enquiry.enquiry.customer.townCity}</p>
                                                                        <p>{enquiry.enquiry.customer.postCode}</p>
                                                                    </div>

                                                                    <ThercoDropdown className="mb-2"
                                                                        title="Delivery address">
                                                                        <div className="d-flex flex-column">
                                                                            <p>{enquiry.enquiry.customer.deliveryAddressOne}</p>
                                                                            <p>{enquiry.enquiry.customer.deliveryAddressTwo}</p>
                                                                            <p>{enquiry.enquiry.customer.deliveryAddressThree}</p>
                                                                            <p>{enquiry.enquiry.customer.deliveryTownCity}</p>
                                                                            <p>{enquiry.enquiry.customer.deliveryPostCode}</p>
                                                                        </div>
                                                                    </ThercoDropdown>

                                                                    <ThercoDropdown title="Invoice address">
                                                                        <div className="d-flex flex-column">
                                                                            <p>{enquiry.enquiry.customer.invoiceAddressOne}</p>
                                                                            <p>{enquiry.enquiry.customer.invoiceAddressTwo}</p>
                                                                            <p>{enquiry.enquiry.customer.invoiceAddressThree}</p>
                                                                            <p>{enquiry.enquiry.customer.invoiceTownCity}</p>
                                                                            <p>{enquiry.enquiry.customer.invoicePostCode}</p>
                                                                        </div>
                                                                    </ThercoDropdown>

                                                                    <Row>
                                                                        <Col>
                                                                            <div className="d-flex flex-column mt-3 mb-2">
                                                                                <p className="font-weight-bold">Technical
                                                                                    contact:</p>
                                                                                <p>{enquiry.enquiry.technicalContact.name}</p>
                                                                                <p>{enquiry.enquiry.technicalContact.email}</p>
                                                                                <p>{enquiry.enquiry.technicalContact.mobilePhone}</p>
                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <div className="d-flex flex-column mt-3">
                                                                                <p className="font-weight-bold">Commercial
                                                                                    contact:</p>
                                                                                <p>{enquiry.enquiry.commercialContact.name}</p>
                                                                                <p>{enquiry.enquiry.commercialContact.email}</p>
                                                                                <p>{enquiry.enquiry.commercialContact.mobilePhone}</p>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col>
                                                                    <Row className="mb-2">
                                                                        <Col>
                                                                            <div className="d-flex flex-column mb-2">
                                                                                <p className="font-weight-bold">Thermal
                                                                                    design scope:</p>
                                                                                <p className="mb-1">{enquiry.enquiry.thermalDesignScope}</p>
                                                                                <p className="font-weight-bold">Mechanical
                                                                                    design scope:</p>
                                                                                <p className="mb-1">{enquiry.enquiry.mechanicalDesignScope}</p>
                                                                                <p className="font-weight-bold">Draughting
                                                                                    design scope:</p>
                                                                                <p className="mb-1">{enquiry.enquiry.draughtingDesignScope}</p>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <div className="d-flex flex-column mb-2">
                                                                        <p className="font-weight-bold">Budget only:</p>
                                                                        <p className="mb-1">{enquiry.enquiry.budgetOnly ? "Yes" : "No"}</p>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <p className="font-weight-bold">Bid Strategy / Enquiry Notes:</p>
                                                                    <div className="d-flex flex-column overflow-auto"
                                                                        style={{ height: "25rem" }}>
                                                                        {enquiry.enquiry.notes.length > 0 && enquiry.enquiry.notes.map((value: any) => {
                                                                            return (
                                                                                <div className="d-flex flex-column mb-4">
                                                                                    <p>{value.text}</p>
                                                                                    <div
                                                                                        className="d-flex justify-content-end">
                                                                                        <p className="font-weight-bold">{`- ${value.username} ${value.date}`}</p>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="mt-3">
                                                                    <p className="font-weight-bold mb-1">Parts enquired:</p>
                                                                    <Table bordered hover>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>QTY</th>
                                                                                <th>Part Number</th>
                                                                                <th className="w-75">Description</th>
                                                                                <th>Tag Number</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {enquiry.enquiry.parts.map((value: any, index: any, array: any) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{index + 1}</td>
                                                                                        <td className="text-right">{value.quantity}</td>
                                                                                        <td>{value.partNumber}</td>
                                                                                        <td>{value.description}</td>
                                                                                        <td>{value.tagNumber}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </Table>
                                                                    <ThercoDropdown className="mt-3"
                                                                        title={`Enquiry assessment - ${averageScore}`}>
                                                                        <Table bordered>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>Question</th>
                                                                                    <th>Rating</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {assessment.map((value) => {
                                                                                    const rating = enquiry.enquiry.assessment.find((id: any) => id.id === value.id)
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{value.id}</td>
                                                                                            <td>{value.text}</td>
                                                                                            <td>{rating && rating.value}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </Table>
                                                                    </ThercoDropdown>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-3">
                                                                <Col className="d-flex flex-column w-100">
                                                                    <div>
                                                                        <p className="font-weight-bold">Confidence:</p>
                                                                        <TrafficLights noChange onChange={() => {
                                                                        }} initialColour={enquiry.enquiry.confidence} />
                                                                        {(enquiry.quote && enquiry.enquiry.status) &&
                                                                            <>
                                                                                <p className="font-weight-bold mt-1">Status:</p>
                                                                                <p>{enquiry.enquiry.status}</p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-3">
                                                                <Col>
                                                                    <div className={`d-flex w-100 justify-content-end ${mobileQuery.matches && "flex-column"}`}>
                                                                        {enquiry.quote &&
                                                                            <>

                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setSelectedEnq(enquiry.enquiry)
                                                                                        setShowReval(true)
                                                                                        setShowCreate(false)
                                                                                    }}
                                                                                    className={`button-green w-auto ml-2 ${mobileQuery.matches && "m-0 mb-2"}`}>
                                                                                    Revaildate Quote
                                                                                </Button>
                                                                            </>

                                                                        }
                                                                        <Button
                                                                            onClick={() => {
                                                                                setEmailEnquiry(enquiry.enquiry)
                                                                                setShowEmail(true)
                                                                            }}
                                                                            className={`button-green w-auto ml-2 ${mobileQuery.matches && "m-0 mb-2"}`}>
                                                                            Generate enquiry email
                                                                        </Button>
                                                                        {/* <Button
                                                                            className={`button-green w-auto ml-2 ${mobileQuery.matches && "m-0 mb-2"}`}>
                                                                            Edit enquiry
                                                                        </Button> */}
                                                                        {enquiry.quote ?
                                                                            <Link
                                                                                to={`/enquiries/quotations/view/${getMostRecentQuote(enquiry.quotations)?.id}`}
                                                                                className={`button-green w-auto ml-2 text-center ${mobileQuery.matches && "m-0 mb-2"}`}>
                                                                                View quote
                                                                            </Link>
                                                                            :
                                                                            <Link
                                                                                to={`/enquiries/quotations/create/${enquiry.enquiry.tnNumber}`}
                                                                                className={`button-green w-auto ml-2 text-center ${mobileQuery.matches && "m-0 mb-2"}`}>
                                                                                Create quote
                                                                            </Link>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            )
                                        })}
                                    </Accordion>
                                </div>
                            </InfiniteScroll>
                        </InfiniteScroll>
                    }
                </Card.Body>
            </Card>
        </Screen>
    )
}

export default Enquiries