import React, { useContext, useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import logo from '../../Images/logo-dark.png'
import moment from "moment";
import firebase from "firebase";
import { useHistory, useParams } from "react-router";
import { Enquiry } from "./Enquiries";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { globalUser, Notification, User } from "../../App";
import { MdAdd, MdEdit, MdFormatListBulleted } from "react-icons/md";
import { uuid } from 'uuidv4';
import { Quote } from './QuotationView';
const QuotationCreate = () => {
    const history = useHistory();
    const [enquiry, setEnquiry] = useState<Enquiry>()
    const [switchTwo, setSwitchTwo] = useState<boolean>(false)
    const [switchTwoVal, setSwitchTwoVal] = useState<string>("")
    const [switchFive, setSwitchFive] = useState<boolean>(false)
    const [switchFiveVal, setSwitchFiveVal] = useState<string>("")
    const [itemPrices, setItemPrices] = useState<{ price: number, index: number }[]>([])
    const [singlePrices, setSinglePrices] = useState<number[]>([0, 0])
    const [approvers, setApprovers] = useState<User[]>([]);
    const [show, setShow] = useState(false);
    const [showExtra, setShowExtra] = useState(false);
    const [selectedApprover, setSelectedApprover] = useState<string>("")
    const [selectedCurrency, setSelectedCurrency] = useState<string>("GBP")
    const [selectedCurrencyIcon, setSelectedCurrencyIcon] = useState<string>("£")
    const [deliveryText, setDeliveryText] = useState<string>("Based on material availability and shop load at the time of acknowledging this order, we estimate a lead time of 18 working weeks.");
    const [dropdowns, setDropdowns] = useState({
        sectionTwo: [],
        sectionFive: [],
        sectionSix: [],
        sectionSeven: [],
        sectionEight: [],
        sectionNine: [],
        sectionTen: []
    })
    const [section2, setSection2] = useState<string[]>([])
    const [section2Value, setSection2Value] = useState<string>("")
    const [section5, setSection5] = useState<string[]>([])
    const [section5Value, setSection5Value] = useState<string>("")
    const [section6, setSection6] = useState<boolean>(false)
    const [section7, setSection7] = useState<boolean>(false)
    const [section8, setSection8] = useState<boolean>(false)
    const [section9, setSection9] = useState<boolean>(false)
    const [section10, setSection10] = useState<boolean>(false)
    const [delivery, setDelivery] = useState<Number>(0)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleExtraClose = () => setShowExtra(false);
    const handleExtraShow = () => setShowExtra(true);



    //NEW
    const [intro, setIntro] = useState<string>("Further to receipt of your request , we have pleasure in detailing our commercial quotation below, please don’t hesitate to contact us for anything further.")
    const [guarantee, setGuarantee] = useState<string>("This equipment shall be guaranteed against faulty design, material and workmanship, for a period of 12 months from commissioning/start up or 18 months from delivery, whichever the shorter. This relies on the equipment having been maintained and stored in accordance with THERCO-SERCKs recommendations in the interim period. Any signs of defects must be reported immediately and an opportunity to inspect the equipment granted without delay. No guarantee against failures resulting from erosion, corrosion or fouling of any nature is implied nor offered.")

    const [terms, setTerms] = useState<string[]>([])
    const [selectTerms, setSelectTerms] = useState<string>("")
    const [switchTerms, setSwitchTerms] = useState<boolean>(false)
    const [termValue, setTermValue] = useState<string>("")

    const [manualLines, setManualLines] = useState<{ qty: number, desc: string, price: number }[]>([])
    const [manQty, setManQty] = useState<string>("")
    const [manDesc, setManDesc] = useState<string>("")
    const [manPrice, setManPrice] = useState<string>("")


    // @ts-ignore
    let { id } = useParams();
    const currentUser = useContext(globalUser)

    const getEnquiry = async () => {
        const query = firebase.firestore().collection('enquiries').doc(id);
        await query.get().then((doc) => {
            // @ts-ignore
            setEnquiry(doc.data())
        })
    };

    const handlePriceChange = (item: { price: string, index: number }) => {
        const itemPrice = parseInt(item.price)
        const arr = itemPrices.map((value) => {
            if (value.index === item.index) {
                value.price = itemPrice
            }
            return value
        })
        setItemPrices(arr)
    }

    const getApprovers = async () => {
        const db = firebase.firestore().collection("users");
        let temp: User[] = []
        await db.where("permission", "==", "Level 1").get().then((snapshot) => {
            snapshot.forEach((doc) => {
                if (doc.exists)
                    temp.push({
                        role: doc.data().role,
                        permission: doc.data().permission,
                        uid: doc.id,
                        email: doc.data().email,
                        name: doc.data().name
                    })
            })
        }).then(() => setApprovers(temp)).then(() => setSelectedApprover(temp[0].uid))
    }

    const getDropdowns = async () => {
        const db = firebase.firestore().collection("dropdowns").doc("sections");
        await db.get().then((snap) => {
            if (snap.exists) {
                // @ts-ignore
                setDropdowns(snap.data().values)
            }
        })
    }

    const handleQuoteSubmit = async () => {
        const id = uuid()
        if (enquiry) {
            const temp: Quote = {
                enquiry: enquiry,
                deliveryText: deliveryText,
                deliveryValue: delivery,
                quoteDate: new Date().toDateString(),
                itemPrices: itemPrices,
                id: id,
                tn: enquiry.tnNumber,
                scope: section2,
                raisedById: currentUser.currentUser.uid,
                approverId: selectedApprover,
                sections: { section6, section7, section8, section9, section10 },
                approved: false,
                newExtra: {
                    guarantee: guarantee,
                    intro: intro,
                    manualLinePrices: manualLines,
                    terms: terms
                }
            }
            const db = firebase.firestore().collection("quotations");

            await db.doc(id).set(temp).then(async () => {
                const newNotification: Notification = {
                    type: "Quotation approval",
                    text: "You've been assigned to approve a quotation",
                    link: `/enquiries/quotations/view/${id}`,
                    dateTime: new Date().toDateString(),
                    viewed: false,
                }
                const notifyQuery = firebase.firestore().collection("notifications").doc(selectedApprover)
                await notifyQuery.get().then((doc) => {
                    // @ts-ignore
                    firebase.firestore().collection("notifications").doc(selectedApprover).set({ notifications: [newNotification, ...doc.data().notifications] })
                })
            }).then(() => history.push(`/enquiries/quotations/view/${id}`))
        }
    }

    useEffect(() => {
        getEnquiry()
        getDropdowns()
    }, []);

    useEffect(() => {
        const items: { price: number; index: number; }[] = []
        const itemPricesTemp: number[] = []
        enquiry && enquiry.parts.map((value, index) => {
            items.push({ index: index, price: value.price || 0 })
            itemPricesTemp.push(value.price || 0)
        })
        setItemPrices(items)
        setSinglePrices(itemPricesTemp)
    }, [enquiry])

    let total = 0
    enquiry && enquiry.parts.map((value, index) => {
        const found = itemPrices.find((item) => item.index === index)
        let quantity = 0;
        if (typeof value.quantity === 'string') {
            quantity = parseInt(value.quantity)
        }
        if (found) {
            total += quantity * found.price
        }
    });
    if(manualLines.length > 0) {
        manualLines.map((line) => {
            total += (line.price * line.qty)
        })
    }

    const addSectionTwo = (value: string) => {
        setSection2([...section2, value])
    }


    const addTerm = (value: string) => {
        setTerms([...terms, value])
    }


    const addManLine = () => {
        const temp = manualLines
        temp.push({ desc: manDesc, price: parseInt(manPrice), qty: parseInt(manQty) })
        setManualLines(temp)
        setManDesc("")
        setManPrice("")
        setManQty("")
    }

    return (
        <Screen active="Enquiries">
            <div className="bg-white p-5">
                <img className="mb-5" src={logo} />
                <p>[Classification – Confidential & Proprietary]</p>
                <div className='d-flex flex-row justify-content-between align-items-end'>
                    <h1>Commercial Quotation</h1>
                    <p className="mb-3">{moment().format('DD/MM/YYYY')}</p>
                </div>
                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 1.0 - Introduction</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Company</th>
                                    <th>Location</th>
                                    <th>Contact</th>
                                    <th>Your Reference</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{enquiry?.customer.name}</td>
                                    <td>{enquiry?.customer.deliveryAddressOne}, {enquiry?.customer.deliveryTownCity}</td>
                                    <td>{enquiry?.commercialContact.name} - {enquiry?.commercialContact.position}</td>
                                    <td>{enquiry?.customerRef}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <div className='doc-section-content-padding'>
                            <Form.Control
                                onChange={(e) => setIntro(e.currentTarget.value)}
                                value={intro}
                            />
                        </div>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 2.0 - Quantity, Description & Price</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Line</th>
                                    <th className='text-right'>Qty</th>
                                    <th>Description</th>
                                    <th>Part Number</th>
                                    <th className='text-right'>Unit Price</th>
                                    <th className='text-right'>Line Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {enquiry && enquiry.parts.map((value, index) => {
                                    const found = itemPrices.find((item) => item.index === index)
                                    let quantity = 0;
                                    if (typeof value.quantity === 'string') {
                                        quantity = parseInt(value.quantity)
                                    }
                                    return (
                                        <tr>
                                            <td className='noBottomBorder'>2.{index + 1}</td>
                                            <td className="text-right noBottomBorder">{value.quantity}</td>
                                            <td className='noBottomBorder'>{value.description}, {value.tagNumber}</td>
                                            <td className='noBottomBorder'>{value.partNumber}</td>
                                            <td className='noBottomBorder'>
                                                <Form.Control onChange={(event) => handlePriceChange({
                                                    index: index,
                                                    price: event.target.value
                                                })} value={found && found.price} type="Number" />
                                            </td>
                                            <td className="text-right noBottomBorder">
                                                {selectedCurrencyIcon}{found && (quantity * found.price).toFixed(2)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td className='noBottomBorder'>2.{enquiry && enquiry.parts.length + 1}</td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                </tr>
                                {manualLines.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>2.{enquiry && (enquiry.parts.length + 1 + index + 1)}</td>
                                            <td className='text-right'>{item.qty}</td>
                                            <td>{item.desc}</td>
                                            <td>-</td>
                                            <td className='text-right'>£{item.price.toFixed(2)}</td>
                                            <td className='text-right'>£{(item.price * item.qty).toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td>

                                    </td>
                                    <td className='small-qty'>
                                        <Form.Control
                                            onChange={(e) => setManQty(e.currentTarget.value)}
                                            placeholder='QTY...'
                                            value={manQty}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            onChange={(e) => setManDesc(e.currentTarget.value)}
                                            placeholder='Description...'
                                            value={manDesc}
                                        />
                                    </td>
                                    <td></td>
                                    <td>
                                        <Form.Control
                                            onChange={(e) => setManPrice(e.currentTarget.value)}
                                            placeholder='Unit price...'
                                            value={manPrice}
                                        />
                                    </td>
                                    <td className='d-flex justify-content-end'>
                                        <Button
                                            onClick={() => addManLine()}
                                            className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                            <MdAdd size={24} color="white" />
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Total (excluding VAT, or Taxes of any other nature)</td>
                                    <td className='no-border'></td>
                                    <td className='no-border'></td>
                                    <td className='text-right'>£{total.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 3.0 - Scope of Supply</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Line</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {section2.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='noBottomBorder'>3.{index + 1}</td>
                                            <td className='noBottomBorder'>{item}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td>

                                    </td>
                                    <td>
                                        <div className="w-100 d-flex flex-row align-items-center">
                                            {switchTwo ?
                                                <Form.Control
                                                    onChange={(e) => setSwitchTwoVal(e.currentTarget.value)}
                                                    placeholder='Enter scope of supply information...'
                                                    value={switchTwoVal}
                                                />
                                                :
                                                <Form.Control onChange={(event) => {
                                                    setSection2Value(event.currentTarget.value)
                                                }} as="select">
                                                    {dropdowns && dropdowns["sectionTwo"].map((val: string, index) =>
                                                        <option value={val}>{val}</option>
                                                    )}
                                                </Form.Control>
                                            }
                                            <Button
                                                onClick={() => setSwitchTwo(!switchTwo)}
                                                className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                                {switchTwo ?
                                                    <MdFormatListBulleted size={24} color="white" />
                                                    :
                                                    <MdEdit size={24} color="white" />
                                                }
                                            </Button>
                                            <Button
                                                onClick={() => addSectionTwo(switchTwo ? switchTwoVal : section2Value)}
                                                className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                                <MdAdd size={24} color="white" />
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 4.0 - Delivery</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Form.Control as="textarea" rows={2} onChange={(event) => setDeliveryText(event.target.value)}
                            value={deliveryText} />
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 5.0 - Guarantee</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Form.Control as="textarea" rows={4} onChange={(event) => setGuarantee(event.target.value)}
                            value={guarantee} />
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 6.0 - Terms & Conditions</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <tbody>
                                {terms.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='noBottomBorder'>6.{index + 1}</td>
                                            <td className='noBottomBorder'>{item}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td>

                                    </td>
                                    <td>
                                        <div className="w-100 d-flex flex-row align-items-center">
                                            {switchTerms ?
                                                <Form.Control
                                                    onChange={(e) => setTermValue(e.currentTarget.value)}
                                                    placeholder='Enter term & condition...'
                                                    value={termValue}
                                                />
                                                :
                                                <Form.Control onChange={(event) => {
                                                    setSelectTerms(event.currentTarget.value)
                                                }} as="select">
                                                    {dropdowns && dropdowns["sectionTwo"].map((val: string, index) =>
                                                        <option value={val}>{val}</option>
                                                    )}
                                                </Form.Control>
                                            }
                                            <Button
                                                onClick={() => setSwitchTerms(!switchTerms)}
                                                className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                                {switchTerms ?
                                                    <MdFormatListBulleted size={24} color="white" />
                                                    :
                                                    <MdEdit size={24} color="white" />
                                                }
                                            </Button>
                                            <Button
                                                onClick={() => addTerm(switchTerms ? termValue : selectTerms)}
                                                className="ml-2 button-green button-green--search d-flex align-items-center justify-content-center w-auto">
                                                <MdAdd size={24} color="white" />
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-content'>
                        <Table>
                            <tbody>

                                <tr>
                                    <td className='font-weight-bold small-cell'>
                                        Quotation Prepared by
                                    </td>
                                    <td>
                                        {currentUser.currentUser.name} - {currentUser.currentUser.role}
                                    </td>
                                    <td className='font-weight-bold small-cell text-right'>
                                        Our Reference
                                    </td>
                                    <td className='text-right'>
                                        TN{enquiry?.tnNumber}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>




                <div className="purchaseOrdersCreate-template-signatures">
                    <div className="d-flex flex-column">

                    </div>
                    <div className="d-flex flex-column">

                        <Button
                            onClick={() => {
                                getApprovers()
                                handleShow()
                            }}
                            className="button-green mt-5">
                            Send for approval
                        </Button>
                    </div>
                </div>
            </div>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select an approver</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control onChange={(event) => setSelectedApprover(event.currentTarget.value)} as="select">
                        {approvers.map((value, index) => <option value={value.uid} key={index}>{value.name}</option>)}
                    </Form.Control>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        onClick={() => handleQuoteSubmit()}
                        className="button-green w-auto">
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>
        </Screen>
    )
}

export default QuotationCreate