import React, { useContext, useEffect, useState } from 'react';
import Screen from "../../Components/Screen";
import logo from '../../Images/logo-dark.png'
import moment from "moment";
import firebase from "firebase";
import { useParams } from "react-router";
import { Enquiry } from "./Enquiries";
import { Button, Modal, Table } from "react-bootstrap";
import { globalUser, Notification, User } from "../../App";
import { toast } from "react-hot-toast";
import BackpageSections from "../../Components/BackpageSections";
import Footer from '../../Components/Footer';

export interface Quote {
    enquiry: Enquiry
    deliveryText: string
    deliveryValue: Number
    id: string
    quoteDate: string
    itemPrices: { price: number, index: number }[]
    raisedById: string
    approverId: string
    approved: boolean
    scope?: string[]
    tn?: string
    sections: { section6: boolean, section7: boolean, section8: boolean, section9: boolean, section10: boolean }
    newExtra?: {
        intro: string,
        guarantee: string,
        terms: string[],
        manualLinePrices: { qty: number, desc: string, price: number }[]
    }
}

const QuotationView = () => {
    const [quote, setQuote] = useState<Quote>()
    const [approver, setApprover] = useState<User>()
    const [creator, setCreator] = useState<User>()
    const [dropdowns, setDropdowns] = useState({
        sectionTwo: [],
        sectionFive: [],
        sectionSix: [],
        sectionSeven: [],
        sectionEight: [],
        sectionNine: [],
        sectionTen: []
    })
    const [printing, setPrinting] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const currentUser = useContext(globalUser)


    const handlePrint = () => {
        setPrinting(true)
    }


    // @ts-ignore
    let { id } = useParams();
    const getQuote = async () => {
        const query = firebase.firestore().collection('quotations').doc(`${id}`);
        await query.get().then((doc) => {
            // @ts-ignore
            setQuote(doc.data())
        })
    };

    const getDropdowns = async () => {
        const db = firebase.firestore().collection("dropdowns").doc("sections");
        await db.get().then((snap) => {
            if (snap.exists) {
                // @ts-ignore
                setDropdowns(snap.data().values)
            }
        })
    }

    const getApprover = async () => {
        if (quote) {
            const db = firebase.firestore().collection("users").doc(quote.approverId);
            await db.get().then((snap) => {
                if (snap.exists) {
                    // @ts-ignore
                    setApprover(snap.data())
                }
            })
        }
    }

    const approveQuote = async () => {
        if (quote) {
            let newQuote: Quote = {
                ...quote,
                approved: true
            }
            const db = firebase.firestore().collection("quotations");
            await db.doc(`${id}`).set(newQuote).then(() => {
                getQuote()
                handleClose()
                toast.success(`Quote approved!`)
            }).then(async () => {
                const newNotification: Notification = {
                    type: "Quotation approved!",
                    text: "Your quote has been approved",
                    link: `/enquiries/quotations/view/${quote.id}`,
                    dateTime: new Date().toDateString(),
                    viewed: false,
                }
                const notifyQuery = firebase.firestore().collection("notifications").doc(quote.raisedById)
                await notifyQuery.get().then((doc) => {
                    if (doc.exists) {
                        // @ts-ignore
                        firebase.firestore().collection("notifications").doc(quote.raisedById).set({ notifications: [newNotification, ...doc.data().notifications] })
                    } else {
                        // @ts-ignore
                        firebase.firestore().collection("notifications").doc(quote.raisedById).set({ notifications: [newNotification] })
                    }
                })
            })
        }
    }

    const getCreator = async () => {
        if (quote) {
            const db = firebase.firestore().collection("users").doc(quote.raisedById);
            await db.get().then((snap) => {
                if (snap.exists) {
                    // @ts-ignore
                    setCreator(snap.data())
                }
            })
        }
    }


    useEffect(() => {
        getDropdowns()
        getQuote()
    }, [])

    useEffect(() => {
        getApprover()
        getCreator()
    }, [quote])

    console.log(quote)
    let total = 0
    if (quote) {
        quote.enquiry.parts.map((value, index) => {
            const found = quote.itemPrices.find((item) => item.index === index)
            let quantity = 0;
            if (typeof value.quantity === 'string') {
                quantity = parseInt(value.quantity)
            }

            if (found) {
                total += quantity * found.price
            }
        })
        total += quote.deliveryValue.valueOf()
        if (quote.newExtra && quote?.newExtra?.manualLinePrices.length > 0) {
            quote?.newExtra?.manualLinePrices.map((line) => {
                total += (line.price * line.qty)
            })
        }
    }


    if (printing && quote) {
        return (
            <div className="bg-white">
                <img className="mb-3" src={logo} />
                <p>[Classification – Confidential & Proprietary]</p>
                <div className='d-flex flex-row justify-content-between align-items-end'>
                    <h1>Commercial Quotation</h1>
                    <p className="mb-3">{moment().format('DD/MM/YYYY')}</p>
                </div>
                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 1.0 - Introduction</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Company</th>
                                    <th>Location</th>
                                    <th>Contact</th>
                                    <th>Your Reference</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{quote.enquiry?.customer.name}</td>
                                    <td>{quote.enquiry?.customer.deliveryAddressOne}, {quote.enquiry?.customer.deliveryTownCity}</td>
                                    <td>{quote.enquiry?.commercialContact.name} - {quote.enquiry?.commercialContact.position}</td>
                                    <td>{quote.enquiry?.customerRef}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <div className='doc-section-content-padding'>
                            <p>{quote.newExtra?.intro}</p>
                        </div>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 2.0 - Quantity, Description & Price</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Line</th>
                                    <th className='text-right'>Qty</th>
                                    <th>Description</th>
                                    <th>Part Number</th>
                                    <th className='text-right'>Unit Price</th>
                                    <th className='text-right'>Line Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {quote.enquiry.parts.map((value, index) => {
                                    const found = quote.itemPrices.find((item) => item.index === index)
                                    let quantity = 0;
                                    if (typeof value.quantity === 'string') {
                                        quantity = parseInt(value.quantity)
                                    }
                                    return (
                                        <tr>
                                            <td className='text-center noBottomBorder'>2.{index + 1}</td>
                                            <td className="text-right noBottomBorder">{value.quantity}</td>
                                            <td className='noBottomBorder'>{value.description}, {value.tagNumber}</td>
                                            <td className='noBottomBorder'>{value.partNumber}</td>
                                            <td className="text-right noBottomBorder">
                                                £{found?.price.toFixed(2)}
                                            </td>
                                            <td className="text-right noBottomBorder">
                                                £{found && (quantity * found.price).toFixed(2)}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td className='text-center noBottomBorder'>2.{quote.enquiry.parts.length + 1}</td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                    <td className='noBottomBorder'></td>
                                </tr>
                                {quote.newExtra && quote.newExtra.manualLinePrices.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='text-center'>2.{quote.enquiry && (quote.enquiry.parts.length + 1 + index + 1)}</td>
                                            <td className='text-right'>{item.qty}</td>
                                            <td>{item.desc}</td>
                                            <td></td>
                                            <td className='text-right'>£{item.price.toFixed(2)}</td>
                                            <td className='text-right'>£{(item.price * item.qty).toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Total (excluding VAT, or Taxes of any other nature)</td>
                                    <td className='no-border'></td>
                                    <td className='no-border'></td>
                                    <td className='text-right'>£{total.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 3.0 - Scope of Supply</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Line</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {quote.scope && quote.scope.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='text-center noBottomBorder'>3.{index + 1}</td>
                                            <td className='noBottomBorder'>{item}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 4.0 - Delivery</h4>
                    </div>
                    <div className='doc-section-content-padding'>
                        <p>{quote.deliveryText}</p>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 5.0 - Guarantee</h4>
                    </div>
                    <div className='doc-section-content-padding'>
                        <p>{quote.newExtra?.guarantee}</p>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-title'>
                        <h4>Section 6.0 - Terms & Conditions</h4>
                    </div>
                    <div className='doc-section-content'>
                        <Table>
                            <tbody>
                                {quote.newExtra && quote.newExtra.terms.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='small-qty text-center noBottomBorder'>6.{index + 1}</td>
                                            <td className='noBottomBorder'>{item}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='doc-section'>
                    <div className='doc-section-content'>
                        <Table>
                            <tbody>

                                <tr>
                                    <td className='font-weight-bold small-cell'>
                                        Quotation Prepared by
                                    </td>
                                    <td>
                                        {currentUser.currentUser.name} - {currentUser.currentUser.role}
                                    </td>
                                    <td className='font-weight-bold small-cell text-right'>
                                        Our Reference
                                    </td>
                                    <td className='text-right'>
                                        TN{quote.enquiry?.tnNumber}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Footer />
                <div className="d-flex flex-row justify-content-end w-100 mt-5 PO-printing">
                    <div className="d-flex flex-row align-items-center">
                        <Button
                            onClick={() => setPrinting(false)}
                            className="button-green ml-3">
                            Cancel preview
                        </Button>
                        <Button
                            onClick={() => window.print()}
                            className="button-green ml-3">
                            Download PDF
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {quote &&
                <Screen active="Enquiries">
                    <div className="bg-white p-5">
                        <img className="mb-5" src={logo} />
                        <p>[Classification – Confidential & Proprietary]</p>
                        <div className='d-flex flex-row justify-content-between align-items-end'>
                            <h1>Commercial Quotation</h1>
                            <p className="mb-3">{moment().format('DD/MM/YYYY')}</p>
                        </div>
                        <div className='doc-section'>
                            <div className='doc-section-title'>
                                <h4>Section 1.0 - Introduction</h4>
                            </div>
                            <div className='doc-section-content'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Company</th>
                                            <th>Location</th>
                                            <th>Contact</th>
                                            <th>Your Reference</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{quote.enquiry?.customer.name}</td>
                                            <td>{quote.enquiry?.customer.deliveryAddressOne}, {quote.enquiry?.customer.deliveryTownCity}</td>
                                            <td>{quote.enquiry?.commercialContact.name} - {quote.enquiry?.commercialContact.position}</td>
                                            <td>{quote.enquiry?.customerRef}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className='doc-section-content-padding'>
                                    <p>{quote.newExtra?.intro}</p>
                                </div>
                            </div>
                        </div>

                        <div className='doc-section'>
                            <div className='doc-section-title'>
                                <h4>Section 2.0 - Quantity, Description & Price</h4>
                            </div>
                            <div className='doc-section-content'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>Line</th>
                                            <th className='text-right'>Qty</th>
                                            <th>Description</th>
                                            <th>Part Number</th>
                                            <th className='text-right'>Unit Price</th>
                                            <th className='text-right'>Line Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {quote.enquiry.parts.map((value, index) => {
                                            const found = quote.itemPrices.find((item) => item.index === index)
                                            let quantity = 0;
                                            if (typeof value.quantity === 'string') {
                                                quantity = parseInt(value.quantity)
                                            }
                                            return (
                                                <tr>
                                                    <td className='text-center noBottomBorder'>2.{index + 1}</td>
                                                    <td className="text-right noBottomBorder">{value.quantity}</td>
                                                    <td className='noBottomBorder'>{value.description}, {value.tagNumber}</td>
                                                    <td className='noBottomBorder'>{value.partNumber}</td>
                                                    <td className="text-right noBottomBorder">
                                                        £{found?.price.toFixed(2)}
                                                    </td>
                                                    <td className="text-right noBottomBorder">
                                                        £{found && (quantity * found.price).toFixed(2)}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td className='text-center noBottomBorder'>2.{quote.enquiry.parts.length + 1}</td>
                                            <td className='noBottomBorder'></td>
                                            <td className='noBottomBorder'></td>
                                            <td className='noBottomBorder'></td>
                                            <td className='noBottomBorder'></td>
                                            <td className='noBottomBorder'></td>
                                        </tr>
                                        {quote.newExtra && quote.newExtra.manualLinePrices.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className='text-center'>2.{quote.enquiry && (quote.enquiry.parts.length + 1 + index + 1)}</td>
                                                    <td className='text-right'>{item.qty}</td>
                                                    <td>{item.desc}</td>
                                                    <td></td>
                                                    <td className='text-right'>£{item.price.toFixed(2)}</td>
                                                    <td className='text-right'>£{(item.price * item.qty).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>Total (excluding VAT, or Taxes of any other nature)</td>
                                            <td className='no-border'></td>
                                            <td className='no-border'></td>
                                            <td className='text-right'>£{total.toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className='doc-section'>
                            <div className='doc-section-title'>
                                <h4>Section 3.0 - Scope of Supply</h4>
                            </div>
                            <div className='doc-section-content'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>Line</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {quote.scope && quote.scope.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className='text-center noBottomBorder'>3.{index + 1}</td>
                                                    <td className='noBottomBorder'>{item}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className='doc-section'>
                            <div className='doc-section-title'>
                                <h4>Section 4.0 - Delivery</h4>
                            </div>
                            <div className='doc-section-content-padding'>
                                <p>{quote.deliveryText}</p>
                            </div>
                        </div>

                        <div className='doc-section'>
                            <div className='doc-section-title'>
                                <h4>Section 5.0 - Guarantee</h4>
                            </div>
                            <div className='doc-section-content-padding'>
                                <p>{quote.newExtra?.guarantee}</p>
                            </div>
                        </div>

                        <div className='doc-section'>
                            <div className='doc-section-title'>
                                <h4>Section 6.0 - Terms & Conditions</h4>
                            </div>
                            <div className='doc-section-content'>
                                <Table>
                                    <tbody>
                                        {quote.newExtra && quote.newExtra.terms.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className='small-qty text-center noBottomBorder'>6.{index + 1}</td>
                                                    <td className='noBottomBorder'>{item}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className='doc-section'>
                            <div className='doc-section-content'>
                                <Table>
                                    <tbody>

                                        <tr>
                                            <td className='font-weight-bold small-cell'>
                                                Quotation Prepared by
                                            </td>
                                            <td>
                                                {currentUser.currentUser.name} - {currentUser.currentUser.role}
                                            </td>
                                            <td className='font-weight-bold small-cell text-right'>
                                                Our Reference
                                            </td>
                                            <td className='text-right'>
                                                TN{quote.enquiry?.tnNumber}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        {quote && !quote.approved ?
                            <div className="d-flex flex-row align-items-center justify-content-end mt-5">
                                <Button
                                    onClick={() => handlePrint()}
                                    className="button-green ml-3 width-fit">
                                    View preview & print
                                </Button>
                                {quote &&
                                    (currentUser.currentUser.uid === quote.approverId &&
                                        <Button
                                            onClick={() => handleShow()}
                                            className="button-green width-fit ml-3">
                                            Approve this quote
                                        </Button>
                                    )
                                }
                            </div>
                            :
                            <></>
                        }
                        {quote && quote.approved &&
                            <div className="d-flex flex-row align-items-center justify-content-end mt-5">
                                <Button
                                    onClick={() => handlePrint()}
                                    className="button-green ml-3 width-fit">
                                    View preview & print
                                </Button>
                            </div>
                        }
                        <BackpageSections sections={quote.sections} lastSectionNumber={5} />
                    </div>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Please confirm approval</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                onClick={() => {
                                    approveQuote()
                                }}
                                className="button-green w-auto">
                                Approve
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Screen>
            }
        </>
    )
}

export default QuotationView